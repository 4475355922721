import React from 'react';
import prb_bg from '../../assets/problem-img.png';
import pbi_1 from '../../assets/problem-icon/1.png';
import pbi_2 from '../../assets/problem-icon/2.png';
import pbi_3 from '../../assets/problem-icon/3.png';
import pbi_4 from '../../assets/problem-icon/4.png';
import pbi_5 from '../../assets/problem-icon/5.png';
import pbi_6 from '../../assets/problem-icon/6.png';
import sl_bg from '../../assets/solution-bg.png';
import grd_std from '../../assets/graduating-student 1.png';
import support from '../../assets/support (1).png';
import briefcase from '../../assets/briefcase.png';
import continuous from '../../assets/continuous.png';

const Problem = () => {
  return (
    <>
      <section className="sm:sm:py-20 py-10 py-10 bg-[#F3DA38]">
        <div className="relative isolate lg:px-8 px-6 mx-auto max-w-7xl ">
          <div className="flex flex-wrap mb-10">
            <div className="md:w-2/4 w-full px-6 md:text-left text-center md:mb-0 mb-6">
              <h3 className="md:text-3xl text-2xl text-gray-black font-medium mb-4">
                The Problem
              </h3>
              <p className="text-light-black md:text-base text-sm">
                Organizations lack insight into the factors that predict high
                performance within their teams.
              </p>
            </div>
          </div>
          <div className="flex items-center flex-wrap">
            <div className="lg:w-5/12 w-full sm:px-4 px-0">
              <div className="pr-10">
                <img className="w-full" src={prb_bg} alt="" />
              </div>
            </div>
            <div className="lg:w-7/12 w-full sm:px-4 px-0">
              <div className="flex flex-wrap -mx-4">
                <div className="md:w-6/12 w-full px-4">
                  <div className="bg-white sm:p-4 p-3 lg:mb-6 mb-4 flex items-center rounded-2xl mb-4 box-shadow-light">
                    <img className="mr-3" src={pbi_1} alt="" />
                    <h4 className="text-base font-mediumm-0">
                      Lack industry-specific skills
                    </h4>
                  </div>
                </div>
                <div className="md:w-6/12 w-full px-4">
                  <div className="bg-white sm:p-4 p-3 lg:mb-6 mb-4 flex items-center rounded-2xl mb-4 box-shadow-light">
                    <img className="mr-3" src={pbi_2} alt="" />
                    <h4 className="text-base font-mediumm-0">
                      Competitive job markets, candidates with the right skills
                    </h4>
                  </div>
                </div>
                <div className="md:w-6/12 w-full px-4">
                  <div className="bg-white sm:p-4 p-3 lg:mb-6 mb-4 flex items-center rounded-2xl mb-4 box-shadow-light">
                    <img className="mr-3" src={pbi_3} alt="" />
                    <h4 className="text-base font-mediumm-0">
                      Rapid Technological Advancements
                    </h4>
                  </div>
                </div>
                <div className="md:w-6/12 w-full px-4">
                  <div className="bg-white sm:p-4 p-3 lg:mb-6 mb-4 flex items-center rounded-2xl mb-4 box-shadow-light">
                    <img className="mr-3" src={pbi_4} alt="" />
                    <h4 className="text-base font-mediumm-0">
                      Educational Curriculum Misalignment
                    </h4>
                  </div>
                </div>
                <div className="md:w-6/12 w-full px-4">
                  <div className="bg-white sm:p-4 p-3 lg:mb-0 mb-4 flex items-center rounded-2xl mb-4 box-shadow-light">
                    <img className="mr-3" src={pbi_5} alt="" />
                    <h4 className="text-base font-mediumm-0">
                      Mismatch in Job Market Demands
                    </h4>
                  </div>
                </div>
                <div className="md:w-6/12 w-full px-4">
                  <div className="bg-white sm:p-4 p-3 flex items-center rounded-2xl mb-4 box-shadow-light">
                    <img className="mr-3" src={pbi_6} alt="" />
                    <h4 className="text-base font-mediumm-0">
                      Agile and Adaptive Learning Challenges
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="sm:py-20 py-10">
        <div className="relative isolate sm:px-6 px-4 mx-auto max-w-7xl lg:px-8">
          <div className="flex flex-wrap mb-10">
            <div className="md:w-2/4 w-full px-6 md:text-left text-center md:mb-0 mb-6">
              <h3 className="md:text-3xl text-2xl text-gray-black font-medium mb-4">
                Our Solution
              </h3>
              <p className="text-light-black md:text-base text-sm">
                We recommend the competencies, soft skills, and cultural traits
                you need for every role. Unlock data to enhance hiring,
                onboarding, and retention.
              </p>
            </div>
          </div>
          <div className="relative py-60">
            <div className="text-center">
              <img className="mx-auto" src={sl_bg} alt="" />
            </div>
            <div className="top-box solution-box">
              <div className="w-20 h-20 mx-auto flex items-center justify-center rounded-full bg-blue-light">
                <img src={grd_std} alt="" />
              </div>
              <div className="md:p-5 p-4 text-center mt-6 rounded bg-dark-blue rounded">
                <h4 className="text-white">
                  Third Bracket is an avant-garde skill profiling platform that
                  enables users to gain a clear understanding of their skills
                  and showcase their talent immaculately
                </h4>
              </div>
            </div>
            <div className="left-box solution-box">
              <div className="w-20 h-20 rounded-full flex items-center justify-center bg-blue-light">
                <img src={support} alt="" />
              </div>
              <div className="md:p-5 p-4 side-box text-center rounded ml-6 bg-dark-blue rounded">
                <h4 className="text-white">
                  Analyze the individual skill scores provided by the system and
                  assist individuals in training themselves to enhance and align
                  their skills with the requirements of organizations and
                  enterprises.
                </h4>
              </div>
            </div>
            <div className="right-box solution-box">
              <div className="md:p-5 p-4 side-box text-center rounded mr-6 bg-dark-blue rounded">
                <h4 className="text-white">
                  Third Bracket will provide users with a roadmap for skills
                  development to track their progress, and constant
                  recommendations for improvement will be seamlessly integrated
                  into the system. opment to the users to track their progress
                  and constant recommendation for improvement will be integrated
                  into the system.
                </h4>
              </div>
              <div className="w-20 h-20 rounded-full flex items-center justify-center bg-blue-light">
                <img src={briefcase} alt="" />
              </div>
            </div>
            <div className="bottom-box solution-box">
              <div className="md:p-5 p-4 rounded text-center mb-6 bg-dark-blue rounded">
                <h4 className="text-white">
                  Third Bracket assists in aligning candidates' skills with the
                  requirements, goals, and objectives of organizations and
                  enterprises.
                </h4>
              </div>
              <div className="w-20 h-20 mx-auto flex items-center justify-center rounded-full bg-blue-light">
                <img src={continuous} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Problem;
