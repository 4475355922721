import React, { useState } from 'react';
import logo from '../../assets/tblogo1.png';
import logoBlack from '../../assets/tblogo1.png';
import { Link } from 'react-router-dom';

const Header = () => {
  const [menu, setMenu] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  return (
    <header className="bg-dark_blue">
      <nav
        className="mx-auto flex lg:px-8 px-6 py-4 max-w-7xl items-center justify-between lg:py-9"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img className="lg:h-12 h-8 w-auto" src={logo} alt="" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMenu(!menu)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#fff"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:gap-x-12">
          <a
            href="/book-a-demo"
            className="text-lg font-semibold leading-6 text-white hover:text-amber-300 border-b-0 hover:border-b hover:border-yellow-300"
          >
            Book A Demo
          </a>
          {/* <div className="relative drop-down">
            <button
              type="button"
              onMouseEnter={() => setDropdown(true)}
              onMouseLeave={() => setDropdown(false)}
              className="product flex items-center gap-x-1 text-lg font-semibold leading-6 text-white"
              aria-expanded="false"
            >
              Product
            </button>
            {dropdown && (
              <div className="absolute -left-8 top-full drop-down-menu z-10 mt-3 w-screen rounded-2xl max-w-lg bg-transparent bg-black backdrop-blur-2xl shadow-lg ring-1 ring-gray-900/5">
                <div className="p-6 flex">
                  <div className="pr-10 border-e w-7/12">
                    <ul>
                      <li className="pb-4">
                        <a href="/" className="lg:text-base text-sm text-white">
                          Ability and Skills Assessments
                        </a>
                      </li>
                      <li className="pb-4">
                        <a href="/" className="lg:text-base text-sm text-white">
                          Behavioral Assessments
                        </a>
                      </li>
                      <li className="pb-4">
                        <a href="/" className="lg:text-base text-sm text-white">
                          Coding Assessments
                        </a>
                      </li>
                      <li className="pb-4">
                        <a href="/" className="lg:text-base text-sm text-white">
                          Gamified Assessments
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="pl-10 w-5/12">
                    <ul>
                      <li className="pb-4">
                        <a href="/" className="lg:text-base text-sm text-white">
                          Jobs
                        </a>
                      </li>
                      <li className="pb-4">
                        <a href="/" className="lg:text-base text-sm text-white">
                          Skill base courses
                        </a>
                      </li>
                      <li>
                        <a href="/" className="lg:text-base text-sm text-white">
                          Trainer &amp; mentorship
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
          <a
            href="/"
            className="text-lg font-semibold leading-6 text-white hover:text-amber-300 border-b-0 hover:border-b hover:border-yellow-300"
          >
            Resources
          </a> */}
        </div>
        <div className="hidden lg:flex ml-12 lg:items-center lg:justify-end">
          <Link
            to="/login"
            className="text-lg font-semibold leading-6 text-white"
          >
            Log in
          </Link>
          <Link
            to="/sign-up"
            className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-lg px-5 py-2.5 ml-12 dark:focus:ring-yellow-900"
          >
            Sign Up
          </Link>
        </div>
      </nav>
      {/* Mobile menu, show/hide based on menu open state. */}
      {menu && (
        <div
          id="sidemenu"
          className="lg:hidden"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 z-10" />
          <div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-4 py-4 sm:px-6 sm:py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-8 w-auto" src={logoBlack} alt="" />
              </a>
              <button
                onClick={() => setMenu(!menu)}
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                {/* <div className="space-y-2 py-6">
                  <a
                    href="/"
                    className="-mx-3 block rounded-lg px-3 py-2 lg:text-base text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Solution
                  </a>
                  <div className="-mx-3">
                    <button
                      type="button"
                      className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 lg:text-base text-sm font-semibold leading-7 hover:bg-gray-50"
                      aria-controls="disclosure-1"
                      aria-expanded="false"
                    >
                      Product
                      <svg
                        className="h-5 w-5 flex-none"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                    {dropdown && (
                      <div className="mt-2 space-y-2" id="dropdownMenu">
                        <a
                          href="/"
                          className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Ability and Skills Assessments
                        </a>
                        <a
                          href="/"
                          className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Behavioral Assessments
                        </a>
                        <a
                          href="/"
                          className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Coding Assessments
                        </a>
                        <a
                          href="/"
                          className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Gamified Assessments
                        </a>
                        <a
                          href="/"
                          className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Jobs
                        </a>
                        <a
                          href="/"
                          className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Skill base courses
                        </a>
                        <a
                          href="/"
                          className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Trainer &amp; mentorship
                        </a>
                      </div>
                    )}
                  </div>
                  <a
                    href="/"
                    className="-mx-3 block rounded-lg px-3 py-2 lg:text-base text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Resources
                  </a>
                </div> */}
                <div className="py-6">
                  <Link
                    to="/login"
                    className="-mx-3 block rounded-lg px-3 py-2.5 lg:text-base text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </Link>
                  <Link
                    to={'/sign-up'}
                    className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-lg w-full px-5 py-2.5 mt-4 dark:focus:ring-yellow-900"
                  >
                    Sign Up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
