import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosPublic } from '../../api/axios';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

const BookADemoForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<any>(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      companyName: '',
      timeSlot: '',
    },
    onSubmit: async (v) => {
      setLoading(true);
      await axiosPublic
        .post('/demos', {
          name: v.name,
          email: v.email,
          phone: v.phone,
          companyName: v.companyName,
          timeSlot: v.timeSlot,
        })
        .then(() => {
          setLoading(false);
          toast.success(
            `We have received your request. We'll get back to you shortly.`
          );
          navigate('/');
        })
        .catch((err) => {
          setLoading(false);
          toast.error(`${err.response?.data?.message}`);
        });
    },
  });
  return (
    <section className="md:pt-16 md:pb-24 sm:pt-12 sm:pb-16 pt-8 pb-12 bg-light-gray px-2">
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-400 opacity-75 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">
            Sending your request...
          </h2>
          <p className="w-1/3 text-center text-white">Please wait a while...</p>
        </div>
      )}
      <div className="mx-auto max-w-xl md:pt-5 sm:pt-4 pt-2 sm:pb-6 pb-4 md:pb-8 sm:px-6 px-4 md:px-8 bg-white rounded-3xl">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className=" text-center sm:text-2xl text-xl font-medium leading-9 tracking-tight text-gray-900">
            Book a Demo with ThirdBracket
          </h2>
        </div>

        <form className="space-y-6 sm:mt-4 mt-2" action="#" method="POST">
          <div>
            <label
              htmlFor="name"
              className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
            >
              Name
            </label>
            <div className="mt-2">
              <input
                id="name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                required
                className="text-sm block w-full p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 rounded-lg"
                placeholder="Enter your name"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="phone"
              className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
            >
              Phone Number
            </label>
            <div className="mt-2">
              <input
                id="phone"
                type="text"
                required
                value={formik.values.phone}
                onChange={formik.handleChange}
                className="text-sm block w-full p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 rounded-lg"
                placeholder="Enter your phone number"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="email"
              className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                autoComplete="email"
                required
                className="text-sm block w-full p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 rounded-lg"
                placeholder="Enter your email/username"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="companyName"
              className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
            >
              Company Name
            </label>
            <div className="mt-2">
              <input
                id="companyName"
                type="text"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                required
                className="text-sm block w-full p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 rounded-lg"
                placeholder="Enter your company name."
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="timeSlot"
              className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
            >
              Time Slot
            </label>
            <div className="mt-2">
              <input
                id="timeSlot"
                type="datetime-local"
                value={formik.values.timeSlot}
                onChange={formik.handleChange}
                required
                className="text-sm block w-full p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 rounded-lg"
              />
            </div>
          </div>
          <div>
            <button
              onClick={formik.handleSubmit as any}
              className="flex w-full justify-center rounded-md bg-amber-300 px-3 p-1.5 font-medium sm:text-base text-sm leading-6 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-300  sm:py-4 py-2"
            >
              Book a demo
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default BookADemoForm;
