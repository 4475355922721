import React from "react";
import DashboardLayout from "../../layout/DashboardLayout";
import MainContent from "../../components/Assesments/MainContent";

const Assessments = () => {
  return (
    <DashboardLayout>
      <MainContent />
    </DashboardLayout>
  );
};

export default Assessments;
