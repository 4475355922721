import React from "react";
import { PropsType } from "./types/PropsTypes";
import google from "../../../assets/google-logo.svg";

const StepFour = ({ prevStep, nextStep }: PropsType) => {
  return (
    <section className="sm:py-10 sm:py-8 py-6 bg-white-sm-light sm:min-h-[calc(100vh-152px)] min-h-[calc(100vh-96px)]">
      <div className="mx-auto max-w-2xl px-4">
        <div className="md:p-8 sm:p-6 p-4 sm:rounded-2xl rounded-xl bg-white md:mb-6 sm:mb-4 mb-3">
          <h4 className="sm:text-base text-sm font-medium mb-2">UX UI Designer</h4>
          <h4 className="flex items-center text-dark-black lg:text-base text-sm">
            <img className="mr-2 md:w-6 w-5 md:h-6 h-5" src={google} alt="" />{" "}
            Company Name{" "}
          </h4>
        </div>
        <h3 className="md:text-2xl sm:text-xl text-base font-medium md:mb-6 sm:mb-4 mb-3">
          Please review your application
        </h3>
        <div className="flex items-center justify-between sm:mb-4 mb-3">
          <h4 className="sm:text-base text-sm font-medium">Resume</h4>
          <a
            href="/"
            className="sm:text-base text-sm text-indigo-600 font-medium"
          >
            Edit
          </a>
        </div>
        <div className="md:p-8 sm:p-6 p-4  bg-white sm:rounded-2xl rounded-xl md:mb-6 sm:mb-4 mb-3">
          <h4 className="sm:text-xl text-base font-medium sm:mb-4 mb-3">Raju Mullah</h4>
          <p className="text-dark-black text-sm mb-2">
            rbrajumullah100@gmail.com <br />
            +880 1784 453204 <br />
            Brahmanbaria 3462
          </p>
          <p className="text-dark-black text-sm">
            With over 4 years of experience as a UI/UX Designer, I have a strong
            track record of creating user centric designs for web and mobile
            applications that meet business objectives. My keen eye for detail
            and passion for creating intuitive and engaging user experiences is
            evident in all my work. I love traveling, hiking. I enjoy seeing
            things from a fresh perspective :)
          </p>
          <hr className="w-full h-px md:my-9 sm:my-6 my-4 bg-gray-200 border-0 dark:bg-gray-700" />
          <h4 className="sm:text-xl text-base font-medium sm:mb-4 mb-3">Work Experience</h4>
          <h5 className="font-medium sm:text-base text-sm mb-3">Senior UX Designer</h5>
          <div className="flex justify-between items-center mb-3">
            <h5 className="sm:text-base text-sm text-dark-black">FalconThought</h5>
            <h4 className="sm:text-base text-sm">Aug 2022 - Current</h4>
          </div>
          <h5 className="sm:text-base text-sm text-dark-black mb-3">(7 months)</h5>
          <p className="text-sm text-dark-black mb-4">
            I am the co-founder of Falcon Thought, a UI/UX design agency that
            specializes in creating intuitive anduser-centered designs for
            digital products. As a UI/UX designer, I have a strong background in
            userresearch, wireframing, prototyping, and usability testing. I am
            up-to-date with the latest design trendsand technologies. My passion
            is to create beautiful and functional designs that are not only
            visuallyappealing but also easy to use and navigate. I am dedicated
            to helping businesses and organizationsachieve their goals by
            creating designs that meet the needs of their users and contribute
            to theirsuccess.
          </p>
          <h5 className="font-medium sm:text-base text-sm mb-3">Senior UX Designer</h5>
          <div className="flex justify-between items-center mb-3">
            <h5 className="sm:text-base text-sm text-dark-black">FalconThought</h5>
            <h4 className="sm:text-base text-sm">Aug 2022 - Current</h4>
          </div>
          <h5 className="sm:text-base text-sm text-dark-black mb-3">(7 months)</h5>
          <p className="text-sm text-dark-black">
            I am the co-founder of Falcon Thought, a UI/UX design agency that
            specializes in creating intuitive anduser-centered designs for
            digital products. As a UI/UX designer, I have a strong background in
            userresearch, wireframing, prototyping, and usability testing. I am
            up-to-date with the latest design trendsand technologies. My passion
            is to create beautiful and functional designs that are not only
            visuallyappealing but also easy to use and navigate. I am dedicated
            to helping businesses and organizationsachieve their goals by
            creating designs that meet the needs of their users and contribute
            to theirsuccess.
          </p>
          <hr className="w-full h-px md:my-9 sm:my-6 my-4 bg-gray-200 border-0 dark:bg-gray-700" />
          <h4 className="sm:text-xl text-base font-medium mb-4">Education</h4>
          <h5 className="font-medium sm:text-base text-sm mb-3">
            Bsc in Petroleum &amp; Mining Engineering
          </h5>
          <p className="text-sm text-dark-black mb-3">
            Shahjalal University of Science and Technology
          </p>
          <p className="text-sm text-dark-black">2019 to Present</p>
          <hr className="w-full h-px md:my-9 sm:my-6 my-4 bg-gray-200 border-0 dark:bg-gray-700" />
          <h4 className="sm:text-xl text-base font-medium mb-4">Skills</h4>
          <div>
            <span className="sm:p-3 p-2 bg-indigo-50 sm:text-base text-sm rounded-lg inline-block sm:mb-4 mb-3 text-indigo-600">
              Adobe Illustrator
            </span>
          </div>
          <div>
            <span className="sm:p-3 p-2 bg-indigo-50 sm:text-base text-sm rounded-lg inline-block sm:mb-4 mb-3 text-indigo-600">
              Adobe Photoshop
            </span>
          </div>
          <div>
            <span className="sm:p-3 p-2 bg-indigo-50 sm:text-base text-sm rounded-lg inline-block sm:mb-4 mb-3 text-indigo-600">
              Adobe xd
            </span>
          </div>
          <div>
            <span className="sm:p-3 p-2 bg-indigo-50 sm:text-base text-sm rounded-lg inline-block sm:mb-4 mb-3 text-indigo-600">
              App design
            </span>
          </div>
          <div>
            <span className="sm:p-3 p-2 bg-indigo-50 sm:text-base text-sm rounded-lg inline-block sm:mb-4 mb-3 text-indigo-600">
              Application design
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between sm:mb-4 mb-3">
          <h4 className="sm:text-base text-sm font-medium">Contact information</h4>
          <a
            href="/"
            className="sm:text-base text-sm text-indigo-600 font-medium"
          >
            Edit
          </a>
        </div>
        <div className="md:p-8 sm:p-6 p-4 bg-white sm:rounded-2xl rounded-xl md:mb-6 sm:mb-4 mb-3">
          <div className="sm:mb-4 mb-3">
            <p className="mb-2 sm:text-base text-sm text-dark-black">Email</p>
            <h4 className="sm:text-base text-sm  font-semibold">info@gmail.com</h4>
          </div>
          <div className="mb-4">
            <p className="mb-2 sm:text-base text-sm  text-dark-black">Phone number</p>
            <h4 className="sm:text-base text-sm  font-semibold">+8801784453204</h4>
          </div>
          <div className="mb-4">
            <p className="mb-2 sm:text-base text-sm  text-dark-black">Address</p>
            <h4 className="sm:text-base text-sm  font-semibold">Barai</h4>
          </div>
          <div className="mb-4">
            <p className="mb-2 sm:text-base text-sm  text-dark-black">State</p>
            <h4 className="sm:text-base text-sm  font-semibold">Kasba</h4>
          </div>
          <div>
            <p className="mb-2 sm:text-base text-sm  text-dark-black">City </p>
            <h4 className="sm:text-base text-sm  font-semibold">Brahmanbaria</h4>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4">
          <h4 className="sm:text-base text-sm  font-medium">Employer questions</h4>
          <a
            href="/"
            className="sm:sm:text-base text-sm  text-sm text-indigo-600 font-medium"
          >
            Edit
          </a>
        </div>
        <div className="md:p-8 sm:p-6 p-4 bg-white sm:rounded-2xl rounded-xl">
          <div className="mb-4">
            <p className="mb-2 sm:text-base text-sm  text-dark-black">Question 1</p>
            <h4 className="sm:text-base text-sm  font-semibold">Ans will be here</h4>
          </div>
          <div className="mb-4">
            <p className="mb-2 sm:text-base text-sm  text-dark-black">Question 2</p>
            <h4 className="sm:text-base text-sm  font-semibold">Ans will be here</h4>
          </div>
          <div className="mb-4">
            <p className="mb-2 sm:text-base text-sm  text-dark-black">Question 3</p>
            <h4 className="sm:text-base text-sm  font-semibold">Ans will be here</h4>
          </div>
          <div className="">
            <p className="mb-2 sm:text-base text-sm  text-dark-black">Question 4</p>
            <h4 className="sm:text-base text-sm  font-semibold">Ans will be here</h4>
          </div>
        </div>
        <div className="mt-10 text-end">
          <div
            onClick={prevStep}
            className="inline-block md:py-4 py-3 md:px-10 px-6 rounded-lg bg-shade-gray font-medium sm:text-base text-sm"
          >
            Back
          </div>
          <div
            onClick={nextStep}
            className="inline-block md:py-4 py-3 md:px-10 px-6 rounded-lg bg-amber-300 font-medium sm:text-base text-sm ml-6"
          >
            Submit
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepFour;
