import React from 'react';
import p from '../../../assets/participants.svg';
import q from '../../../assets/questions.svg';
import c from '../../../assets/clock-white.svg';

const StepSixHeader = ({ retake }: any) => {
  return (
    <div className="md:w-4/5 w-full">
      <div className="flex justify-end items-center">
        {/* <div className="text-center">
          <h4 className="text-white flex items-center md:text-sm text-xs mb-1">
            <img className="md:w-6 w-5 md:h-6 h-5 mr-1" src={p} alt="" /> Participate
          </h4>
          <h3 className="font-semibold sm:text-base md:text-sm text-xs text-white">12k</h3>
        </div>
        <div className="text-center md:ml-10 ml-6">
          <h4 className="text-white flex items-center md:text-sm text-xs mb-1">
            <img className="md:w-6 w-5 md:h-6 h-5 mr-1" src={q} alt="" /> Questions
          </h4>
          <h3 className="font-semibold sm:text-base md:text-sm text-xs text-white">32</h3>
        </div>
        <div className="text-center md:ml-10 ml-6">
          <h4 className="text-white flex items-center md:text-sm text-xs mb-1">
            <img className="md:w-6 w-5 md:h-6 h-5 mr-1" src={c} alt="" /> Time
          </h4>
          <h3 className="font-semibold sm:text-base md:text-sm text-xs text-white">
            50mins
          </h3>
        </div> */}
        <div className="md:ml-10 ml-6">
          <div
            onClick={retake}
            className="bg-amber-300 md:text-base text-sm md:py-3.5 sm:py-2.5 py-2 rounded-lg md:w-40 sm:w-32 w-full sm:px-0 px-3 text-center inline-block font-medium"
          >
            Retake
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepSixHeader;
