import React from 'react';
import google from '../../assets/google-logo.svg';
import clock from '../../assets/clock.svg';
import star from '../../assets/gray-star.svg';
import icici_logo from '../../assets/icici-logo.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { axiosPublic } from '../../api/axios';
const Jobs = () => {
  // const jobs = [
  //   {
  //     title: "Team Leader",
  //     description: `We are looking for a result-driven team leader to be responsible for monitoring and supervising a team of consultants to achieve the organization’s objectives and increase sales. You will be responsible for providing guidance, instruction, training, and leadership skills to inspire the team to perform at their optimum. You should create a cohesive team that works efficiently together to maximize the sales team's potential.

  //   To be successful as a team leader, you should have a track record of team motivation and positive sales results. Ultimately, a top-notch team leader should display excellent interpersonal skills to build quality relationships with the team and with customers, be goal-driven, and manage the day-to-day activities of the team efficiently.

  //   Team Leader Responsibilities:

  //   Managing the day-to-day activities of the team.
  //   Motivating the team to achieve organizational goals.
  //   Developing and implementing a timeline to achieve targets.
  //   Delegating tasks to team members.
  //   Conducting training of team members to maximize their potential.
  //   Empowering team members with skills to improve their confidence, product knowledge, and communication skills.
  //   Conducting quarterly performance reviews.
  //   Contributing to the growth of the company through a successful team.
  //   Creating a pleasant working environment that inspires the team.`,
  //     location: "Kolkata (Work From Office)",
  //     company: "Google",
  //     rating: "4.5",
  //     type: "Office Work",
  //     hiringStatus: "Urgently Hiring",
  //   },
  //   {
  //     title: "Preschool Franchisee Expansion Manager",
  //     description: `As a Preschool Franchisee Expansion Manager, your primary responsibility will be to drive the growth and expansion of our preschool franchise network. You will be responsible for identifying potential franchisees, evaluating their suitability, and guiding them through the process of opening new preschool locations. Your goal will be to ensure consistent growth, maintain brand standards, and maximize profitability while upholding our mission of providing quality early childhood education.
  // Key Responsibilities:
  // Franchisee Recruitment:
  // Identify potential franchisees through various channels, including networking, referrals, and targeted marketing campaigns.
  // Conduct thorough evaluations of potential franchisees, assessing their financial capabilities, business acumen, and alignment with our brand values.
  // Present franchise opportunities to qualified candidates, highlighting the benefits and advantages of joining our preschool network.
  // Franchisee Onboarding and Training:
  // Guide new franchisees through the onboarding process, including legal documentation, site selection, and facility setup.
  // Coordinate comprehensive training programs for franchisees, ensuring they understand our curriculum, operational procedures, and quality standards.
  // Provide ongoing support and mentorship to franchisees, addressing any concerns or challenges they encounter during the setup and initial operations phase.
  // Market Analysis and Expansion Strategy:
  // Conduct market research to identify potential locations for new preschools based on demographics, competition, and demand.
  // Develop a strategic expansion plan, outlining target markets, growth projections, and resource allocation.
  // Collaborate with internal teams to create marketing and promotional materials to attract potential franchisees and increase brand awareness.
  // Franchisee Relationship Management:
  // Foster strong relationships with franchisees, serving as their main point of contact for operational and strategic support.
  // Regularly visit franchise locations to assess performance, identify areas for improvement, and ensure compliance with brand standards.
  // Organize regular franchisee meetings, conferences, and training sessions to foster networking, knowledge sharing, and continuous improvement.
  // Performance Analysis and Reporting:
  // Monitor and analyze key performance metrics for franchise locations, including revenue, expenses, enrollment, and customer satisfaction.
  // Prepare comprehensive reports and presentations to communicate franchise performance to senior management and stakeholders.
  // Identify trends and insights to inform decision-making processes and refine expansion strategies.
  // `,
  //     location: "Kolkata (Work From Office)",
  //     company: "Google",
  //     rating: "4.5",
  //     type: "Office Work",
  //     hiringStatus: "Urgently Hiring",
  //   },
  //   {
  //     title: "Business Analyst IT",
  //     description: `Create User Story | Wireframe | Prototype | Feature List | SRS | BRD | DFD flow diagrams as per requirement.

  //   Responsibilities:

  //   - Capturing the customer requirement and Working closely with the engineering team toexplain the requirements with the required documents. Example - user story, DFD, Mock-up, Sequence Diagram etc.

  //   - Responsible to prepare User Stories, Data Flow, Business Requirement Documents (BRD), Functional Requirement Specification (FRS) and Used cases.

  //   - Ensuring that the company's product & features can deliver on the customer's requirements.

  //   - Replying to customers following requests for information and proposal requests.

  //   - Producing detailed costing for customers and ensuring the contract is profitable.

  //   - Communicate with team heads regarding common challenges, roadblocks and other issues that interrupt their workflow

  //   - Create detailed plans for the execution and implementation of new processes, including budgetary guidelines and key personnel

  //   - Construct workflow charts and diagrams; study system capabilities; writing specifications

  //   - Define project requirements by identifying project milestones, phases and elements.

  //   - Monitor project progress and perform daily, weekly and monthly reviews and analyses of current processes using operational metrics and reports

  //   Experience:

  //   - Ability to impact operations and effect change without being confrontational

  //   - Detail oriented, analytical, and inquisitive

  //   - Ability to work independently and with others

  //   - Extremely organized with strong time-management skills

  //   - Excellent communication skills and ability to explain complex issues

  //   Skills:

  //   - Any database experience is strongly preferred.

  //   - Ability to build large complex reports by using macros, calculations, etc.

  //   - Ability to take abstract business workflows and translate them into technical workflow/process documentation for SaaS automation

  //   - Ability to communicate with the engineering team on various aspects of a highly technical product

  //   Desired Skills & Expertise:

  //   Functional Attributes:

  //   - Advanced MS Office skills

  //   - Project Management

  //   - Interpersonal Communication skills

  //   Qualifications: Graduation Degree in the appropriate field of study or equivalent work experience`,
  //     location: "Kolkata (Work From Office)",
  //     company: "Google",
  //     rating: "4.5",
  //     type: "Office Work",
  //     hiringStatus: "Urgently Hiring",
  //   },
  //   {
  //     title: "Channel Sales Manager",
  //     description: `Major Areas of Responsibility：

  //     Achieve and exceed sales quota and business targets with the assigned partners.
  //     Execute channel strategy with the assigned partners and implement necessary critical tasks. Monitor the effectiveness of the strategy, identify opportunities and areas for improvement, suggesting and implementing approved corrective measures.
  //     Serve as the lead point of contact for all account management matters for the selected channel partners, as well as act as a trusted advisor for these partners.
  //     Continually monitor the performance and competency level of distributors based on Moxa’s Channel Management Processes (CME), define capabilities development plan together with the distributors and lead activities to accomplish the plan. Conduct regular review of the progress in the plan execution and provide periodic update on the status to key stakeholders in partner and MOXA organizations
  //     Coordinates the activities with CSMs responsible for the specific territories to ensure success of pan-European channel partners in the respective territories.
  //     Collaborate with sales operations, product marketing, technical support, marketing, FAE and finance teams to ensure proper support and development of channel partners.
  //     Collaborate with the Key Account Managers on business with key and preferred accounts served by distributors in the assigned Area.
  //     Influence Moxa’s strategy by participating in relevant task forces and providing market intelligence on vertical market segments, partner profiles, competition as well as key applications that could support Moxa business objectives.
  //     Embrace Moxa’s core values and operate in accordance with them.

  //     Qualifications

  //     Bachelor´s degree in engineering; or equivalent combination of education and experience
  //     Fluent English + German in written and spoken
  //     5-8 years of working experience; at least 5 years of sales experience
  //     Familiar with European industrial market and business practice, management and account development
  //     Goal-oriented
  //     Self-motivated and self-directed.`,
  //     location: "Kolkata (Work From Office)",
  //     company: "Google",
  //     rating: "4.5",
  //     type: "Office Work",
  //     hiringStatus: "Urgently Hiring",
  //   },
  // ];

  const [jobs, setJobs] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      await axiosPublic.get('/job').then((res) => setJobs(res.data));
    })();
  }, []);
  return (
    // <section className="sm:py-20 py-10 bg-light-gray">
    //   <div className="relative isolate px-4 mx-auto max-w-7xl">
    //     <div className="flex flex-wrap -mx-6 md:mb-10 mb-6">
    //       <div className="md:w-2/4 w-full px-6 md:text-left text-center md:mb-0 mb-6">
    //         <h3 className="md:text-3xl text-2xl text-gray-black font-medium mb-4">
    //           Jobs
    //         </h3>
    //         <p className="text-light-black md:text-base text-sm">
    //           Skill profiling stands as a pivotal process within this application, shaping the foundation for effective talent recognition and development.

    //         </p>
    //       </div>
    //       <div className="md:w-2/4 w-full px-6 md:text-right text-center">
    //         <a
    //           href="/login"
    //           className="inline-flex items-center border width-auto lg:text-base text-sm text-gray-black rounded-xl border-gray-black px-4 py-2 sm:px-6 sm:py-4"
    //         >
    //           View All
    //           <svg
    //             className="ml-2"
    //             width={24}
    //             height={24}
    //             viewBox="0 0 24 24"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M4 20L20 4M20 4H7.2M20 4V16.8"
    //               stroke="#0A1026"
    //               strokeWidth={2}
    //               strokeLinecap="round"
    //             />
    //           </svg>
    //         </a>
    //       </div>
    //     </div>
    //     {jobs?.map((job: any, idx: number) => {
    //       return (
    //         <div
    //           onClick={() => ((window as Window).location = '/login')}
    //           key={idx}
    //         >
    //           <div className="md:p-10 sm:p-6 p-4 bg-white rounded-2xl md:mb-6 sm:mb-4 mb-3 border-2 border-white hover:border-yellow-300 transition-all">
    //             <h3 className="lg:text-2xl sm:text-xl text-base mb-2 font-medium">
    //               {job.title}
    //             </h3>
    //             <h4 className="flex items-center text-dark-black lg:text-base text-sm mb-2 font-bold">
    //               {/* <img
    //                 width={24}
    //                 height={24}
    //                 className="mr-2"
    //                 src={google}
    //                 alt=""
    //               /> */}
    //               Company: {job.companyName}
    //               {/* <span className="flex items-center ml-4 text-black">
    //                     {job.rating}{" "}
    //                     <img className="ml-1" src={grayStart} alt="" />
    //                   </span> */}
    //             </h4>
    //             <div className="flex mb-2">
    //               <div className="flex items-center mr-6 text-dark-black sm:text-base text-sm">
    //                 <span className="w-2 h-2 rounded bg-dark-black inline-block mr-2" />
    //                 {job.jobType}
    //               </div>
    //               <div className="flex items-center">
    //                 {/* <img
    //                       className="mr-2"
    //                       width={24}
    //                       height={24}
    //                       src={clock}
    //                       alt=""
    //                     /> */}
    //                 {/* <p className="text-dark-black sm:text-base text-sm">
    //                       {job.hiringStatus}
    //                     </p> */}
    //               </div>
    //             </div>
    //             {/* <span className="py-2 px-3 rounded-lg text-light-green bg-light-green lg:text-base text-sm font-medium mb-2 inline-block">
    //               You’re fit for this job
    //             </span> */}
    //             <p className="text-dark-black lg:text-base text-sm">
    //               {job.jobDetails?.slice(0, 200)}...
    //             </p>
    //           </div>
    //         </div>
    //       );
    //     })}
    //     {/* <Link
    //       to="/login"
    //       className="block sm:p-10 p-6 bg-white rounded-2xl mb-6 border-2 border-white hover:border-yellow-300 transition-all"
    //     >
    //       <h3 className="lg:text-2xl sm:text-xl text-base mb-2 font-medium">
    //         UX UI Designer
    //       </h3>
    //       <h4 className="flex items-center text-dark-black lg:text-base text-sm mb-2">
    //         <img width={24} height={24} className="mr-2" src={google} alt="" />
    //         Company Name
    //         <span className="flex items-center ml-4 text-black">
    //           2.5 <img className="ml-1" src={star} alt="" />
    //         </span>
    //       </h4>
    //       <div className="flex mb-2">
    //         <div className="flex items-center mr-6 text-dark-black sm:text-base text-sm">
    //           <span className="w-2 h-2 rounded bg-dark-black inline-block mr-2" />
    //           Remote
    //         </div>
    //         <div className="flex items-center">
    //           <img className="mr-2" width={24} height={24} src={clock} alt="" />
    //           <p className="text-dark-black sm:text-base text-sm">
    //             Urgently hiring
    //           </p>
    //         </div>
    //       </div>
    //       <span className="py-2 px-3 rounded-lg text-light-green bg-light-green lg:text-base text-sm font-medium mb-2 inline-block">
    //         You’re fit for this job
    //       </span>
    //       <p className="text-dark-black lg:text-base text-sm">
    //         Come join our exciting team at AxleHire as a Sr UX/UI Designer, or
    //         User Experience/User Interface Designer as we address last mile
    //         opportunities. The primary responsibility is for creating and
    //         optimizing the user experience across all AxleHire tools and mobile
    //         app.
    //       </p>
    //     </Link>
    //     <Link
    //       to="/login"
    //       className="block sm:p-10 p-6 bg-white rounded-2xl border-2 border-white hover:border-yellow-300 transition-all"
    //     >
    //       <h3 className="lg:text-2xl sm:text-xl text-base mb-2 font-medium">
    //         UX UI Designer
    //       </h3>
    //       <h4 className="flex items-center text-dark-black lg:text-base text-sm mb-2">
    //         <img width={24} height={24} className="mr-2" src={google} alt="" />
    //         Company Name
    //         <span className="flex items-center ml-4 text-black">
    //           2.5 <img className="ml-1" src={star} alt="" />
    //         </span>
    //       </h4>
    //       <div className="flex mb-2">
    //         <div className="flex items-center mr-6 text-dark-black sm:text-base text-sm">
    //           <span className="w-2 h-2 rounded bg-dark-black inline-block mr-2" />
    //           Remote
    //         </div>
    //         <div className="flex items-center">
    //           <img className="mr-2" width={24} height={24} src={clock} alt="" />
    //           <p className="text-dark-black sm:text-base text-sm">
    //             Urgently hiring
    //           </p>
    //         </div>
    //       </div>
    //       <span className="py-2 px-3 rounded-lg text-light-red bg-light-red lg:text-base text-sm font-medium mb-2 inline-block">
    //         Your resume might be missing qualifications?
    //       </span>
    //       <p className="text-dark-black lg:text-base text-sm">
    //         Come join our exciting team at AxleHire as a Sr UX/UI Designer, or
    //         User Experience/User Interface Designer as we address last mile
    //         opportunities. The primary responsibility is for creating and
    //         optimizing the user experience across all AxleHire tools and mobile
    //         app.
    //       </p>
    //     </Link> */}
    //   </div>
    // </section>
    <section className="sm:py-20 py-10 bg-[#AEEEEE]">
      <div className="relative isolate sm:px-6 px-4 mx-auto max-w-7xl lg:px-8">
        <div className="flex flex-wrap mb-10">
          <div className="md:w-2/4 w-full px-6 md:text-left text-center md:mb-0 mb-6">
            <h3 className="md:text-3xl text-2xl text-gray-black font-medium mb-4">
              Jobs
            </h3>
            <p className="text-light-black md:text-base text-sm">
              Assessment lays the crucial foundation for self awareness and
              success in any role.
            </p>
          </div>
          <div className="md:w-2/4 w-full flex px-6 md:justify-end justify-center items-center">
            <a
              href=""
              className="inline-flex items-center border width-auto mr-6 lg:text-base text-sm text-gray-black rounded-xl border-gray-black px-4 py-2 sm:px-6 sm:py-4"
            >
              <svg
                className="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M18.3334 2.5H1.66669L8.33335 10.3833V15.8333L11.6667 17.5V10.3833L18.3334 2.5Z"
                  stroke="#0A1026"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Filter
            </a>
            <a
              href=""
              className="inline-flex items-center border width-auto lg:text-base text-sm text-gray-black rounded-xl border-gray-black px-4 py-2 sm:px-6 sm:py-4"
            >
              View All
              <svg
                className="ml-2"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 20L20 4M20 4H7.2M20 4V16.8"
                  stroke="#0A1026"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="flex flex-wrap">
          {jobs?.map((job: any, idx) => (
            <div
              key={idx}
              className="lg:w-3/12 md:w-4/12 sm:w-6/12 w-full px-2"
            >
              <div className="bg-white sm:p-4 p-3 rounded-2xl mb-4 box-shadow-light">
                <div className="flex items-center mb-4">
                  {/* <img src={icici_logo} className="mr-3 w-10" alt="" /> */}
                  <h3 className="lg:text-2xl sm:text-xl text-base font-medium">
                    {job?.title?.slice(0, 13)}...
                  </h3>
                </div>
                <h4 className="flex items-center text-dark-black lg:text-base text-sm mb-2">
                  {/* <img
                    width={24}
                    height={24}
                    className="mr-2"
                    src="../images/google-logo.svg"
                    alt=""
                  /> */}
                  {job?.company}
                  {/* <span className="flex items-center ml-4 text-black">
                    2.5{' '}
                    <img
                      className="ml-1"
                      src="../images/gray-star.svg"
                      alt=""
                    />
                  </span> */}
                </h4>
                <div className="flex mb-2">
                  <div className="flex items-center mr-6 text-dark-black sm:text-base text-sm">
                    <span className="w-2 h-2 rounded bg-dark-black inline-block mr-2" />
                    Remote
                  </div>
                  <div className="flex items-center">
                    <img
                      className="mr-2"
                      width={24}
                      height={24}
                      src={clock}
                      alt=""
                    />
                    <p className="text-dark-black sm:text-base text-sm">
                      Urgently hiring
                    </p>
                  </div>
                </div>
                <div className="flex mb-6">
                  <div className="flex items-center mr-6 text-dark-black sm:text-base text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-1"
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M15.8333 5H13.3333V4.16667C13.3333 3.72464 13.1577 3.30072 12.8452 2.98816C12.5326 2.67559 12.1087 2.5 11.6667 2.5H8.33333C7.89131 2.5 7.46738 2.67559 7.15482 2.98816C6.84226 3.30072 6.66667 3.72464 6.66667 4.16667V5H4.16667C3.50362 5 2.86774 5.26339 2.3989 5.73223C1.93006 6.20107 1.66667 6.83696 1.66667 7.5V15C1.66667 15.663 1.93006 16.2989 2.3989 16.7678C2.86774 17.2366 3.50362 17.5 4.16667 17.5H15.8333C16.4964 17.5 17.1323 17.2366 17.6011 16.7678C18.0699 16.2989 18.3333 15.663 18.3333 15V7.5C18.3333 6.83696 18.0699 6.20107 17.6011 5.73223C17.1323 5.26339 16.4964 5 15.8333 5ZM8.33333 4.16667H11.6667V5H8.33333V4.16667ZM16.6667 15C16.6667 15.221 16.5789 15.433 16.4226 15.5893C16.2663 15.7455 16.0543 15.8333 15.8333 15.8333H4.16667C3.94565 15.8333 3.73369 15.7455 3.57741 15.5893C3.42113 15.433 3.33333 15.221 3.33333 15V10.8333H6.66667V11.6667C6.66667 11.8877 6.75446 12.0996 6.91074 12.2559C7.06702 12.4122 7.27899 12.5 7.5 12.5C7.72101 12.5 7.93297 12.4122 8.08926 12.2559C8.24554 12.0996 8.33333 11.8877 8.33333 11.6667V10.8333H11.6667V11.6667C11.6667 11.8877 11.7545 12.0996 11.9107 12.2559C12.067 12.4122 12.279 12.5 12.5 12.5C12.721 12.5 12.933 12.4122 13.0893 12.2559C13.2455 12.0996 13.3333 11.8877 13.3333 11.6667V10.8333H16.6667V15ZM16.6667 9.16667H3.33333V7.5C3.33333 7.27899 3.42113 7.06702 3.57741 6.91074C3.73369 6.75446 3.94565 6.66667 4.16667 6.66667H15.8333C16.0543 6.66667 16.2663 6.75446 16.4226 6.91074C16.5789 7.06702 16.6667 7.27899 16.6667 7.5V9.16667Z"
                        fill="#424349"
                      />
                    </svg>
                    7-18 yr
                  </div>
                  <div className="flex items-center">
                    <p className="text-dark-black sm:text-base text-sm">
                      ₹ 20,000 - 30, 0000
                    </p>
                  </div>
                </div>
                <p className="text-dark-black lg:text-base text-sm">
                  {job.jobDetails?.slice(0, 210)}...
                </p>
                <div className="text-center mt-6">
                  <a
                    href="/login"
                    className="inline-block py-3 px-8 rounded-xl bg-dark-blue text-white text-base"
                  >
                    View Job
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Jobs;
