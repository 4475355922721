import React from "react";
import coursesImage from "../../../assets/course-img.png";
import ci1 from "../../../assets/courses-icon/1.svg";
import ci2 from "../../../assets/courses-icon/2.svg";
import ci3 from "../../../assets/courses-icon/3.svg";
import avatar2 from "../../../assets/avatar-02.png";

const TabOne = () => {
  return (
    <>
      <div className="bg-white p-4 rounded-2xl mb-4 flex flex-wrap items-center">
        <div className="flex flex-wrap items-center sm:w-2/5 w-full">
          <div>
            <img
              className="w-20 h-20 rounded-xl object-cover mr-3"
              src={coursesImage}
              alt=""
            />
          </div>
          <div>
            <h4 className="md:text-base text-sm font-semibold mb-3">
              Become a expert Figma Designers in 3 months
            </h4>
            <div className="flex items-center">
              <div className="font-sm text-dark-white mr-3">Mentor</div>
              <div className="flex items-center">
                <img
                  className="w-6 h-6 object-cover rounded-full mr-2"
                  src={avatar2}
                  alt=""
                />
                <h4 className="text-sm">Raju</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:w-1/5 w-full sm:py-0 py-3">
          <p className="text-sm text-dark-black mb-1">Duration</p>
          <h4 className="text-sm">1hr 50mins</h4>
        </div>
        <div className="sm:w-1/5 w-full">
          <div className="flex items-center justify-between mb-1">
            <div className="mb-1 text-sm font-medium dark:text-white">50%</div>
            <div className="mb-1 text-sm font-medium dark:text-white">
              8/12%
            </div>
          </div>
          <div className="w-full bg-gray-300 rounded-full h-1 sm:mb-0 mb-4 dark:bg-gray-700">
            <div
              className="bg-blue-600 h-1 rounded-full"
              style={{ width: "45%" }}
            />
          </div>
        </div>
        <div className="flex items-center justify-end sm:w-1/5 w-full">
          <div className="flex items-center mr-4">
            <img className="h-5 w-5 mr-1" src={ci1} alt="" />
            <span className="text-sm">1k</span>
          </div>
          <div className="flex items-center mr-4">
            <img className="h-5 w-5 mr-1" src={ci2} alt="" />
            <span className="text-sm">1k</span>
          </div>
          <div className="flex items-center mr-4">
            <img className="h-5 w-5 mr-1" src={ci3} alt="" />
            <span className="text-sm">50</span>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-2xl mb-4 flex flex-wrap items-center">
        <div className="flex flex-wrap items-center sm:w-2/5 w-full">
          <div>
            <img
              className="w-20 h-20 rounded-xl object-cover mr-3"
              src={coursesImage}
              alt=""
            />
          </div>
          <div>
            <h4 className="md:text-base text-sm font-semibold mb-3">
              Become a expert Figma Designers in 3 months
            </h4>
            <div className="flex items-center">
              <div className="font-sm text-dark-white mr-3">Mentor</div>
              <div className="flex items-center">
                <img
                  className="w-6 h-6 object-cover rounded-full mr-2"
                  src={avatar2}
                  alt=""
                />
                <h4 className="text-sm">Raju</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:w-1/5 w-full sm:py-0 py-3">
          <p className="text-sm text-dark-black mb-1">Duration</p>
          <h4 className="text-sm">1hr 50mins</h4>
        </div>
        <div className="sm:w-1/5 w-full">
          <div className="flex items-center justify-between mb-1">
            <div className="mb-1 text-sm font-medium dark:text-white">50%</div>
            <div className="mb-1 text-sm font-medium dark:text-white">
              8/12%
            </div>
          </div>
          <div className="w-full bg-gray-300 rounded-full h-1 sm:mb-0 mb-4 dark:bg-gray-700">
            <div
              className="bg-blue-600 h-1 rounded-full"
              style={{ width: "45%" }}
            />
          </div>
        </div>
        <div className="flex items-center justify-end sm:w-1/5 w-full">
          <div className="flex items-center mr-4">
            <img className="h-5 w-5 mr-1" src={ci1} alt="" />
            <span className="text-sm">1k</span>
          </div>
          <div className="flex items-center mr-4">
            <img className="h-5 w-5 mr-1" src={ci2} alt="" />
            <span className="text-sm">1k</span>
          </div>
          <div className="flex items-center mr-4">
            <img className="h-5 w-5 mr-1" src={ci3} alt="" />
            <span className="text-sm">50</span>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-2xl mb-4 flex flex-wrap items-center">
        <div className="flex flex-wrap items-center sm:w-2/5 w-full">
          <div>
            <img
              className="w-20 h-20 rounded-xl object-cover mr-3"
              src={coursesImage}
              alt=""
            />
          </div>
          <div>
            <h4 className="md:text-base text-sm font-semibold mb-3">
              Become a expert Figma Designers in 3 months
            </h4>
            <div className="flex items-center">
              <div className="font-sm text-dark-white mr-3">Mentor</div>
              <div className="flex items-center">
                <img
                  className="w-6 h-6 object-cover rounded-full mr-2"
                  src={avatar2}
                  alt=""
                />
                <h4 className="text-sm">Raju</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:w-1/5 w-full sm:py-0 py-3">
          <p className="text-sm text-dark-black mb-1">Duration</p>
          <h4 className="text-sm">1hr 50mins</h4>
        </div>
        <div className="sm:w-1/5 w-full">
          <div className="flex items-center justify-between mb-1">
            <div className="mb-1 text-sm font-medium dark:text-white">50%</div>
            <div className="mb-1 text-sm font-medium dark:text-white">
              8/12%
            </div>
          </div>
          <div className="w-full bg-gray-300 rounded-full h-1 sm:mb-0 mb-4 dark:bg-gray-700">
            <div
              className="bg-blue-600 h-1 rounded-full"
              style={{ width: "45%" }}
            />
          </div>
        </div>
        <div className="flex items-center justify-end sm:w-1/5 w-full">
          <div className="flex items-center mr-4">
            <img className="h-5 w-5 mr-1" src={ci1} alt="" />
            <span className="text-sm">1k</span>
          </div>
          <div className="flex items-center mr-4">
            <img className="h-5 w-5 mr-1" src={ci2} alt="" />
            <span className="text-sm">1k</span>
          </div>
          <div className="flex items-center mr-4">
            <img className="h-5 w-5 mr-1" src={ci3} alt="" />
            <span className="text-sm">50</span>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-2xl mb-4 flex flex-wrap items-center">
        <div className="flex flex-wrap items-center sm:w-2/5 w-full">
          <div>
            <img
              className="w-20 h-20 rounded-xl object-cover mr-3"
              src={coursesImage}
              alt=""
            />
          </div>
          <div>
            <h4 className="md:text-base text-sm font-semibold mb-3">
              Become a expert Figma Designers in 3 months
            </h4>
            <div className="flex items-center">
              <div className="font-sm text-dark-white mr-3">Mentor</div>
              <div className="flex items-center">
                <img
                  className="w-6 h-6 object-cover rounded-full mr-2"
                  src={avatar2}
                  alt=""
                />
                <h4 className="text-sm">Raju</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:w-1/5 w-full sm:py-0 py-3">
          <p className="text-sm text-dark-black mb-1">Duration</p>
          <h4 className="text-sm">1hr 50mins</h4>
        </div>
        <div className="sm:w-1/5 w-full">
          <div className="flex items-center justify-between mb-1">
            <div className="mb-1 text-sm font-medium dark:text-white">50%</div>
            <div className="mb-1 text-sm font-medium dark:text-white">
              8/12%
            </div>
          </div>
          <div className="w-full bg-gray-300 rounded-full h-1 sm:mb-0 mb-4 dark:bg-gray-700">
            <div
              className="bg-blue-600 h-1 rounded-full"
              style={{ width: "45%" }}
            />
          </div>
        </div>
        <div className="flex items-center justify-end sm:w-1/5 w-full">
          <div className="flex items-center mr-4">
            <img className="h-5 w-5 mr-1" src={ci1} alt="" />
            <span className="text-sm">1k</span>
          </div>
          <div className="flex items-center mr-4">
            <img className="h-5 w-5 mr-1" src={ci2} alt="" />
            <span className="text-sm">1k</span>
          </div>
          <div className="flex items-center mr-4">
            <img className="h-5 w-5 mr-1" src={ci3} alt="" />
            <span className="text-sm">50</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabOne;
