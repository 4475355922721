import React from "react";
import DashboardLayout from "../../layout/DashboardLayout";
import MainContent from "../../components/CourseDetails/MainContent";

const CourseDetails = () => {
  return (
    <DashboardLayout>
      <MainContent />
    </DashboardLayout>
  );
};

export default CourseDetails;
