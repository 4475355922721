import React from "react";
import google from "../../../assets/google-logo.svg";
import { PropsType } from "./types/PropsTypes";

const StepTwo = ({ prevStep, nextStep }: PropsType) => {
  return (
    <section className="md:py-10 sm:py-8 py-6 bg-white-sm-light sm:min-h-[calc(100vh-152px)] min-h-[calc(100vh-96px)]">
      <div className="mx-auto max-w-2xl px-4">
        <div className="md:p-8 sm:p-6 p-4 sm:rounded-2xl rounded-xl bg-white md:mb-6 sm:mb-4 mb-3">
          <h4 className="sm:text-base text-sm font-medium mb-2">UX UI Designer</h4>
          <h4 className="flex items-center text-dark-black lg:text-base text-sm">
            <img className="mr-2 md:w-6 w-5 md:h-6 h-5" src={google} alt="" />{" "}
            Company Name{" "}
          </h4>
        </div>
        <div className="md:p-8 sm:p-6 p-4 bg-white sm:rounded-2xl rounded-xl">
          <form action="#" method="GET">
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block sm:text-base text-sm font-medium sm:leading-6 text-gray-900 mb-2"
              >
                Email
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3 rounded-lg"
                  placeholder="Enter your email"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block sm:text-base text-sm font-medium sm:leading-6 text-gray-900 mb-2"
              >
                Country Code
              </label>
              <div className="mt-2">
                <select
                  name=""
                  id=""
                  required
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3 rounded-lg"
                >
                  <option value="">Bangladesh (+880)</option>
                </select>
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block sm:text-base text-sm font-medium sm:leading-6 text-gray-900 mb-2"
              >
                Phone number
              </label>
              <div className="mt-2">
                <input
                  name="country"
                  type="text"
                  required
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3 rounded-lg"
                  defaultValue={1784453204}
                  placeholder="Enter your number"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block sm:text-base text-sm font-medium sm:leading-6 text-gray-900 mb-2"
              >
                Address
              </label>
              <div className="mt-2">
                <input
                  name="country"
                  type="text"
                  required
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3 rounded-lg"
                  placeholder="Type here"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block sm:text-base text-sm font-medium sm:leading-6 text-gray-900 mb-2"
              >
                State
              </label>
              <div className="mt-2">
                <input
                  name="country"
                  type="text"
                  required
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3 rounded-lg"
                  placeholder="Type here"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block sm:text-base text-sm font-medium sm:leading-6 text-gray-900 mb-2"
              >
                City
              </label>
              <div className="mt-2">
                <input
                  name="country"
                  type="text"
                  required
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3 rounded-lg"
                  placeholder="Type here"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="sm:mt-10 mt-6 text-end">
          <a
             onClick={prevStep}
            className="inline-block md:py-4 py-3 md:px-10 px-6 rounded-lg bg-shade-gray font-medium sm:text-base text-sm"
          >
            Back
          </a>
          <div
            onClick={nextStep}
            className="inline-block md:py-4 py-3 md:px-10 px-6 rounded-lg bg-amber-300 font-medium sm:text-base text-sm ml-6"
          >
            Next
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepTwo;
