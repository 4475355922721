import React from 'react';
import google from '../../assets/google-logo.svg';
import { Link } from 'react-router-dom';

const JobModal = ({ close, job }: any) => {
  return (
    <>
      <div
        id="jodDetails"
        className="fixed top-0 transition-all overflow-y-auto ease-in-out duration-700 bg-white z-20 h-screen md:px-8 sm:px-6 px-4 md:py-14 sm:py-10 py-8"
      >
        <div
          onClick={close}
          className="sm:py-4 py-2 sm:px-4 px-3 bg-dark-gray rounded-lg inline-flex items-center font-medium sm:text-base text-sm md:mb-8 sm:mb-6 mb-4"
        >
          Close
        </div>
        <div className="flex">
          <div className="w-9/12">
            <div className="mb-6">
              <h3 className="lg:text-2xl sm:text-xl text-base mb-2 font-medium">
                {job?.title}
              </h3>
              <h4 className="flex items-center text-dark-black lg:text-base text-sm mb-2">
                {/* <img
                  className="mr-2 md:w-6 w-5 md:h-6 h-5"
                  src={google}
                  alt=""
                /> */}
                {job?.companyName}
                {/* <span className="flex items-center ml-4 text-black">
                  {job?.rating} <img className="ml-1" src={grayStart} alt="" />
                </span> */}
              </h4>
              <div className="flex mb-2">
                <div className="flex items-center mr-6 text-dark-black sm:text-base text-sm">
                  <span className="w-2 h-2 rounded bg-dark-black inline-block mr-2" />
                  {job?.jobType}
                </div>
                <div className="flex items-center">
                  {/* <img
                    className="mr-2 md:w-6 w-5 md:h-6 h-5"
                    src={clock}
                    alt=""
                  />
                  <p className="text-dark-black sm:text-base text-sm">
                    {job?.hiringStatus}
                  </p> */}
                </div>
              </div>
              {/* <span className="py-2 px-3 rounded-lg text-light-green bg-light-green lg:text-base text-sm font-medium inline-block">
                You’re fit for this job
              </span> */}
            </div>
          </div>
          <div className="w-3/12">
            <div className="text-right">
              <Link
                to="/apply-job"
                className="inline-flex items-center border-b border-yellow-300 md:leading-9 font-semibold md:text-base text-sm text-gray-black bg-amber-300 justify-center md:rounded-xl rounded md:px-6 px-2 md:py-3 py-2"
              >
                Apply
                <svg
                  className="ml-2 md:w-6 w-5 md:h-6 h-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 20L20 4M20 4H7.2M20 4V16.8"
                    stroke="#000"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
        <p className="text-dark-black lg:text-base text-sm mb-4">
          {job?.jobDetails}
        </p>
        {/* <h4 className="font-semibold text-base">Background</h4>
        <p className="text-dark-black lg:text-base text-sm">
          Our Client is a successful and leading electronic trading platform
          used by 6000 traders across 1800 firms. The company currently has 600
          employees (in different locations), of which 300 are technologists.
        </p>
        <p className="text-dark-black lg:text-base text-sm">
          We recognize the need for specialist teams with a focus on the UX
          design and development. We’re looking to grow this capability by
          bringing on board talented and driven designers and developers. The
          purpose of these teams is to provide a consistent UI across the whole
          platform as part of its transformation and modernisation.
        </p>
        <p className="text-dark-black lg:text-base text-sm">
          The product vision is to Maximize trading opportunity by providing
          Traders with the ultimate streamlined, flexible &amp; frictionless
          workspace.
        </p>
        <p className="text-dark-black lg:text-base text-sm mb-4">
          The role is perfect for someone who's looking to use their design
          skills to drive change - someone who wants to be part of specialist
          team in a forward-thinking company.
        </p>
        <h4 className="font-semibold text-base">
          General responsibilities include:
        </h4>
        <ul className="pl-5 list-disc mb-4">
          <li className="mb-1 text-dark-black lg:text-base text-sm">
            Work with product teams to understand user needs and identify key
            journeys. These are likely to follow a ‘Design Sprint’ pattern.
          </li>
          <li className="mb-1 text-dark-black lg:text-base text-sm">
            Define and design industry leading UIs for our products.
          </li>
          <li className="mb-1 text-dark-black lg:text-base text-sm">
            Design easy-to-understand end-to-end flows and use tools such as
            Figma (and potentially Axure, if appropriate) to demonstrate how a
            particular flow or interaction will work.
          </li>
          <li className="mb-1 text-dark-black lg:text-base text-sm">
            Work with product teams to understand user needs and identify key
            journeys. These are likely to follow a ‘Design Sprint’ pattern.
          </li>
        </ul>
        <h4 className="font-semibold text-base">Knowledge/Experience</h4>
        <ul className="pl-5 list-disc mb-4">
          <li className="mb-1 text-dark-black lg:text-base text-sm">
            Work with product teams to understand user needs and identify key
            journeys. These are likely to follow a ‘Design Sprint’ pattern.
          </li>
          <li className="mb-1 text-dark-black lg:text-base text-sm">
            Define and design industry leading UIs for our products.
          </li>
          <li className="mb-1 text-dark-black lg:text-base text-sm">
            Design easy-to-understand end-to-end flows and use tools such as
            Figma (and potentially Axure, if appropriate) to demonstrate how a
            particular flow or interaction will work.
          </li>
          <li className="mb-1 text-dark-black lg:text-base text-sm">
            Work with product teams to understand user needs and identify key
            journeys. These are likely to follow a ‘Design Sprint’ pattern.
          </li>
        </ul> */}
      </div>
      <div
        id="jodDetailsOverlay"
        className="fixed top-0 right-0 blur-sm duration-500 bg-gray-transparent transition-all ease-in-out z-10 h-full"
      />
    </>
  );
};

export default JobModal;
