import React, { useState } from 'react';
import ButtonOne from '../shared/ButtonOne';
import google from '../../assets/google-logo.svg';
import linkedin from '../../assets/linkedin.svg';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebase';
import { useFormik } from 'formik';
import { axiosPublic } from '../../api/axios';
import { toast } from 'react-toastify';

const LoginForm = () => {
  const provider = new GoogleAuthProvider();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const authViaGoogle = async () => {
    const result: any = await signInWithPopup(auth, provider);
    if (result) {
      setLoading(true);
    }
    await axiosPublic
      .post('/auth/auth-via-google', {
        email: result.user.email,
        name: result.user.displayName,
        googleAccessToken: result.user.accessToken,
      })
      .then((res) => {
        setLoading(false);
        localStorage.setItem('user', JSON.stringify(res.data));
        localStorage.setItem('token', res.data.accessToken);
        navigate('/dashboard');
      })
      .catch(() => {
        setLoading(false);
        toast.error(
          'There was an error logging you in, please check your credentials!'
        );
      });
  };
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (v) => {
      setLoading(true);
      await axiosPublic
        .post('/auth/login-via-email', v)
        .then((res) => {
          setLoading(false);
          localStorage.setItem('user', JSON.stringify(res.data));
          localStorage.setItem('token', res.data.accessToken);
          navigate('/dashboard');
        })
        .catch(() => {
          setLoading(false);
          toast.error(
            'There was an error logging you in, please check your credentials!'
          );
        });
    },
  });

  return (
    <section className="md:pt-16 md:pb-24 sm:pt-12 sm:pb-16 pt-8 pb-12 bg-light-gray px-2">
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-400 opacity-75 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">
            Logging you in...
          </h2>
          <p className="w-1/3 text-center text-white">
            This may take a few seconds, please don't close this page.
          </p>
        </div>
      )}
      <div className="mx-auto max-w-xl md:pt-5 sm:pt-4 pt-2 sm:pb-6 pb-4 md:pb-8 sm:px-6 px-4 md:px-8 bg-white rounded-3xl">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className=" text-center sm:text-2xl text-xl font-medium leading-9 tracking-tight text-gray-900">
            Welcome back!
          </h2>
        </div>
        <div className="sm:mt-6 mt-4">
          <ButtonOne
            text="Continue with Google"
            iconLocation={google}
            onClick={authViaGoogle}
          />
        </div>
        <div className="inline-flex items-center justify-center w-full">
          <hr className="w-full h-px sm:my-8 my-6 bg-gray-200 border-0 dark:bg-gray-700" />
          <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">
            Or
          </span>
        </div>
        <form className="space-y-6 sm:mt-4 mt-2" action="#" method="POST">
          <div>
            <label
              htmlFor="email"
              className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={formik.values.email}
                onChange={formik.handleChange}
                className="sm:text-base text-sm block w-full placeholder:text-xs p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:leading-6 rounded-lg"
                placeholder="Enter your email/username"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="email"
              className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
            >
              Password
            </label>
            <div className="mt-2 relative">
              <input
                id="password"
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                autoComplete="current-password"
                required
                className="sm:text-base text-sm block w-full placeholder:text-xs p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300  sm:leading-6 rounded-lg"
                placeholder="Enter your password"
              />
              <button
                type="button"
                className="absolute top-2/4 right-4 -translate-y-1/2"
              >
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5418 13.5418C13.3421 13.7562 13.1012 13.9281 12.8336 14.0474C12.5659 14.1666 12.277 14.2307 11.9841 14.2359C11.6911 14.2411 11.4001 14.1872 11.1284 14.0774C10.8568 13.9677 10.61 13.8044 10.4028 13.5972C10.1956 13.39 10.0323 13.1432 9.92255 12.8716C9.81282 12.5999 9.75893 12.3089 9.7641 12.0159C9.76927 11.723 9.83339 11.4341 9.95264 11.1664C10.0719 10.8988 10.2438 10.6579 10.4582 10.4582M4 4L20 20M16.32 16.32C15.0768 17.2676 13.563 17.7926 12 17.8182C6.90909 17.8182 4 12 4 12C4.90465 10.3141 6.15937 8.84117 7.68 7.68L16.32 16.32ZM10.4727 6.35636C10.9733 6.23918 11.4859 6.18061 12 6.18182C17.0909 6.18182 20 12 20 12C19.5585 12.8259 19.032 13.6034 18.4291 14.32L10.4727 6.35636Z"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between sm:mb-10 mb-6">
              <label htmlFor="" className="flex items-center font-medium">
                <input
                  type="checkbox"
                  className="border-gray-black h-6 w-6 border-2 rounded mr-2"
                />{' '}
                Remember Me
              </label>
              <div className="text-sm">
                <a
                  href="/"
                  className="font-medium sm:text-base text-sm text-indigo-600 hover:text-indigo-500"
                >
                  Forgot password?
                </a>
              </div>
            </div>
          </div>
          <div>
            <button
              // type="submit"
              onClick={formik.handleSubmit as any}
              className="flex w-full justify-center rounded-md bg-amber-300 px-3 p-1.5 font-medium sm:text-base text-sm leading-6 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-300  sm:py-4 py-2"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
      <p className="mt-10 text-center sm:text-base text-sm text-gray-500">
        Don't have an account?
        <Link
          to="/sign-up"
          className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500 underline"
        >
          Create Account
        </Link>
      </p>
    </section>
  );
};

export default LoginForm;
