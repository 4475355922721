import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { axiosPrivate } from '../../api/axios';

// Define the type for the blog prop
type Blog = {
  blogTitle: string;
  blogThumbnail: string;
  blogContent: string;
  // Add any other properties that a blog might have
};

// Define the props type for the BlogDetail component
type BlogDetailProps = {};

const BlogDetail: React.FC<BlogDetailProps> = () => {
  const { blogId } = useParams<{ blogId: string }>();
  const [blog, setBlog] = useState<Blog | null>(null);
  React.useEffect(() => {
    (async () => {
      await axiosPrivate.get(`/content/blogContent/${blogId}`).then((resp) => {
        setBlog(resp.data);
        // console.log(resp.data);
      });
    })();
  },);

  if (!blog) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ background: '#f2f2f2', padding: '20px', borderRadius: '10px', maxWidth: '800px', margin: 'auto' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '10px', fontSize: '28px', fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
        {blog.blogTitle}
      </h2>
      <img
        src={blog.blogThumbnail}
        alt={`Blog Image`}
        style={{ display: 'block', margin: 'auto', maxWidth: '100%', height: '300px', width:'500px', borderRadius: '8px' }}
      />
      <p style={{ fontSize: '18px', lineHeight: '1.6', marginTop: '20px', fontFamily: 'Arial, sans-serif' }}>
        {blog.blogContent}
      </p>
      <div style={{ marginTop: '20px', textAlign: 'right', color: '#777', fontSize: '14px', fontFamily: 'Arial, sans-serif' }}>
        by Admin
      </div>
    </div>
  );
};

export default BlogDetail;
