import React from 'react';
import avatar from '../../assets/top-trainer/avatar.png';
import Swiper from 'react-id-swiper';

const params = {
  containerClass: 'swiper trainerSlider',
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  breakpoints: {
    640: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 2.75,
      spaceBetween: 24,
    },
  },
};

const Trainers = () => {
  const trainers = [
    { image: avatar, name: 'Atma Prakash', role: 'Mentor' },
    { image: avatar, name: 'CA Sangeeta Agarwal Lhila', role: 'Mentor' },
  ];
  return (
    <section className="sm:py-20 py-10 bg-[#110A6A]">
      <div className="relative isolate lg:pr-0 px-6 mx-auto max-w-full lg:px-8">
        <div className="flex flex-wrap items-center mb-10">
          <div className="md:w-5/12 w-full sm:px-6 px-0">
            <div className="lg:w-8/12 w-full mx-auto xl:mr-0 lg:mb-0 mb-8 sm:text-left text-center">
              <h3 className="md:text-3xl text-2xl text-white font-medium mb-4">
                Mentors
              </h3>
              <p className="text-white md:text-base text-sm md:mb-10 mb-6">
                Assessment lays the crucial foundation for self awareness and
                success in any role.
              </p>
              <a
                href="/"
                className="inline-flex items-center border width-auto lg:text-base text-sm text-white rounded-xl border-white px-4 py-2 sm:px-6 sm:py-4"
              >
                View All
                <svg
                  className="ml-2"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 20L20 4M20 4H7.2M20 4V16.8"
                    stroke="#fff"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="lg:w-7/12 w-full sm:pl-6 lg:pr-0 sm:px-4 px-0">
            <Swiper {...params}>
              {trainers.map((trainer, index) => (
                // <div key={index}>
                //   {/* <img
                //       className="w-24 h-24 mb-4 -mb-12  rounded-full object-cover border-2 border-white mx-auto"
                //       src={trainer.image}
                //       alt=""
                //     /> */}
                //   <div className="text-center pt-14 p-6 bg-slate-700 rounded-xl">
                //     <p className="text-white mb-4">
                //       It is a long established fact that a reader will be
                //       distracted by the readable content of a page when looking
                //       at its layout.
                //     </p>
                //     <h4 className="md:text-2xl sm:text-xl text-base mb-1 text-white">
                //       {trainer.name}
                //     </h4>
                //     <h5 className="lg:text-base text-sm text-white">
                //       {trainer.role}
                //     </h5>
                //   </div>
                // </div>
                <div className="swiper-slide">
                  <div className="text-center">
                    {/* <img
                      className="rounded-full w-20 h-20 mx-auto object-cover object-center"
                      src={trainer.image}
                      alt=""
                    /> */}
                    <div className="md:p-5 p-4 rounded text-center mt-6 bg-dark-blue rounded">
                      <p className="text-white text-sm mb-4">
                        In the modern world, skill profiling is essential due to
                        rapid technological advancements and changing job market
                        demands. It helps individuals identify and showcase
                        their unique skill sets, enabling them to stay relevant
                        and competitive in their careers. Additionally, for
                        employers, skill profiling facilitates more accurate
                        talent acquisition, workforce planning, and skill gap
                        analysis, leading to more effective team building and
                        resource allocation. <br /> Third Bracket will give you
                        the best guidance to start & excel in your career to be
                        the best person at your job according to
                        your skills set.
                      </p>
                      <h4 className="text-white text-xl">{trainer.name}</h4>
                      <p className="text-white text-sm">Mentor</p>
                    </div>
                  </div>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Trainers;
