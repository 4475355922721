import React from 'react';
import logo from '../../../assets/tblogo1.png';
import { Link } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
  progress: number;
};
const MainLayoutHeader = ({ children, progress }: Props) => {
  return (
    <>
      <>
        <header className="bg-dark-blue w-full md:py-8 sm:py-6 py-4 z-10 px-4">
          <div className="flex flex-wrap items-center justify-between mx-auto max-w-7xl px-4">
            <div className="md:w-1/5 w-full md:mb-0 mb-2">
              <Link to="/dashboard">
                <span className="sr-only">Your Company</span>
                <img className="lg:h-12 h-8 w-auto" src={logo} alt="" />
              </Link>
            </div>
            {children}
          </div>
        </header>
        {progress > 0 && (
          <div className="w-full bg-gray-200 h-2 dark:bg-gray-700">
            <div
              className="bg-amber-300 h-2"
              style={{ width: `${progress}%` }}
            />
          </div>
        )}
      </>
    </>
  );
};

export default MainLayoutHeader;
