import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { axiosPrivate } from '../../api/axios';
import { secondsToHms } from '../../utils/time';

const MainContent = () => {
  const [assessment, setAssessment] = useState([]);
  const [completedAssessments, setCompletedAssessments] = useState([]);
  const [tab, setTab] = useState('new');
  const user = JSON.parse(localStorage.getItem('user') as string);
  const userId = user?.id || user?._id;
  React.useEffect(() => {
    (async () => {
      await axiosPrivate.get('/assessment').then((resp) => {
        if (user?.isB2COTU) {
          setAssessment(
            resp.data?.filter((a: any) =>
              user?.priviligedAssessments?.includes(a?._id)
            )
          );
        } else {
          setAssessment(resp.data);
        }
      });
    })();
  }, [userId]);

  React.useEffect(() => {
    (async () => {
      await axiosPrivate.get(`/assessment/${userId}/all`).then((resp) => {
        setCompletedAssessments(resp.data);
      });
    })();
  }, [userId]);

  return (
    <section className="dasboard-content lg:w-[calc(100%-15rem)] bg-light-sm min-h-[calc(100vh-104px)] sm:px-6 px-3 sm:py-7 py-4">
      <h3 className="md:text-2xl sm:text-xl text-base text-gray-black font-medium sm:mb-4 mb-2">
        Assessments
      </h3>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 mb-6">
        <ul className="flex flex-wrap -mb-px">
          <li className="md:mr-20 sm:mr-16 mr-12">
            <div
              className={`cursor-pointer inline-block pb-4 border-b-4 font-semibold text-gray-black sm:text-base text-sm active ${
                tab === 'new' ? 'border-amber-300' : ''
              }`}
              aria-current="page"
              onClick={() => setTab('new')}
            >
              New
            </div>
          </li>
          <li className="md:mr-20 sm:mr-16 mr-12">
            <div
              onClick={() => setTab('completed')}
              className={`cursor-pointer inline-block pb-4 border-b-4 font-semibold text-gray-black sm:text-base text-sm active ${
                tab === 'completed' ? 'border-amber-300' : ''
              }`}
            >
              Completed
            </div>
          </li>
        </ul>
      </div>
      {tab === 'new' && (
        <div className="flex flex-wrap sm:-mx-3">
          <div className="grid sm:grid-cols-2 gap-4 grid-cols-1 sm:px-3 px-0">
            {assessment?.map((a: any, idx) => {
              return (
                <div
                  className="bg-white sm:p-6 p-4  rounded-3xl flex flex-wrap items-center mb-4"
                  key={idx}
                >
                  <div className="sm:w-4/12 w-full">
                    <img
                      className="w-full sm:h-60 h-40 sm:mb-0 mb-6 rounded-3xl"
                      src={a?.thumbnail}
                      alt=""
                    />
                  </div>
                  <div className="sm:w-8/12 w-full sm:pl-4 text-center sm:text-left">
                    <h4 className="sm:text-xl  text-base mb-3 font-medium text-gray-black">
                      {a?.title}
                    </h4>
                    {/* <p className="sm:text-base text-sm  text-light-black mb-3">
                    12k people have already participated
                  </p> */}
                    <span className="flex sm:justify-start justify-center items-center sm:mb-12 mb-8">
                      <svg
                        width={24}
                        height={24}
                        className="mr-1.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
                          stroke="#0A1026"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.7099 15.18L12.6099 13.33C12.0699 13.01 11.6299 12.24 11.6299 11.61V7.51001"
                          stroke="#0A1026"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {secondsToHms(a?.duration)}
                    </span>
                    <Link
                      to={`/assessment-overview/${a?._id}`}
                      className="inline-flex items-center border-2 w-full leading-9 sm:text-base text-sm  text-gray-black border-light-gray hover:bg-amber-300 hover:border-yellow-300 transition-all font-medium justify-center rounded-2xl sm:py-3 py-1"
                    >
                      Start Now
                      <svg
                        className="ml-2"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 20L20 4M20 4H7.2M20 4V16.8"
                          stroke="#000"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {tab === 'completed' && (
        <div className="flex flex-wrap sm:-mx-3">
          <div className="grid sm:grid-cols-2 gap-4 grid-cols-1 sm:px-3 px-0">
            {completedAssessments?.map((a: any, idx) => {
              return (
                <div
                  className="bg-white sm:p-6 p-4  rounded-3xl flex flex-wrap items-center mb-4"
                  key={idx}
                >
                  <div className="sm:w-4/12 w-full">
                    <img
                      className="w-full sm:h-60 h-40 sm:mb-0 mb-6 rounded-3xl"
                      src={a?.thumbnail}
                      alt=""
                    />
                  </div>
                  <div className="sm:w-8/12 w-full sm:pl-4 text-center sm:text-left">
                    <h4 className="sm:text-xl  text-base mb-3 font-medium text-gray-black">
                      {a?.title}
                    </h4>

                    <span className="flex sm:justify-start justify-center items-center sm:mb-12 mb-8">
                      <svg
                        width={24}
                        height={24}
                        className="mr-1.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
                          stroke="#0A1026"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.7099 15.18L12.6099 13.33C12.0699 13.01 11.6299 12.24 11.6299 11.61V7.51001"
                          stroke="#0A1026"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {secondsToHms(a?.duration)}
                    </span>
                    <Link
                      to={`/assessments/result?resultId=${a?.resultId}`}
                      className="inline-flex items-center border-2 w-full leading-9 sm:text-base text-sm  text-gray-black border-light-gray hover:bg-amber-300 hover:border-yellow-300 transition-all font-medium justify-center rounded-2xl sm:py-3 py-1"
                    >
                      See Result
                      <svg
                        className="ml-2"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 20L20 4M20 4H7.2M20 4V16.8"
                          stroke="#000"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </section>
  );
};

export default MainContent;
