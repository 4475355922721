import React from "react";
import Header from "../../components/shared/Header";
import SignUpForm from "../../components/SignUp/SignUpForm";
import Footer from "../../components/shared/Footer";

const SignUp = () => {
  return (
    <>
      <Header />
      <SignUpForm />
      <Footer />
    </>
  );
};

export default SignUp;
