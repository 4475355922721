import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-dark-blue">
      <div className="relative isolate px-6 mx-auto max-w-7xl lg:px-8 md:pt-24 md:pb-20 sm:pt-20 sm:pb-16 pt-12 pb-8 md:flex">
        <div className="lg:w-2/5 w-full lg:mb-0 mb-8">
          <h4 className="text-white md:text-3xl sm:text-2xl text-xl font-medium lg:mb-8 sm:mb-6 mb-4">
            Get in Touch
          </h4>
          <ul>
            <li className="md:mb-4 sm:mb-3 mb-2">
              <a
                href="mailto:sales@learningbooth.co"
                className="text-light-white lg:text-2xl sm:text-xl text-base"
              >
                sales@learningbooth.co
              </a>
            </li>
            <li>
              <a
                href="callto:+919147064666"
                className="text-light-white lg:text-2xl sm:text-xl text-base"
              >
                +91 9147064666
              </a>
            </li>
          </ul>
        </div>
        <div className="lg:w-3/12 w-full lg:mb-0 mb-8">
          <h4 className="text-white md:text-3xl sm:text-2xl text-xl font-medium lg:mb-8 sm:mb-6 mb-4">
            Menu
          </h4>
          <div>
            <ul>
              <li className="md:pb-6 sm:pb-4 pb-2">
                <a href="/" className="sm:text-lg text-sm text-white">
                  Assessment
                </a>
              </li>
              <li className="md:pb-6 sm:pb-4 pb-2">
                <a href="/" className="sm:text-lg text-sm text-white">
                  Courses
                </a>
              </li>
              <li className="">
                <a href="/" className="sm:text-lg text-sm text-white">
                  Jobs
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="lg:w-2/5 w-full">
          <h4 className="text-white md:text-3xl sm:text-2xl text-xl font-medium lg:mb-8 sm:mb-6 mb-4">
            Newsletter- Sign up
          </h4>
          <p className="lg:text-base text-sm text-light-white mb-16">
            If you would like to know all the cutting edge news happening in the
            world of Skill Profiling sign up here.
          </p>
          <form action="">
            <div className="relative border-b-2 border-white">
              <input
                type="text"
                className="w-full bg-transparent border-0 h-16 lg:text-base text-sm text-light-white"
                placeholder="Enter your mail...."
              />
              <button className="inline-flex absolute top-2/4 -translate-y-2/4 right-2 items-center justify-center w-18 h-18 rounded-full sm:w-12 sm:h-12 bg-amber-300 dark:bg-gray-800/30 group-hover:bg-amber-300 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                <svg
                  width={29}
                  height={14}
                  viewBox="0 0 29 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.00031 7H27.0003M27.0003 7L20.9599 1M27.0003 7L20.9599 13"
                    stroke="#0A1026"
                    strokeWidth="1.6732"
                    strokeLinecap="round"
                  />
                </svg>
                <span className="sr-only">Next</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="relative isolate px-6 mx-auto max-w-7xl lg:px-8 pb-9 md:flex">
        <div className="md:w-6/12 w-full">
          <p className="text-white lg:text-base text-sm font-medium lg:mb-0 mb-4">
            2023 Copyright, Learning Booth PVT LTD. Venture
          </p>
        </div>
        <div className="md:w-6/12 w-full lg:text-right">
          <a href="/" className="text-white lg:text-base text-sm font-medium">
            Terms &amp; Condition
          </a>
          <a
            href="/"
            className="text-white lg:text-base text-sm font-medium md:pl-20 sm:pl-10 pl-6"
          >
            Privacy &amp; Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
