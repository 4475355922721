import React, { useState, useEffect } from "react";
import t_a from "../../assets/testimonial_avatar.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";

SwiperCore.use([Navigation, Pagination]);

type Testimonial = {
  _id: string;
  testimonialName: string;
  testimonialContent: string;
};

const SuccessStory = () => {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://learningboothserver-production.up.railway.app/content/testimonials",
          {
            headers: {
              "Cache-Control": "no-cache",
            },
          }
        );
        const data = await response.json();
        // console.log("test data", data);
        setTestimonials(data.slice(0, 3).reverse()); // Assuming the response is an array of testimonials
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <section className="sm:py-20 py-10 bg-[#AEEEEE]">
      <div className="relative isolate px-6 mx-auto max-w-7xl lg:px-8">
        <h3 className="text-center lg:text-4xl sm:text-3xl text-2xl sm:mb-10 mb-6 font-semibold">
          Testimonials
        </h3>
        <Swiper
          className="testimonialSlider"
          spaceBetween={10}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="lg:pt-28 lg:pb-32 md:pt-24 md:pb-28 sm:pt-20 sm:pb-24 pt-8 pb-12 lg:px-24 md:px-20 sm:px-16 px-6 text-center bg-white rounded-2xl w-10/12 mx-auto">
                <h4 className="lg:text-3xl md:text-2xl sm:text-xl text-base font-semibold sm:mb-6 mb-4">
                  {testimonial.testimonialName}
                </h4>
                <p style={{ fontSize: "20px" }}>
                  {testimonial.testimonialContent}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default SuccessStory;
