import React, { useState } from 'react';
import as_1 from '../../assets/assessment/1.svg';
import as_2 from '../../assets/assessment/2.svg';
import icon from '../../assets/product-icon.png';
import { axiosPrivate } from '../../api/axios';
import { secondsToHms } from '../../utils/time';
import { Link } from 'react-router-dom';
// import as_3 from "../../assets/assessment/3.svg";
// import as_4 from "../../assets/assessment/4.svg";

const Assessment = () => {
  const staticParticipantCounts = [80, 45, 92, 60, 75];
  const [assessment, setAssessment] = useState([]);
  React.useEffect(() => {
    (async () => {
      await axiosPrivate.get('/assessment').then((resp) => {
        setAssessment(resp.data);
        console.log(resp.data);
      });
    })();
  }, []);
  return (
    // <section className="sm:sm:py-20 py-10 bg-light-gray">
    //   <div className="relative isolate mx-auto max-w-7xl px-4">
    //     <div className="flex flex-wrap lg:-mx-6 md:mb-10 mb-6">
    //       <div className="md:w-2/4 w-full px-6 md:text-left text-center md:mb-0 mb-6">
    //         <h3 className="md:text-3xl text-2xl text-gray-black font-medium mb-4">
    //           Skill Profiling
    //         </h3>
    //         <p className="text-light-black md:text-base text-sm">
    //           Skill Profiling is a crucial phase in any website project, as it
    //           lays the foundation for success.
    //         </p>
    //       </div>
    //       <div className="md:w-2/4 w-full px-6 md:text-right text-center">
    //         <a
    //           href="/login"
    //           className="inline-flex items-center border width-auto lg:text-base text-sm text-gray-black rounded-xl border-gray-black px-4 py-2 sm:px-6 sm:py-4"
    //         >
    //           View All
    //           <svg
    //             className="ml-2"
    //             width={24}
    //             height={24}
    //             viewBox="0 0 24 24"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M4 20L20 4M20 4H7.2M20 4V16.8"
    //               stroke="#0A1026"
    //               strokeWidth={2}
    //               strokeLinecap="round"
    //             />
    //           </svg>
    //         </a>
    //       </div>
    //     </div>
    //     <div className="flex justify-between">
    //       <div className="text-center mb-6 mx-auto inline-block bg-white md:p-4 p-2 rounded-full box-shadow-light">
    //         <button
    //           type="button"
    //           className="md:py-3 py-2 md:px-6 px-4 md:text-base text-sm bg-dark-blue text-white rounded-full active"
    //         >
    //           Business Domain
    //         </button>
    //         <button
    //           type="button"
    //           className="md:py-3 py-2 md:px-6 px-4 md:text-base text-sm rounded-full"
    //         >
    //           Technology Domain
    //         </button>
    //       </div>
    //     </div>
    //     <div className="flex flex-wrap sm:-mx-5">
    //       <div className="grid sm:grid-cols-2 gap-4 grid-cols-1 sm:px-5 px-0">
    //         {assessment?.map((a: any, idx) => {
    //           return (
    //             <div
    //               className="bg-white sm:p-6 p-4  rounded-3xl flex flex-wrap items-center mb-4"
    //               key={idx}
    //             >
    //               <div className="sm:w-4/12 w-full">
    //                 <img
    //                   className="w-full sm:h-60 h-40 sm:mb-0 mb-6 rounded-3xl"
    //                   src={a?.thumbnail}
    //                   alt=""
    //                 />
    //               </div>
    //               <div className="sm:w-8/12 w-full sm:pl-4 text-center sm:text-left">
    //                 <h4 className="sm:text-xl  text-base mb-3 font-medium text-gray-black">
    //                   {a?.title}
    //                 </h4>
    //                 {/* <p className="sm:text-base text-sm  text-light-black mb-3">
    //                 12k people have already participated
    //               </p> */}
    //                 <span className="flex sm:justify-start justify-center items-center sm:mb-12 mb-8">
    //                   <svg
    //                     width={24}
    //                     height={24}
    //                     className="mr-1.5"
    //                     viewBox="0 0 24 24"
    //                     fill="none"
    //                     xmlns="http://www.w3.org/2000/svg"
    //                   >
    //                     <path
    //                       d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
    //                       stroke="#0A1026"
    //                       strokeWidth="1.5"
    //                       strokeLinecap="round"
    //                       strokeLinejoin="round"
    //                     />
    //                     <path
    //                       d="M15.7099 15.18L12.6099 13.33C12.0699 13.01 11.6299 12.24 11.6299 11.61V7.51001"
    //                       stroke="#0A1026"
    //                       strokeWidth="1.5"
    //                       strokeLinecap="round"
    //                       strokeLinejoin="round"
    //                     />
    //                   </svg>
    //                   {secondsToHms(a?.duration)}
    //                 </span>
    //                 <Link
    //                   to={`/login`}
    //                   className="inline-flex items-center border-2 w-full leading-9 sm:text-base text-sm  text-gray-black border-light-gray hover:bg-amber-300 hover:border-yellow-300 transition-all font-medium justify-center rounded-2xl sm:py-3 py-1"
    //                 >
    //                   Start Now
    //                   <svg
    //                     className="ml-2"
    //                     width={24}
    //                     height={24}
    //                     viewBox="0 0 24 24"
    //                     fill="none"
    //                     xmlns="http://www.w3.org/2000/svg"
    //                   >
    //                     <path
    //                       d="M4 20L20 4M20 4H7.2M20 4V16.8"
    //                       stroke="#000"
    //                       strokeWidth={2}
    //                       strokeLinecap="round"
    //                     />
    //                   </svg>
    //                 </Link>
    //               </div>
    //             </div>
    //           );
    //         })}
    //       </div>
    //       {/* <div className="lg:w-2/4 w-full sm:px-5 px-0">
    //         <div className="bg-white sm:p-6 p-4 rounded-3xl flex flex-wrap sm:mb-10 mb-8">
    //           <div className="sm:w-5/12 w-full">
    //             <img
    //               className="w-full sm:h-60 h-40 sm:mb-0 mb-6 rounded-3xl"
    //               src={as_2}
    //               alt=""
    //             />
    //           </div>
    //           <div className="sm:w-7/12 w-full sm:pl-4 text-center sm:text-left">
    //             <h4 className="lg:text-2xl text-xl mb-3 font-medium text-gray-black">
    //               Behavioral Assessments
    //             </h4>
    //             <p className="lg:text-base text-sm text-light-black mb-3">
    //               12k people have already participated
    //             </p>
    //             <span className="flex sm:justify-start justify-center items-center sm:mb-12 mb-8">
    //               <svg
    //                 width={24}
    //                 height={24}
    //                 className="mr-1.5"
    //                 viewBox="0 0 24 24"
    //                 fill="none"
    //                 xmlns="http://www.w3.org/2000/svg"
    //               >
    //                 <path
    //                   d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
    //                   stroke="#0A1026"
    //                   strokeWidth="1.5"
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                 />
    //                 <path
    //                   d="M15.7099 15.18L12.6099 13.33C12.0699 13.01 11.6299 12.24 11.6299 11.61V7.51001"
    //                   stroke="#0A1026"
    //                   strokeWidth="1.5"
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                 />
    //               </svg>
    //               50 mins
    //             </span>
    //             <a
    //               href="/login"
    //               className="inline-flex items-center border-b w-full border-yellow-300 leading-9 lg:text-base text-sm text-gray-black bg-amber-300 justify-center rounded-2xl sm:py-3 py-1"
    //             >
    //               Start Now
    //               <svg
    //                 className="ml-2"
    //                 width={24}
    //                 height={24}
    //                 viewBox="0 0 24 24"
    //                 fill="none"
    //                 xmlns="http://www.w3.org/2000/svg"
    //               >
    //                 <path
    //                   d="M4 20L20 4M20 4H7.2M20 4V16.8"
    //                   stroke="#000"
    //                   strokeWidth={2}
    //                   strokeLinecap="round"
    //                 />
    //               </svg>
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="lg:w-2/4 w-full sm:px-5 px-0">
    //         <div className="bg-white sm:p-6 p-4 rounded-3xl flex flex-wrap sm:mb-10 mb-8">
    //           <div className="sm:w-5/12 w-full">
    //             <img
    //               className="w-full sm:h-60 h-40 sm:mb-0 mb-6 rounded-3xl"
    //               src={as_3}
    //               alt=""
    //             />
    //           </div>
    //           <div className="sm:w-7/12 w-full sm:pl-4 text-center sm:text-left">
    //             <h4 className="lg:text-2xl text-xl mb-3 font-medium text-gray-black">
    //               Coding Assessments
    //             </h4>
    //             <p className="lg:text-base text-sm text-light-black mb-3">
    //               12k people have already participated
    //             </p>
    //             <span className="flex sm:justify-start justify-center items-center sm:mb-12 mb-8">
    //               <svg
    //                 width={24}
    //                 height={24}
    //                 className="mr-1.5"
    //                 viewBox="0 0 24 24"
    //                 fill="none"
    //                 xmlns="http://www.w3.org/2000/svg"
    //               >
    //                 <path
    //                   d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
    //                   stroke="#0A1026"
    //                   strokeWidth="1.5"
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                 />
    //                 <path
    //                   d="M15.7099 15.18L12.6099 13.33C12.0699 13.01 11.6299 12.24 11.6299 11.61V7.51001"
    //                   stroke="#0A1026"
    //                   strokeWidth="1.5"
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                 />
    //               </svg>
    //               50 mins
    //             </span>
    //             <a
    //               href="/login"
    //               className="inline-flex items-center border-b w-full border-yellow-300 leading-9 lg:text-base text-sm text-gray-black bg-amber-300 justify-center rounded-2xl sm:py-3 py-1"
    //             >
    //               Start Now
    //               <svg
    //                 className="ml-2"
    //                 width={24}
    //                 height={24}
    //                 viewBox="0 0 24 24"
    //                 fill="none"
    //                 xmlns="http://www.w3.org/2000/svg"
    //               >
    //                 <path
    //                   d="M4 20L20 4M20 4H7.2M20 4V16.8"
    //                   stroke="#000"
    //                   strokeWidth={2}
    //                   strokeLinecap="round"
    //                 />
    //               </svg>
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="lg:w-2/4 w-full sm:px-5 px-0">
    //         <div className="bg-white sm:p-6 p-4 rounded-3xl flex flex-wrap sm:mb-10 mb-8">
    //           <div className="sm:w-5/12 w-full">
    //             <img
    //               className="w-full sm:h-60 h-40 sm:mb-0 mb-6 rounded-3xl"
    //               src={as_4}
    //               alt=""
    //             />
    //           </div>
    //           <div className="sm:w-7/12 w-full sm:pl-4 text-center sm:text-left">
    //             <h4 className="lg:text-2xl text-xl mb-3 font-medium text-gray-black">
    //               Gamified Assessments
    //             </h4>
    //             <p className="lg:text-base text-sm text-light-black mb-3">
    //               12k people have already participated
    //             </p>
    //             <span className="flex sm:justify-start justify-center items-center sm:mb-12 mb-8">
    //               <svg
    //                 width={24}
    //                 height={24}
    //                 className="mr-1.5"
    //                 viewBox="0 0 24 24"
    //                 fill="none"
    //                 xmlns="http://www.w3.org/2000/svg"
    //               >
    //                 <path
    //                   d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
    //                   stroke="#0A1026"
    //                   strokeWidth="1.5"
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                 />
    //                 <path
    //                   d="M15.7099 15.18L12.6099 13.33C12.0699 13.01 11.6299 12.24 11.6299 11.61V7.51001"
    //                   stroke="#0A1026"
    //                   strokeWidth="1.5"
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                 />
    //               </svg>
    //               50 mins
    //             </span>
    //             <a
    //               href="/login"
    //               className="inline-flex items-center border-b w-full border-yellow-300 leading-9 lg:text-base text-sm text-gray-black bg-amber-300 justify-center rounded-2xl sm:py-3 py-1"
    //             >
    //               Start Now
    //               <svg
    //                 className="ml-2"
    //                 width={24}
    //                 height={24}
    //                 viewBox="0 0 24 24"
    //                 fill="none"
    //                 xmlns="http://www.w3.org/2000/svg"
    //               >
    //                 <path
    //                   d="M4 20L20 4M20 4H7.2M20 4V16.8"
    //                   stroke="#000"
    //                   strokeWidth={2}
    //                   strokeLinecap="round"
    //                 />
    //               </svg>
    //             </a>
    //           </div>
    //         </div>
    //       </div> */}
    //     </div>
    //   </div>
    // </section>
    <section className="sm:sm:py-20 py-10 py-10 bg-[#110A6A]">
      <div className="relative isolate px-6 mx-auto max-w-7xl lg:px-8">
        <div className="flex flex-wrap mb-10">
          <div className="w-full px-6 text-center md:mb-0 mb-6">
            <h3 className="md:text-3xl text-2xl text-white font-medium mb-4">
              Product Offering
            </h3>
            {/* <p className="text-light-black md:text-base text-sm">
              The demand for skill-based jobs is higher in today's world. In
              these modern times, Third Bracket will guide you to your dream
              company, tailored to your skillsets.
            </p> */}
            <div className="flex flex-wrap -mx-3 mt-8">
              <div className="lg:w-1/4 md:w-2/4 sm:w-3/4 w-full px-3">
                <div className="p-4 rounded-lg bg-[#AEEEEE] flex align-center text-left text-[#484848] font-medium mb-5 min-h-[calc(100%-20px)]"><img className="md:h-8 h-6 mr-4" src={icon} alt="" /> Comprehensive Assessments</div>
              </div>
              <div className="lg:w-1/4 md:w-2/4 sm:w-3/4 w-full px-3">
                <div className="p-4 rounded-lg bg-[#AEEEEE] flex align-center text-left text-[#484848] font-medium mb-5 min-h-[calc(100%-20px)]"><img className="md:h-8 h-6 mr-4" src={icon} alt="" /> Dynamic Skills Profiling</div>
              </div>
              <div className="lg:w-1/4 md:w-2/4 sm:w-3/4 w-full px-3">
                <div className="p-4 rounded-lg bg-[#AEEEEE] flex align-center text-left text-[#484848] font-medium mb-5 min-h-[calc(100%-20px)]"><img className="md:h-8 h-6 mr-4" src={icon} alt="" /> Tailored Self Configurable Assessments</div>
              </div>
              <div className="lg:w-1/4 md:w-2/4 sm:w-3/4 w-full px-3">
                <div className="p-4 rounded-lg bg-[#AEEEEE] flex align-center text-left text-[#484848] font-medium mb-5 min-h-[calc(100%-20px)]"><img className="md:h-8 h-6 mr-4" src={icon} alt="" /> Robust Enterprise Grade SaaS Platform</div>
              </div>
              <div className="lg:w-1/4 md:w-2/4 sm:w-3/4 w-full px-3">
                <div className="p-4 rounded-lg bg-[#AEEEEE] flex align-center text-left text-[#484848] font-medium mb-5 min-h-[calc(100%-20px)]"><img className="md:h-8 h-6 mr-4" src={icon} alt="" /> Advanced Proctoring Solutions</div>
              </div>
              <div className="lg:w-1/4 md:w-2/4 sm:w-3/4 w-full px-3">
                <div className="p-4 rounded-lg bg-[#AEEEEE] flex align-center text-left text-[#484848] font-medium mb-5 min-h-[calc(100%-20px)]"><img className="md:h-8 h-6 mr-4" src={icon} alt="" /> In-Depth Insights</div>
              </div>
              <div className="lg:w-1/4 md:w-2/4 sm:w-3/4 w-full px-3">
                <div className="p-4 rounded-lg bg-[#AEEEEE] flex align-center text-left text-[#484848] font-medium mb-5 min-h-[calc(100%-20px)]"><img className="md:h-8 h-6 mr-4" src={icon} alt="" /> Intelligent Reporting</div>
              </div>
              <div className="lg:w-1/4 md:w-2/4 sm:w-3/4 w-full px-3">
                <div className="p-4 rounded-lg bg-[#AEEEEE] flex align-center text-left text-[#484848] font-medium mb-5 min-h-[calc(100%-20px)]"><img className="md:h-8 h-6 mr-4" src={icon} alt="" /> Expert Professional Support</div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-sm mx-6 font-medium text-center text-white border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 mb-6">
          <ul className="flex flex-wrap -mb-px">
            <li className="mr-20">
              <div
                className="inline-block pb-4 border-b-4 font-semibold text-white text-base border-amber-300 active"
                aria-current="page"
              >
                Business Domain
              </div>
            </li>
            <li className="mr-20">
              <div className="inline-block pb-4 border-b-4 border-transparent text-base">
                Technology Domain
              </div>
            </li>
          </ul>
        </div>
        <div className="flex flex-wrap">
          {assessment.map((a: any, idx) => (
            <div
              key={idx}
              className="lg:w-3/12 md:w-4/12 sm:w-6/12 w-full sm:px-4 px-0"
            >
              <div className="bg-white sm:p-3 p-4 rounded-3xl flex flex-wrap sm:mb-10 mb-8">
                <div className="w-full">
                  <img
                    className="w-full sm:h-40 h-32 sm:mb-0 mb-6 object-cover rounded-3xl"
                    src={a?.thumbnail}
                    alt=""
                  />
                </div>
                <div className="w-full pt-3 text-center sm:text-left">
                  <h4 className="lg:text-xl text-lg mb-3 min-h-[58px] font-medium text-gray-black">
                    {a?.title}
                  </h4>
                  <p className="lg:text-base text-sm text-light-black mb-3">
                    {staticParticipantCounts[idx]} people have already
                    participated
                  </p>
                  <span className="flex sm:justify-start justify-center items-center sm:mb-12 mb-8">
                    <svg
                      width={24}
                      height={24}
                      className="mr-1.5"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
                        stroke="#0A1026"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.7099 15.18L12.6099 13.33C12.0699 13.01 11.6299 12.24 11.6299 11.61V7.51001"
                        stroke="#0A1026"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {secondsToHms(a?.duration)}
                  </span>
                  <a
                    href="/login"
                    className="inline-flex items-center border-b w-full border-yellow-300 leading-9 lg:text-base text-sm text-gray-black bg-amber-300 justify-center rounded-2xl sm:py-3 py-1"
                  >
                    Start Now
                    <svg
                      className="ml-2"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 20L20 4M20 4H7.2M20 4V16.8"
                        stroke="#000"
                        strokeWidth={2}
                        strokeLinecap="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full px-6 text-center">
            <a
              href="/login"
              className="inline-flex items-center border border-white width-auto lg:text-base text-sm text-white rounded-xl px-4 py-2 sm:px-6 sm:py-4"
            >
              View All
              <svg
                className="ml-2"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 20L20 4M20 4H7.2M20 4V16.8"
                  stroke="#fff"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
            </a>
          </div>
      </div>
    </section>
  );
};

export default Assessment;
