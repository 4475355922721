import React, { useEffect, useState } from 'react';
import c_1 from '../../assets/courses/One.jpeg';
import c_2 from '../../assets/courses/Two.jpeg';
import c_3 from '../../assets/courses/Three.jpeg';
import c_4 from '../../assets/courses/Four.jpeg';
import profile from '../../assets/user-proflie-1.png';
import { Link } from 'react-router-dom';
import BlogDetail from './BlogDetail';


const Blogs = () => {
  const [blogImages, setBlogImages] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);

  useEffect(() => {
    const fetchBlogImages = async () => {
      try {
        const response = await fetch(`https://learningboothserver-production.up.railway.app/content/blogContent`, {
          headers: {
            'Cache-Control': 'no-cache',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch blog images');
        }
        const data = await response.json();
        // console.log('blogs', data);
        setBlogImages(data.slice(0, 3).reverse());
      } catch (error) {
        console.error('Error fetching blog images:', error);
      }
    };

    fetchBlogImages();
  }, []);

  useEffect(() => {
    // console.log('selected blog', selectedBlog);
  }, [selectedBlog]);

  const handleBlogClick = (blog) => {
    setSelectedBlog(blog);
    // console.log('selected blog',selectedBlog);
  };

  const trimTitle = (title, maxLength) => {
    return title.length > maxLength ? title.slice(0, maxLength) + '...' : title;
  };

  return (
    <section className="sm:py-20 py-10 bg-[#F3DA38]">
      <div className="relative isolate px-6 mx-auto max-w-7xl lg:px-8">
        <div className="flex flex-wrap mb-10">
          <div className="w-full px-6 text-center md:mb-0 mb-6">
            <h3 className="md:text-3xl text-2xl text-gray-black font-medium mb-4">
              Our Blogs
            </h3>
          </div>
        </div>
        <div className="flex flex-wrap">
          {blogImages.map((image, index) => (
            <div key={index} className="lg:w-4/12 w-full lg:px-5 sm:mb-8 mb-6">
              <Link to={`/blogs/${image._id}`}> 
              <div className="bg-white sm:p-6 p-4 rounded-3xl box-shadow-light" onClick={() => handleBlogClick(image)}>
                <div className="mb-4">
                  <img
                    className="w-full rounded-3xl h-60 object-cover object-center"
                    src={image.blogThumbnail}
                    alt={`Blog Image ${index + 1}`}
                  />
                </div>
                <div>
                  <div className="flex items-center">
                    {/* <div className="mr-2">
                      <img src={profile} alt="" />
                    </div> */}
                    <h4 className="lg:text-base text-sm">Admin</h4>
                  </div>
                  <h3 className="lg:text-xl sm:text-lg text-base mt-4 font-medium">
                  {trimTitle(image.blogTitle, 50)}
                  </h3>
                </div>
              </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      {selectedBlog && <BlogDetail blog={selectedBlog} />}
    </section>
  );
};

export default Blogs;
