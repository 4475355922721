import React from "react";
import MainLayout from "../../layout/MainLayout";
import MainContent from "../../components/StartLearning/MainContent";
import { Link } from "react-router-dom";

const StartLearning = () => {
  return (
    <MainLayout
      headerProgress={50}
      headerContent={
        <div className="">
          <Link
            to="/courses"
            className="bg-white-transparent text-white text-base py-3.5 px-6 rounded-lg text-center inline-block font-medium"
          >
            Close
          </Link>
        </div>
      }
    >
      <MainContent />
    </MainLayout>
  );
};

export default StartLearning;
