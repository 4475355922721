import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logoBlack from '../../assets/tblogo1.png';
import userProfile from '../../assets/user-profile.png';

const ApplyJobHeader = () => {
  const [menu, setMenu] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  return (
    <>
      <div className="flex lg:hidden">
        <button
          type="button"
          onClick={() => setMenu(!menu)}
          className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#fff"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
      </div>
      <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:gap-x-12">
        <a
          href="/"
          className="text-lg font-semibold leading-6 text-white hover:text-amber-300 border-b-0 hover:border-b hover:border-yellow-300"
        >
          Solution
        </a>
        <div className="relative drop-down">
          <button
            type="button"
            className="product flex items-center gap-x-1 text-lg font-semibold leading-6 text-white"
            aria-expanded="false"
          >
            Product
          </button>
          <div className="absolute -left-8 top-full drop-down-menu z-10 mt-3 w-screen rounded-2xl max-w-lg bg-black-transparent backdrop-blur-2xl shadow-lg ring-1 ring-gray-900/5">
            <div className="p-6 flex">
              <div className="pr-10 border-e w-7/12">
                <ul>
                  <li className="pb-4">
                    <a href="/" className="lg:text-base text-sm  text-white">
                      Ability and Skills Assessments
                    </a>
                  </li>
                  <li className="pb-4">
                    <a href="/" className="lg:text-base text-sm  text-white">
                      Behavioral Assessments
                    </a>
                  </li>
                  <li className="pb-4">
                    <a href="/" className="lg:text-base text-sm  text-white">
                      Coding Assessments
                    </a>
                  </li>
                  <li className="pb-4">
                    <a href="/" className="lg:text-base text-sm  text-white">
                      Gamified Assessments
                    </a>
                  </li>
                </ul>
              </div>
              <div className="pl-10 w-5/12">
                <ul>
                  <li className="pb-4">
                    <a href="/" className="lg:text-base text-sm  text-white">
                      Jobs
                    </a>
                  </li>
                  <li className="pb-4">
                    <a href="/" className="lg:text-base text-sm  text-white">
                      Skill base courses
                    </a>
                  </li>
                  <li>
                    <a href="/" className="lg:text-base text-sm  text-white">
                      Trainer &amp; mentorship
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <a
          href="/"
          className="text-lg font-semibold leading-6 text-white hover:text-amber-300 border-b-0 hover:border-b hover:border-yellow-300"
        >
          Resources
        </a>
      </div>
      <div className="hidden lg:flex ml-32 lg:items-center lg:justify-end">
        <div className="flex justify-end items-center">
          <div className="relative sm:pr-6 pr-4 sm:mr-6 mr-4 border-r-2">
            <span className="absolute -top-1 left-2 h-4 w-4 rounded-full bg-rose-500 text-center leading-4 text-white text-xs">
              1
            </span>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0189 2C8.33892 2 5.35892 4.98 5.35892 8.66V10.76C5.35892 11.44 5.07892 12.46 4.72892 13.04L3.45892 15.16C2.67892 16.47 3.21892 17.93 4.65892 18.41C9.43892 20 14.6089 20 19.3889 18.41C20.7389 17.96 21.3189 16.38 20.5889 15.16L19.3189 13.04C18.9689 12.46 18.6889 11.43 18.6889 10.76V8.66C18.6789 5 15.6789 2 12.0189 2Z"
                stroke="#fff"
                strokeWidth="1.5"
                strokeMiterlimit={10}
                strokeLinecap="round"
              />
              <path
                d="M15.3318 18.8203C15.3318 20.6503 13.8318 22.1503 12.0018 22.1503C11.0918 22.1503 10.2518 21.7703 9.65187 21.1703C9.05187 20.5703 8.67188 19.7303 8.67188 18.8203"
                stroke="#fff"
                strokeWidth="1.5"
                strokeMiterlimit={10}
              />
              <path
                d="M12 6.43945V9.76945"
                stroke="#fff"
                strokeWidth="1.5"
                strokeMiterlimit={10}
                strokeLinecap="round"
              />
            </svg>
          </div>
          <div className="flex items-center">
            <div>
              <img
                className="sm:h-12 sm:w-12 h-8 w-8 rounded-full object-cover mr-3"
                src={userProfile}
                alt=""
              />
            </div>
            <h4 className="sm:text-base text-sm font-semibold text-white">
              Jenny W.
            </h4>
          </div>
        </div>
      </div>
      {menu && (
        <>
          {/* Mobile menu, show/hide based on menu open state. */}
          <div
            id="sidemenu"
            className="lg:hidden"
            role="dialog"
            aria-modal="true"
          >
            {/* Background backdrop, show/hide based on slide-over state. */}
            <div className="fixed inset-0 z-10" />
            <div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-4 py-4 sm:px-6 sm:py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <Link to="/" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img className="h-8 w-auto" src={logoBlack} alt="" />
                </Link>
                <button
                  onClick={() => setMenu(!menu)}
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                >
                  <span className="sr-only">Close menu</span>
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    <a
                      href="/"
                      className="-mx-3 block rounded-lg px-3 py-2 lg:text-base text-sm  font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Solution
                    </a>
                    <div className="-mx-3">
                      <button
                        onMouseEnter={() => setDropdown(true)}
                        onMouseLeave={() => setDropdown(false)}
                        type="button"
                        className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 lg:text-base text-sm  font-semibold leading-7 hover:bg-gray-50"
                        aria-controls="disclosure-1"
                        aria-expanded="false"
                      >
                        Product
                        <svg
                          className="h-5 w-5 flex-none"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      {/* 'Product' sub-menu, show/hide based on menu state. */}
                      {dropdown && (
                        <div className="mt-2 space-y-2" id="dropdownMenu">
                          <a
                            href="/"
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            Ability and Skills Assessments
                          </a>
                          <a
                            href="/"
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            Behavioral Assessments
                          </a>
                          <a
                            href="/"
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            Coding Assessments
                          </a>
                          <a
                            href="/"
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            Gamified Assessments
                          </a>
                          <a
                            href="/"
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            Jobs
                          </a>
                          <a
                            href="/"
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            Skill base courses
                          </a>
                          <a
                            href="/"
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            Trainer &amp; mentorship
                          </a>
                        </div>
                      )}
                    </div>
                    <a
                      href="/"
                      className="-mx-3 block rounded-lg px-3 py-2 lg:text-base text-sm  font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Resources
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ApplyJobHeader;
