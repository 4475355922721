import React from 'react';
import google from '../../../../assets/google-logo.svg';
import JobModal from '../../../Jobs/JobModal';
const JobsRecommendations = ({ jobs }: any) => {
  const [job, setJob] = React.useState<any>(null);
  const viewDetails = (j: any) => {
    var jobDetails: any = document.getElementById('jodDetails');
    var jobDetailsOverlay: any = document.getElementById('jodDetailsOverlay');
    jobDetails.classList.toggle('active');
    jobDetailsOverlay.classList.toggle('active');
    setJob(j);
  };

  return (
    <section className="sm:py-20 py-10 bg-light-gray">
      <div className="relative isolate px-4 mx-auto max-w-7xl">
        <div className="flex flex-wrap -mx-6 md:mb-10 mb-6">
          <div className="md:w-2/4 w-full px-6 md:text-left text-center md:mb-0 mb-6">
            <h3 className="md:text-3xl text-2xl text-gray-black font-medium mb-4">
              Jobs
            </h3>
            <p className="text-light-black md:text-base text-sm">
              Assessment lays the crucial foundation for self awareness and
              success in any role.
            </p>
          </div>
          <div className="md:w-2/4 w-full px-6 md:text-right text-center">
            <a
              href="/login"
              className="inline-flex items-center border width-auto lg:text-base text-sm text-gray-black rounded-xl border-gray-black px-4 py-2 sm:px-6 sm:py-4"
            >
              View All
              <svg
                className="ml-2"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 20L20 4M20 4H7.2M20 4V16.8"
                  stroke="#0A1026"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
            </a>
          </div>
        </div>
        {jobs?.map((job: any, idx: number) => {
          return (
            <div onClick={() => viewDetails(job)} key={idx}>
              <div className="md:p-10 sm:p-6 p-4 bg-white rounded-2xl md:mb-6 sm:mb-4 mb-3 border-2 border-white hover:border-yellow-300 transition-all">
                <h3 className="lg:text-2xl sm:text-xl text-base mb-2 font-medium">
                  {job.title}
                </h3>
                <h4 className="flex items-center text-dark-black lg:text-base text-sm mb-2 font-bold">
                  {/* <img
                    width={24}
                    height={24}
                    className="mr-2"
                    src={google}
                    alt=""
                  /> */}
                  Company: {job.companyName}
                  {/* <span className="flex items-center ml-4 text-black">
                        {job.rating}{" "}
                        <img className="ml-1" src={grayStart} alt="" />
                      </span> */}
                </h4>
                <div className="flex mb-2">
                  <div className="flex items-center mr-6 text-dark-black sm:text-base text-sm">
                    <span className="w-2 h-2 rounded bg-dark-black inline-block mr-2" />
                    {job.jobType}
                  </div>
                  <div className="flex items-center">
                    {/* <img
                          className="mr-2"
                          width={24}
                          height={24}
                          src={clock}
                          alt=""
                        /> */}
                    {/* <p className="text-dark-black sm:text-base text-sm">
                          {job.hiringStatus}
                        </p> */}
                  </div>
                </div>
                {/* <span className="py-2 px-3 rounded-lg text-light-green bg-light-green lg:text-base text-sm font-medium mb-2 inline-block">
                  You’re fit for this job
                </span> */}
                <p className="text-dark-black lg:text-base text-sm">
                  {job.jobDetails?.slice(0, 200)}...
                </p>
              </div>
            </div>
          );
        })}

        {/* <Link
          to="/login"
          className="block sm:p-10 p-6 bg-white rounded-2xl mb-6 border-2 border-white hover:border-yellow-300 transition-all"
        >
          <h3 className="lg:text-2xl sm:text-xl text-base mb-2 font-medium">
            UX UI Designer
          </h3>
          <h4 className="flex items-center text-dark-black lg:text-base text-sm mb-2">
            <img width={24} height={24} className="mr-2" src={google} alt="" />
            Company Name
            <span className="flex items-center ml-4 text-black">
              2.5 <img className="ml-1" src={star} alt="" />
            </span>
          </h4>
          <div className="flex mb-2">
            <div className="flex items-center mr-6 text-dark-black sm:text-base text-sm">
              <span className="w-2 h-2 rounded bg-dark-black inline-block mr-2" />
              Remote
            </div>
            <div className="flex items-center">
              <img className="mr-2" width={24} height={24} src={clock} alt="" />
              <p className="text-dark-black sm:text-base text-sm">
                Urgently hiring
              </p>
            </div>
          </div>
          <span className="py-2 px-3 rounded-lg text-light-green bg-light-green lg:text-base text-sm font-medium mb-2 inline-block">
            You’re fit for this job
          </span>
          <p className="text-dark-black lg:text-base text-sm">
            Come join our exciting team at AxleHire as a Sr UX/UI Designer, or
            User Experience/User Interface Designer as we address last mile
            opportunities. The primary responsibility is for creating and
            optimizing the user experience across all AxleHire tools and mobile
            app.
          </p>
        </Link>
        <Link
          to="/login"
          className="block sm:p-10 p-6 bg-white rounded-2xl border-2 border-white hover:border-yellow-300 transition-all"
        >
          <h3 className="lg:text-2xl sm:text-xl text-base mb-2 font-medium">
            UX UI Designer
          </h3>
          <h4 className="flex items-center text-dark-black lg:text-base text-sm mb-2">
            <img width={24} height={24} className="mr-2" src={google} alt="" />
            Company Name
            <span className="flex items-center ml-4 text-black">
              2.5 <img className="ml-1" src={star} alt="" />
            </span>
          </h4>
          <div className="flex mb-2">
            <div className="flex items-center mr-6 text-dark-black sm:text-base text-sm">
              <span className="w-2 h-2 rounded bg-dark-black inline-block mr-2" />
              Remote
            </div>
            <div className="flex items-center">
              <img className="mr-2" width={24} height={24} src={clock} alt="" />
              <p className="text-dark-black sm:text-base text-sm">
                Urgently hiring
              </p>
            </div>
          </div>
          <span className="py-2 px-3 rounded-lg text-light-red bg-light-red lg:text-base text-sm font-medium mb-2 inline-block">
            Your resume might be missing qualifications?
          </span>
          <p className="text-dark-black lg:text-base text-sm">
            Come join our exciting team at AxleHire as a Sr UX/UI Designer, or
            User Experience/User Interface Designer as we address last mile
            opportunities. The primary responsibility is for creating and
            optimizing the user experience across all AxleHire tools and mobile
            app.
          </p>
        </Link> */}
        <JobModal close={viewDetails} job={job} />
      </div>
    </section>
  );
};

export default JobsRecommendations;
