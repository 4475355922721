import React from "react";
import { Link } from "react-router-dom";
import arrowLeft from "../../assets/arrow-left-long.svg";

const StepOne = ({ prevStep, nextStep }: any) => {
  return (
    <section className="dasboard-content lg:w-[calc(100%-15rem)] w-full bg-light-sm sm:min-h-[calc(100vh-104px)] min-h-[calc(100vh-72px)] sm:px-6 px-4 sm:py-7 py-4">
      <Link
        to="/assessments"
        className="sm:py-4 py-2 sm:px-4 px-3 bg-dark-gray rounded-lg inline-flex items-center font-medium sm:text-base text-sm md:mb-8 sm:mb-6 mb-4"
      >
        <img className="sm:mr-2 mr-1 sm:w-5 w-4" src={arrowLeft} alt="" /> Back
      </Link>
      <div className="mx-auto max-w-3xl">
        <div className="md:p-8 sm:p-6 p-4 bg-white sm:rounded-2xl rounded-xl">
          <form action="#" method="GET">
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block sm:text-base text-sm font-medium sm:leading-6 text-gray-900 mb-2"
              >
                Title
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3  rounded-lg"
                  placeholder="Type here"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
              >
                Description
              </label>
              <div className="mt-2">
                <textarea
                  name=""
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3  rounded-lg"
                  placeholder="Type here"
                  rows={8}
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
              >
                Thumbnail
              </label>
              <div className="mt-2 relative flex justify-center px-6 py-8 bg-white-gray rounded-lg">
                <p className="text-base">
                  <span className="text-indigo-500 text-dark-black">
                    Click{" "}
                  </span>{" "}
                  to upload here
                </p>
                <input
                  name="country"
                  type="file"
                  required
                  className="sm:text-base absolute opacity-0 top-0 left-0 w-full h-full text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:px-4 px-3  sm:leading-6"
                  //   defaultValue={1784453204}
                  placeholder="Enter your number"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
              >
                Time
              </label>
              <div className="mt-2 relative">
                <input
                  name="country"
                  type="text"
                  required
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 rounded-lg sm:px-4 px-3  sm:pl-12 pl-8"
                  placeholder="30 mins"
                />
                <svg
                  className="sm:h-6 h-4 sm:w-6 w-4 absolute top-2/4 left-4 -translate-y-1/2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.75 13.25C20.75 18.08 16.83 22 12 22C7.17 22 3.25 18.08 3.25 13.25C3.25 8.42 7.17 4.5 12 4.5C16.83 4.5 20.75 8.42 20.75 13.25Z"
                    stroke="#0A1026"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 8V13"
                    stroke="#0A1026"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 2H15"
                    stroke="#0A1026"
                    strokeWidth="1.5"
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </form>
        </div>
        <div className="sm:mt-10 mt-6 text-end">
          <div
            onClick={prevStep}
            className="inline-block md:py-4 py-3 md:px-10 px-6 rounded-lg bg-shade-gray font-medium sm:text-base text-sm"
          >
            Previous
          </div>
          <div
            onClick={nextStep}
            className="inline-block md:py-4 py-3 md:px-10 px-6 rounded-lg bg-amber-300 font-medium sm:text-base text-sm ml-6"
          >
            Next
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepOne;
