// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBwHviGR4MZeQDyytANiuyHiBazR8cT1-M',
  authDomain: 'learning-booth-a908e.firebaseapp.com',
  projectId: 'learning-booth-a908e',
  storageBucket: 'learning-booth-a908e.appspot.com',
  messagingSenderId: '992210116550',
  appId: '1:992210116550:web:8bc1b5e7333f5bc4b91cc7',
  measurementId: 'G-XYZ2B6ZY6T',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
