import React, { useState } from 'react';
import TabOne from './tabs/TabOne';
import TabThree from './tabs/TabThree';

const MainContent = () => {
  const [tab, setTab] = useState(2);
  return (
    <section className="dasboard-content lg:w-[calc(100%-15rem)] bg-light-sm min-h-[calc(100vh-104px)] sm:px-6 px-3 sm:py-7 py-4">
      <h3 className="md:text-2xl sm:text-xl text-base text-gray-black font-medium sm:mb-4 mb-2">
        Courses
      </h3>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 md:mb-6 sm:mb-4 mb-3">
        <ul className="flex flex-wrap -mb-px">
          {/* <li className="md:mr-20 sm:mr-14 mr-8">
            <div
              onClick={() => setTab(0)}
              className={
                tab === 0
                  ? `inline-block sm:pb-4 pb-3 md:border-b-4 border-b-2 font-semibold text-gray-black cursor-pointer md:text-base text-sm border-amber-300 active`
                  : 'inline-block sm:pb-4 pb-3 md:border-b-4 border-b-2 border-transparent md:text-base text-sm cursor-pointer'
              }
              aria-current="page"
            >
              Current
            </div>
          </li>
          <li className="md:mr-20 sm:mr-14 mr-8">
            <div
              className={
                tab === 1
                  ? `inline-block sm:pb-4 pb-3 md:border-b-4 border-b-2 font-semibold text-gray-black cursor-pointer md:text-base text-sm border-amber-300 active`
                  : 'inline-block sm:pb-4 pb-3 md:border-b-4 border-b-2 border-transparent md:text-base text-sm cursor-pointer'
              }
              onClick={() => setTab(0)}
            >
              Completed
            </div>
          </li> */}
          <li className="">
            <div
              className={
                tab === 2
                  ? `inline-block sm:pb-4 pb-3 md:border-b-4 border-b-2 font-semibold text-gray-black cursor-pointer md:text-base text-sm border-amber-300 active`
                  : 'inline-block sm:pb-4 pb-3 md:border-b-4 border-b-2 border-transparent md:text-base text-sm cursor-pointer'
              }
              onClick={() => setTab(2)}
            >
              Recommended
            </div>
          </li>
        </ul>
      </div>
      {tab === 0 && <TabOne />}
      {tab === 2 && <TabThree />}
    </section>
  );
};

export default MainContent;
