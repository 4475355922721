import React, { useEffect, useState } from 'react';

const Clients = () => {
  const [companyThumbnails, setCompanyThumbnails] = useState([]);

  useEffect(() => {
    const fetchCompanyThumbnails = async () => {
      try {
        const response = await fetch(
          'https://learningboothserver-production.up.railway.app/content/companyThumbnails',
          {
            headers: {
              'Cache-Control': 'no-cache',
            },
          }
        );
        if (!response.ok) {
          throw new Error('Failed to fetch company thumbnails');
        }
        const data = await response.json();
        setCompanyThumbnails(data.slice(0, 5).reverse());
      } catch (error) {
        console.error('Error fetching company thumbnails:', error);
      }
    };

    fetchCompanyThumbnails();
  }, []);

  return (
    <section className="sm:py-20 py-10">
      <div className="relative isolate px-6 mx-auto max-w-7xl lg:px-8">
        <p className="text-center lg:text-2xl sm:text-xl text-base text-gray-black font-medium mb-6">
          Companies currently experiencing Third Bracket's Skill
          Profiling Platform.
        </p>
        <div className="flex flex-wrap justify-between bg-white">
          {companyThumbnails.map((thumbnail: any, index) => (
            <div
              key={index}
              className="text-center md:w-1/6 sm:w-2/4 w-full md:mb-0 mb-6"
            >
              <img
                className="mx-auto lg:h-16 h-8 mix-blend-multiply"
                src={thumbnail.companyThumbnail}
                alt={`Company Thumbnail ${index + 1}`}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Clients;
