import React, { useState } from 'react';
import profileIcon3 from '../../assets/profile-icon/3.svg';
import profileIcon4 from '../../assets/profile-icon/4.svg';
import addBtn from '../../assets/add-button.svg';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { axiosPrivate } from '../../api/axios';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { FormControl, Chip, TextField } from '@mui/material';
import Autocomplete, {
  AutocompleteRenderGetTagProps,
} from '@mui/material/Autocomplete';
import moment from 'moment';

import smallbag from '../../assets/new-dashboard-img/smallbag.svg'
import location from '../../assets/new-dashboard-img/location.svg'
import rupee from '../../assets/new-dashboard-img/rupee.svg'
import coin from '../../assets/new-dashboard-img/coin.svg'
import page from '../../assets/new-dashboard-img/page.svg'
import profilePik from '../../assets/new-dashboard-img/profilepik.png'
import github from '../../assets/new-dashboard-img/github.svg'
import Phone from '../../assets/new-dashboard-img/Phone.svg'
import mail from '../../assets/new-dashboard-img/mail.svg'
import menu from '../../assets/new-dashboard-img/menu.png'
import stared from '../../assets/new-dashboard-img/stared.svg'
import linkin from '../../assets/new-dashboard-img/linkin.svg'
import checked from '../../assets/new-dashboard-img/checked.svg'
import image1 from '../../assets/new-dashboard-img/image1.svg'
import image2 from '../../assets/new-dashboard-img/image2.svg'
import image3 from '../../assets/new-dashboard-img/image3.svg'
import image4 from '../../assets/new-dashboard-img/image4.svg'
import image5 from '../../assets/new-dashboard-img/image5.svg'
import bag from '../../assets/new-dashboard-img/bag-logo.svg'
import star from '../../assets/new-dashboard-img/star.svg'
import react from '../../assets/new-dashboard-img/React_Logo.svg'
import phthon from '../../assets/new-dashboard-img/Python-logo.svg'
import icon1 from '../../assets/new-dashboard-img/icon1.svg';
import icon2 from '../../assets/new-dashboard-img/icon2.svg';
import Heading from '../shared/Heading';
import ProfileSnapshot from '../shared/ProfileSnapshot';
import GaugeComponent from 'react-gauge-component'
import Sidebar from './Sidebar';
import Header from './Header';

interface InstitutionData {
  _id: string;
  colleges: string[];
}

const jobs = [1, 2, 3, 4];
const profileData = [
  {
    id: 1,
    title: 'No. of Skills',
    value: 11,
    img: image1,
    data: ['Team Management', 'Python', 'Sr. Sales Executive',]
  },
  {
    id: 2,
    title: 'Skill Profile Taken',
    value: 2,
    img: image2,
    data: ['Sr. Business Manger', 'Python', 'Sr. Sales Executive',]
  },
  {
    id: 3,
    title: 'Mapped jobs for you',
    value: 12,
    img: image3,
    data: ['Sr. Sales Executive', 'Business']
  },
  {
    id: 4,
    title: 'Certificates',
    value: 7,
    img: image4,
    data: ['C++', 'Python', 'Java', 'GoLang']
  },
  {
    id: 5,
    title: 'Courses',
    value: 2,
    img: image5,
    data: ['Management Study', 'Python', 'Java', 'GoLang']
  },
];

const MainContent = () => {
  // const [institutions, setInstitutions] = React.useState([]);
  const [institutions, setInstitutions] = React.useState<InstitutionData>({
    _id: '',
    colleges: [],
  });
  const [selectedInstitution, setSelectedInstitution] = React.useState('');
  const [toggleCertifications, setTogglecertifications] = React.useState(false);

  const [toggleBio, setToggleBio] = useState(false);
  const [toggleExperience, setToggleExperience] = useState(false);
  const [toggleEducation, setToggleEducation] = useState(false);
  const [toggleSkills, setToggleSkills] = useState(false);
  const [skill, setSkill] = useState([]);
  const [skills, setSkills] = useState([]);
  const [bio, setBio] = useState('');
  const [userDetails, setUserDetails] = useState<any>({});
  const user = JSON.parse(localStorage.getItem('user') as string);
  const [sideNavBar, setSideNavBar] = useState(true);
  const [rightSideNavBar, setRightSideNavBar] = useState(true);
  const userId = user?.id || user?._id;
  console.log(userId);
  const getUserDetails = async () => {
    await axiosPrivate
      .get(`/user/${userId}`)
      .then((res) => {
        setUserDetails(res.data);
      });
  };
  const addBio = async () => {
    await axiosPrivate
      .post(`/user/${user?.id}/about`, {
        bio: bio,
      })
      .then(() => {
        toast.success('Bio added!');
        setBio('');
        getUserDetails();
      })
      .catch(() => {
        toast.error('There was an error adding bio, try again later.');
      });
    setToggleBio(false);
  };
  React.useEffect(() => {
    (async () => {
      try {
        console.log('here');
        const res = await axiosPrivate.get('/assessment/all-institutions');
        console.log('not here');
        setInstitutions(res.data[0]);
        console.log('data received', res.data[0]);
      } catch (error) {
        console.error('Error fetching institutions:', error);
      }
    })();
  }, []);


  const educationFormik = useFormik({
    initialValues: {
      institution: '',
      degree: '',
      fieldOfStudy: '',
      startDate: '',
      endDate: '',
      grade: '',
    },
    onSubmit: async (values) => {
      console.log(values);
      await axiosPrivate
        .post(`/user/${user?.id}/education`, values)
        .then(() => {
          toast.success('Education added!');
          setToggleEducation(false);
          educationFormik.resetForm();
          getUserDetails();
        })
        .catch(() => {
          toast.error('There was an error adding education, try again later.');
        });
    },
  });
  const experienceFormik = useFormik({
    initialValues: {
      company: '',
      position: '',
      startDate: '',
      endDate: '',
    },
    onSubmit: async (values) => {
      await axiosPrivate
        .post(`/user/${user?.id}/experience`, values)
        .then(() => {
          toast.success('Experience added!');
          setToggleExperience(false);
          experienceFormik.resetForm();
          getUserDetails();
        })
        .catch(() => {
          toast.error('There was an error adding experience, try again later.');
        });
    },
  });

  React.useEffect(() => {
    (async () => {
      await axiosPrivate
        .get('/assessment/all-skills')
        .then((res) => setSkills(res.data));
    })();
  }, []);

  const appendSkills = async () => {
    await axiosPrivate
      .post(`/user/${user.id}/skills`, skill)
      .then(() => {
        setSkill([]);
        toast.success('Skills added successfully!');
        setToggleSkills(false);
        getUserDetails();
      })
      .catch(() => {
        toast.error('There was an error adding skills.');
      });
  };
  const licenseFormik = useFormik({
    initialValues: {
      name: '',
      issuingAuthority: '',
      issueDate: '',
    },
    onSubmit: async (values) => {
      await axiosPrivate
        .post(`/user/${user?.id}/license`, values)
        .then(() => {
          toast.success('Certificate added!');
          setTogglecertifications(false);
          licenseFormik.resetForm();
          getUserDetails();
        })
        .catch(() => {
          toast.error('There was an error adding license, try again later.');
        });
    },
  });

  React.useEffect(() => {
    if (user?.id) {
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  const deleteSkill = async (skillToDelete: any) => {
    await axiosPrivate
      .delete(`/user/${user?.id}/skill/${skillToDelete}`)
      .then(() => {
        toast.success('Skill Removed');
        getUserDetails();
      })
      .catch(() => {
        toast.error('An error while removing skill.');
      });
  };
  const deleteExperience = async (id: any) => {
    await axiosPrivate
      .delete(`/user/${user?.id}/experience/${id}`)
      .then(() => {
        toast.success('Experience Removed');
        getUserDetails();
      })
      .catch(() => {
        toast.error('An error while removing experience.');
      });
  };
  const deleteEducation = async (id: any) => {
    await axiosPrivate
      .delete(`/user/${user?.id}/education/${id}`)
      .then(() => {
        toast.success('Education Removed');
        getUserDetails();
      })
      .catch(() => {
        toast.error('An error while removing education.');
      });
  };
  const deleteLicense = async (id: any) => {
    await axiosPrivate
      .delete(`/user/${user?.id}/license/${id}`)
      .then(() => {
        toast.success('License Removed');
        getUserDetails();
      })
      .catch(() => {
        toast.error('An error while removing education.');
      });
  };

  return (
    <>
      <section className="dasboard-content pl-4">
        <div className='bg-[#f7f8fa]'>
          <div className="flex items-start flex-row justify-between gap-[18px]  bg-white" >
            <div className='left-side-section w-[calc(100%-541px)] max-w-[1700px] mx-auto flex-1 pb-3'>
              <div className='top-profile-section pt-[17px]'>
                <div className='py-3 lg:px-8 px-2 bg-[#FAFAFA] w-full rounded-t-xl'>
                  <div className='flex-1 flex flex-row items-center justify-between flex-wrap lg:gap-0 gap-5'>
                    <div className='flex-row items-center justify-between flex gap-[30px]'>
                      <div className="profileImage">
                        <div className='w-16 h-16 rounded-full overflow-hidden'>
                          <img src={profilePik} alt="profilepic" className='w-full h-full object-contain' />
                        </div>
                      </div>
                      <div className='userData flex-1 '>
                        <h3 className='text-xl text-black font-bold mb-0.5'>Akash Gupta</h3>
                        <h4 className='text-text_gray text-[10.84px] font-normal  '>Python Developer</h4>
                        <div className='mt-3 flex flex-row items-center justify-between flex-wrap gap-2 lg:gap-0'>
                          <div className='flex flex-row gap-1 items-center'>
                            <span className=''>
                              <img src={Phone} alt="Phone" />
                            </span>
                            <span className='text-dark_grey text-[10px]/[12px] font-normal'>+91-2983749279</span>
                          </div>
                          <div className='flex flex-row gap-1 items-center lg:ml-4'>
                            <span className=''>
                              <img src={mail} alt="Phone" />
                            </span>
                            <span className='text-dark_grey text-[10px]/[12px] font-normal'>xyz@gmail.com</span>
                          </div>
                          <div className='flex flex-row gap-2 items-center lg:ml-5'>
                            <a href="" className='h-4 w-4'>
                              <img src={linkin} alt="Phone" className='w-full h-full' />
                            </a>
                            <a href="" className='h-4 w-4'>
                              <img src={github} alt="Phone" className='w-full h-full' />
                            </a>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className='flex-row flex flex-wrap items-center lg:justify-end justify-center gap-3'>
                      {/* <div className=''> */}
                      <div className='relative rounded-lg border-[#F3F3F3] border bg-white'>
                        <GaugeComponent
                          type="semicircle"
                          id={'value'}
                          marginInPercent={{ top: 0.06, bottom: 0.00, left: 0.07, right: 0.07 }}
                          arc={{
                            colorArray: ['#978FED', '#EE89DF', '#74B8EF', '#FBDE9D'],
                            nbSubArcs: 4,
                            width: 0.08,
                            padding: 0.01,
                            subArcs:
                              [
                                { limit: 40 },
                                { limit: 60 },
                                { limit: 80 },
                                { limit: 100 },
                                // { showTick: true }
                              ]
                          }}

                          className="w-[143px] h-[102px]"
                          labels={{
                            tickLabels: {
                              hideMinMax: true,
                              type: 'inner',
                              ticks: [
                                {
                                  value: 0
                                },
                                {
                                  value: 10
                                }, {
                                  value: 20
                                }, {
                                  value: 30
                                }, {
                                  value: 40
                                }, {
                                  value: 50
                                }, {
                                  value: 60
                                }, {
                                  value: 70
                                }, {
                                  value: 80
                                }, {
                                  value: 90
                                }, {
                                  value: 100
                                }],
                              defaultTickValueConfig: {
                                hide: true
                              },
                              defaultTickLineConfig: {
                                width: 1,
                                length: 1,
                                color: '#7A7A7A',
                                distanceFromArc: 10
                              }

                            },
                            valueLabel: {
                              style: {
                                fontSize: 22, fill: '#242424', fontWeight: 700, textShadow: 'none'
                              }
                            }
                          }}


                          // style={{ margin: 'auto' }}
                          pointer={{ type: "blob", animationDelay: 100, width: 25, elastic: true }}
                          value={20}
                        />
                        <p className='absolute text-[#242424] font-bold text-[9.85px] w-full text-center bottom-2 text-nowrap'>
                          Hireability Score
                        </p>
                      </div>
                      {/* </div> */}
                      <div className='relative rounded-lg border-[#F3F3F3] border bg-white'>
                        <GaugeComponent
                          type="semicircle"
                          id={'value'}
                          marginInPercent={{ top: 0.06, bottom: 0.00, left: 0.07, right: 0.07 }}
                          arc={{
                            colorArray: ['#FFBA87', '#E2965E', '#EF9974', '#FFAAA5'],
                            nbSubArcs: 4,
                            width: 0.08,
                            padding: 0.01,
                            subArcs:
                              [
                                { limit: 40 },
                                { limit: 60 },
                                { limit: 80 },
                                { limit: 100 },
                                // { showTick: true }
                              ]
                          }}

                          className="w-[143px] h-[102px]"
                          labels={{
                            tickLabels: {
                              hideMinMax: true,
                              type: 'inner',
                              ticks: [
                                {
                                  value: 0
                                },
                                {
                                  value: 10
                                }, {
                                  value: 20
                                }, {
                                  value: 30
                                }, {
                                  value: 40
                                }, {
                                  value: 50
                                }, {
                                  value: 60
                                }, {
                                  value: 70
                                }, {
                                  value: 80
                                }, {
                                  value: 90
                                }, {
                                  value: 100
                                }],
                              defaultTickValueConfig: {
                                hide: true
                              },
                              defaultTickLineConfig: {
                                width: 1,
                                length: 1,
                                color: '#7A7A7A',
                                distanceFromArc: 10
                              }

                            },
                            valueLabel: {
                              style: {
                                fontSize: 22, fill: '#242424', fontWeight: 700, textShadow: 'none'
                              }
                            }
                          }}


                          // style={{ margin: 'auto' }}
                          pointer={{ type: "blob", animationDelay: 100, width: 25, elastic: true }}
                          value={72}
                        />
                        <p className='absolute text-[#242424] font-bold text-[9.85px] w-full text-center bottom-2 text-nowrap'>
                          Profile Score
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='py-3 bg-[#F2F2F2] rounded-b-xl'>
                  <div className="flex flex-row items-center justify-center flex-wrap gap-4">
                    <div className="flex-1 flex flex-row items-center justify-center">
                      <div className='GlobalRanking rounded  h-10 w-min-[190px] flex flex-row py-2 px-3.5 gap-3'>
                        <h3 className='text-white text-sm/normal font-semibold '>Global Ranking</h3>
                        <div className='h-6 rounded bg-white px-1 py-0.5 flex-row items-center justify-center gap-1 flex' >
                          <div className='w-5 h-5'>
                            <img src={stared} alt="" className='w-full h-full' />
                          </div>
                          <h3 className='my-0 text-[#3A2D2D] text-sm font-bold '>23</h3>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-row items-center justify-end">
                      <div className="flex flex-row gap-6 flex-wrap lg:flex-nowrap items-center justify-center lg:px-8 px-3">
                        <h3 className='text-black font-semibold text-[10.84px]/[12.64px] '>Complete  Profile</h3>
                        <div className='flex flex-row items-center'>
                          <span className='w-4 h-4 '>
                            <img src={checked} className='w-full h-full' alt="" />
                          </span>
                          <span className='w-[29px] h-0.5 bg-[#5FD495]'></span>
                          <span className='w-4 h-4 '>
                            <img src={checked} className='w-full h-full' alt="" />
                          </span>
                          <span className='w-[29px] h-0.5 bg-[#5FD495]'></span>
                          <span className='w-4 h-4 '>
                            <img src={checked} className='w-full h-full' alt="" />
                          </span>
                          <span className='w-[29px] h-0.5 flex flex-row'>
                            <span className='bg-[#5FD495] border-e-rounded flex-1'></span>
                            <span className='bg-[#D8D8D8] flex-1'></span>
                          </span>
                          <span className='bg-white w-4 h-4 border-[#D8D8D8] border rounded-full'></span>
                        </div>
                        <button className='bg-[#161B2A] rounded h-[28.97px] flex lfex-row items-center justify-center px-2'>
                          <span className='text-white text-[10.84px]/[12.64px] font-normal'>Take Action</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='profile-snapshot'>
                <div className="mt-[30.7px]">
                  <Heading text={'Profile Snapshot'} />
                  <div className='overflow-hidden'>
                    <div className='grid lg:grid-cols-3  grid-cols-1 xl:grid-cols-5 items-start justify-center gap-4'>
                      {profileData.map((items, idx) => {
                        return (
                          <ProfileSnapshot item={items} idx={idx} />
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className='profile-analysis '>
                <div className="mt-[20.33px]">
                  <Heading text={'Profile Analysis'} />
                  <div className='flex flex-row flex-wrap items-start justify-center xl:w-4/5 w-full gap-4'>
                    <div className='flex-1'>
                      <div className='p-2.5 bg-[#FAFAFA] rounded-t-lg  overflow-hidden'>
                        <div className="flex flex-row items-center">
                          <div className='flex flex-row items-center gap-3 justify-between'>
                            <div className='w-10 h-[38px] flex-row items-center justify-center bg-white  flex rounded'>
                              <div className='w-5 h-[18px]'>
                                <img src={bag} alt="bag-log" className='w-full h-full' />
                              </div>
                            </div>
                            <div className='flex-col'>
                              <h3 className='text-[#3C4B80] text-xs font-bold mb-[5px]'>Jobs Applied</h3>
                              <div className='flex-row flex items-center justify-start'>
                                <div className='w-[18px] h-[18px] rounded-full border-[0.46px] border-[#EEEEEE]'>
                                  <img src={icon1} alt="google-icon" />
                                </div>
                                <div className='w-[18px] -ml-2 h-[18px] rounded-full border-[0.46px] border-[#EEEEEE]'>
                                  <img src={icon2} alt="google-icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <button className="border-[#AEF3BE] ml-3 h-8 w-[50px] rounded-[15px] border-[1.65px] bg-[#D9FFE8]">
                            <span className='text-[#349956] font-normal text-lg'>23</span>
                          </button>
                        </div>
                      </div>
                      <div className='bg-[#F3F3F3] rounded-b-lg overflow-hidden flex flex-row items-center p-2.5 justify-end'>
                        <button className='text-[#5685FD] bg-transparent border-none text-[10px] font-medium'>
                          <span>Know More</span>
                        </button>
                      </div>
                    </div>
                    <div className='flex-1'>

                      <div className='p-2.5 rounded-t-lg  overflow-hidden bg-[#FAFAFA]'>
                        <div className="flex flex-row items-center">
                          <div className='flex flex-row items-center  gap-3 justify-between'>
                            <div className='w-10 h-[38px] flex-row items-center justify-center bg-white  flex rounded'>
                              <div className='w-5 h-[18px]'>
                                <img src={react} alt="bag-log" className='w-full h-full' />
                              </div>
                            </div>
                            <div className='flex-col'>
                              <h3 className='text-[#3C4B80] text-xs font-bold mb-[5px]'>Profile Viewed by Companies</h3>
                              <div className='flex-row border-[#F3CBAE] border rounded-[9.55px] px-[7.46px] py-[3.15px] w-min  flex items-center justify-start'>
                                <span className='text-[#C89150] text-[10.84px]/[12px] font-normal'>Incomplete</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='bg-[#F3F3F3] rounded-b-lg  overflow-hidden flex flex-row  items-center p-2.5 justify-end gap-6'>
                        <div className='flex flex-row items-center flex-1 justify-start gap-1'>
                          <h3 className='my-0 text-[#757575] font-medium text-[10px]/[12px] '>38%</h3>
                          <div className='flex-1 rounded-[32.52px] bg-white h-[9.66px] '>
                            <div className='w-[38%] bg-[#7FD9A8] h-full rounded-[32.52px]'></div>
                          </div>
                        </div>
                        <button className='text-[#5685FD] bg-transparent border-none text-[10px] font-medium'>
                          <span>Complete Now</span>
                        </button>
                      </div>
                    </div>
                    <div className='flex-1'>

                      <div className='p-2.5 rounded-t-lg  overflow-hidden bg-[#FAFAFA]'>
                        <div className="flex flex-row items-center">
                          <div className='flex flex-row items-center  gap-3 justify-between'>
                            <div className='w-10 h-[38px] flex-row items-center justify-center bg-white  flex rounded'>
                              <div className='w-5 h-[18px]'>
                                <img src={phthon} alt="bag-log" className='w-full h-full' />
                              </div>
                            </div>
                            <div className='flex-col'>
                              <h3 className='text-[#3C4B80] text-xs font-bold mb-[5px]'>Profile Gap Anlysis</h3>
                              <div className='flex-row border-[#F3CBAE] border rounded-[9.55px] px-[7.46px] py-[3.15px] w-min  flex items-center justify-start'>
                                <span className='text-[#C89150] text-[10.84px]/[12px] font-normal'>Incomplete</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='bg-[#F3F3F3] rounded-b-lg  overflow-hidden flex flex-row  items-center p-2.5 justify-end gap-6'>
                        <div className='flex flex-row items-center flex-1 justify-start gap-1'>
                          <h3 className='my-0 text-[#757575] font-medium text-[10px]/[12px] '>68%</h3>
                          <div className='flex-1 rounded-[32.52px] bg-white h-[9.66px] '>
                            <div className='w-[68%] bg-[#7FD9A8] h-full rounded-[32.52px]'></div>
                          </div>
                        </div>
                        <button className='text-[#5685FD] bg-transparent border-none text-[10px] font-medium'>
                          <span>Complete Now</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='jobs-analsis '>
                <div className="mt-[19px] flex-1 ">
                  <div className="flex-row flex items-center justify-between">
                    <Heading text={'Jobs for you'} />
                    <button className='border-[#D0DEE8] bg-[#F9F9F9] h-[28.97px]  w-[66px] rounded-[24px] px-3 py-2 flex flex-row items-center justify-center'>
                      <span className='text-[#5685FD] text-[10px]/[12px] font-medium'>Show All</span>
                    </button>
                  </div>
                  <div className='overflow-hidden mt-[7.19px]'>
                    <div className='flex-row gap-4 flex no-scrollbar cursor-grab overflow-x-auto'>
                      {jobs.map((items, idx) => {
                        return (
                          <div key={idx} className='w-min flex-1'>
                            <div className='bg-[#FAFAFA] rounded-t-lg overflow-hidden px-4 pt-4 pb-3 '>
                              <h3 className='text-[#3C4B80] text-sm font-bold mb-1.5'>Business Developer</h3>
                              <div className="flex flex-row items-center gap-1.5  justify-start mb-2">
                                <h3 className='text-[#3C4B80] text-xs font-normal'>Atlasian</h3>
                                <div className='bg-[#E0E0E0] h-3.5 w-[1px]'></div>
                                <div className='flex-row items-center flex justify-center gap-2.5'>
                                  <div className='w-3 h-[11.43]'>
                                    <img src={star} className='w-full h-full' alt="" />
                                  </div>
                                  <span className='text-[#3C4B80] text-xs font-normal'>4.3</span>
                                </div>
                                <div className='bg-[#E0E0E0] h-3.5 w-[1px]'></div>
                                <h3 className='text-[#3C4B80] text-xs font-normal'>5 Reviews</h3>
                              </div>
                              <div className='flex flex-row my-2 gap-1.5 items-center justify-start '>
                                <div className='flex flex-row items-center justify-center gap-2.5'>
                                  <div className='w-3 h-[11px]'>
                                    <img src={smallbag} className='w-full h-full' alt="" />
                                  </div>
                                  <h3 className='text-[#3C4B80] text-xs font-semibold text-nowrap'>3-7 Years</h3>
                                </div>
                                <div className='bg-[#E0E0E0] h-3.5 w-[1px]'></div>
                                <div className='flex flex-row items-center justify-center gap-2.5'>
                                  <div className='w-3 h-[11px]'>
                                    <img src={rupee} className='w-full h-full' alt="" />
                                  </div>
                                  <h3 className='text-[#3C4B80] text-xs font-semibold text-nowrap'>Not Disclosed</h3>
                                </div>
                                <div className='bg-[#E0E0E0] h-3.5 w-[1px]'></div>
                                <div className='flex flex-row items-center justify-center gap-2.5'>
                                  <div className='w-3 h-[11px]'>
                                    <img src={location} className='w-full h-full' alt="" />
                                  </div>
                                  <h3 className='text-[#3C4B80] text-xs font-semibold text-nowrap'> Bengaluru</h3>
                                </div>
                              </div>
                              <div className='flex flex-row items-center gap-1.5 justify-start'>
                                <div className='w-2.5 h-3'>
                                  <img src={page} alt="" className='w-full h-full' />
                                </div>
                                <h3 className='text-[#546974] font-normal text-[10.84px]/[13.12px] flex-1 line-clamp-2'>Most companies look for people who are serious about
                                  new opportunities. Create an updated</h3>
                              </div>
                            </div>
                            <div className='h-[47px] rounded-b-lg overflow-hidden bg-[#F3F3F3] flex flex-row items-center justify-center'>
                              <button className=' bg-transparent border-none '>
                                <span className='text-[#5685FD] text-xs font-bold'>Take Assessment</span>
                              </button>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`right-side-section h-full ${rightSideNavBar ? 'w-[215px] z-[9999] fixed md:sticky right-0' : 'sticky z-10'} md:sticky top-16 pb-3 bg-[#f7f8fa] `}>
              {rightSideNavBar ?
                <>
                  <div
                    className="bg-[#F7F8FA]">
                    <button className={'md:hidden d-block bg-[#F0F1F91A] rounded flex w-10 p-2 flex-row items-center justify-center'} onClick={() => { setRightSideNavBar((prev: boolean) => !prev) }}>
                      <span className='text-[#161B2A] text-xl text-left'>X</span>
                    </button>
                  </div>
                  <div className="pl-[14.87px] pr-3.5  overflow-y-auto no-scrollbar min-h-screen h-full">
                    <div className='mt-[17.84px]  py-3 px-[15.5px] bg-[#16192C] rounded-lg  border-b border-[#485060]'>
                      <div className='mb-4'>
                        <h3 className='text-sm text-left font-bold text-white mb-1'>Bracket Coins</h3>
                        <p className='text-[#A6B1BD] text-[8px]/[12px] mb-2 pt-0 font-normal'>Lorem ipsum dolor sit amet,consectetur adipiscing elit.</p>
                      </div>
                      <div className='flex-col flex items-center justify-center mb-4'>
                        <div className='mb-[7px] w-[54px] h[54px]'>
                          <img src={coin} alt="" />
                        </div>
                        <h3 className='text-white '>
                          <span className='text-2xl font-bold'>56</span>
                          <span className='text-base font-normal'>/100</span>
                        </h3>
                      </div>
                      <div className='flex-row flex items-center justify-between gap-2'>
                        <button className='border-[#667499] flex-1  border-[0.4px] bg-[#4C6FFF33] flex flex-row items-center justify-center rounded-[4.46px] px-2 gap-[7px] h-[26.82px]'>
                          <div>
                            <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.216797" y="0.208984" width="20.1408" height="16.2619" rx="8.13095" fill="white" fill-opacity="0.2" />
                              <path d="M9.63119 11.0054V6.73907H10.7098V11.0054H9.63119ZM8.03733 9.41155V8.33292H12.3037V9.41155H8.03733Z" fill="white" />
                            </svg>
                          </div>
                          <span className='font-semibold text-[8.91px] text-nowrap text-white'>Earn More</span>
                        </button>
                        <button className='bg-[#FAC600] flex-1 flex flex-row items-center justify-center rounded-[4.46px] h-[26.82px]'>
                          <span className='font-semibold text-[8.91px] text-[#3F3926]'>Redeem</span>
                        </button>
                      </div>
                    </div>
                    <div className='mt-[24.54px]'>
                      <div className='bg-white  card-box-shadow rounded-lg'>
                        <div className='px-2'>
                          <div className='h-[42px]  border-box mb-1 flex flex-row items-center gap-2 justify-start'>
                            <div className='h-4 w-4'>
                              <img src={bag} className='w-full h-full' alt="" />
                            </div>
                            <h3 className='font-bold text-xs text-black'>Trending Jobs</h3>
                          </div>
                          <div className='flex flex-row mt-3 items-center justify-between h-9 border-[#F6F6F6] border-b'>
                            <div className='flex-row flex items-center justify-start gap-[5px]'>
                              <div className='w-6 h-6'>
                                <img src={icon1} className='w-full h-full' alt="" />
                              </div>
                              <div className=''>
                                <h3 className='text-[#333944] text-xs font-medium'>
                                  Product Designer
                                </h3>
                                <p className='text-[#6B7A99] text-[10px] font-normal'>Google</p>
                              </div>
                            </div>
                            <button className='border-[#0057FC] border-[0.6px] h-6 w-6  rounded-[4.8px] flex-row flex justify-center items-center p-1.5'>
                              <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.625 7.82422V3.57422H4.375M8.5 3.69922L3.375 8.82422" stroke="#0057FC" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </button>
                          </div>
                          <div className='flex flex-row mt-3 items-center justify-between h-9 border-[#F6F6F6] border-b'>
                            <div className='flex-row flex items-center justify-start gap-[5px]'>
                              <div className='w-6 h-6'>
                                <img src={icon1} className='w-full h-full' alt="" />
                              </div>
                              <div className=''>
                                <h3 className='text-[#333944] text-xs font-medium'>
                                  Product Designer
                                </h3>
                                <p className='text-[#6B7A99] text-[10px] font-normal'>Google</p>
                              </div>
                            </div>
                            <button className='border-[#0057FC] border-[0.6px] h-6 w-6  rounded-[4.8px] flex-row flex justify-center items-center p-1.5'>
                              <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.625 7.82422V3.57422H4.375M8.5 3.69922L3.375 8.82422" stroke="#0057FC" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </button>
                          </div>
                          <div className='flex flex-row mt-3 items-center justify-between h-9 border-[#F6F6F6] border-b'>
                            <div className='flex-row flex items-center justify-start gap-[5px]'>
                              <div className='w-6 h-6'>
                                <img src={icon1} className='w-full h-full' alt="" />
                              </div>
                              <div className=''>
                                <h3 className='text-[#333944] text-xs font-medium'>
                                  Product Designer
                                </h3>
                                <p className='text-[#6B7A99] text-[10px] font-normal'>Google</p>
                              </div>
                            </div>
                            <button className='border-[#0057FC] border-[0.6px] h-6 w-6  rounded-[4.8px] flex-row flex justify-center items-center p-1.5'>
                              <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.625 7.82422V3.57422H4.375M8.5 3.69922L3.375 8.82422" stroke="#0057FC" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </button>
                          </div>
                          <div className='flex flex-row mt-3 items-center justify-between h-9 border-[#F6F6F6] border-b'>
                            <div className='flex-row flex items-center justify-start gap-[5px]'>
                              <div className='w-6 h-6'>
                                <img src={icon1} className='w-full h-full' alt="" />
                              </div>
                              <div className=''>
                                <h3 className='text-[#333944] text-xs font-medium'>
                                  Product Designer
                                </h3>
                                <p className='text-[#6B7A99] text-[10px] font-normal'>Google</p>
                              </div>
                            </div>
                            <button className='border-[#0057FC] border-[0.6px] h-6 w-6  rounded-[4.8px] flex-row flex justify-center items-center p-1.5'>
                              <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.625 7.82422V3.57422H4.375M8.5 3.69922L3.375 8.82422" stroke="#0057FC" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="border-[#EFEFEF] border-t py-2 ">
                          <div className='px-2'>
                            <button className='bg-[#161B2A] h-[28.97px] w-full flex-row items-center justify-center rounded'>
                              <span className='text-white text-[10px] rounded '>View All</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='mt-[24.54px]'>
                      <div className='bg-white  card-box-shadow rounded-lg'>
                        <div className='px-2'>
                          <div className='h-[42px]  border-box mb-1 flex flex-row items-center gap-2 justify-start'>
                            <div className='h-4 w-4'>
                              <img src={bag} className='w-full h-full' alt="" />
                            </div>
                            <h3 className='font-bold text-xs text-black'>Trending Companies</h3>
                          </div>
                          <div className='flex flex-row mt-3 items-center justify-between h-9 border-[#F6F6F6] border-b'>
                            <div className='flex-row flex items-center justify-start gap-[5px]'>
                              <div className='w-6 h-6'>
                                <img src={icon1} className='w-full h-full' alt="" />
                              </div>
                              <div className=''>
                                <h3 className='text-[#333944] text-xs font-medium'>
                                  Product Designer
                                </h3>
                                <p className='text-[#6B7A99] text-[10px] font-normal'>120 Hired  |  Past Month</p>
                              </div>
                            </div>

                          </div>
                          <div className='flex flex-row mt-3 items-center justify-between h-9 border-[#F6F6F6] border-b'>
                            <div className='flex-row flex items-center justify-start gap-[5px]'>
                              <div className='w-6 h-6'>
                                <img src={icon1} className='w-full h-full' alt="" />
                              </div>
                              <div className=''>
                                <h3 className='text-[#333944] text-xs font-medium'>
                                  Product Designer
                                </h3>
                                <p className='text-[#6B7A99] text-[10px] font-normal'>120 Hired  |  Past Month</p>
                              </div>
                            </div>

                          </div>
                          <div className='flex flex-row mt-3 items-center justify-between h-9 border-[#F6F6F6] border-b'>
                            <div className='flex-row flex items-center justify-start gap-[5px]'>
                              <div className='w-6 h-6'>
                                <img src={icon1} className='w-full h-full' alt="" />
                              </div>
                              <div className=''>
                                <h3 className='text-[#333944] text-xs font-medium'>
                                  Product Designer
                                </h3>
                                <p className='text-[#6B7A99] text-[10px] font-normal'>120 Hired  |  Past Month</p>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="border-[#EFEFEF] border-t py-2 ">
                          <div className='px-2'>
                            <button className='bg-[#161B2A] h-[28.97px] w-full flex-row items-center justify-center rounded'>
                              <span className='text-white text-[10px] rounded '>View All</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>

                :
                <div
                  className="bg-[#F7F8FA] z-10 min-h-screen h-full">
                  <button className={`${rightSideNavBar ? 'hidden' : 'd-block'} bg-[#F0F1F91A] rounded flex w-10 p-2 flex-row items-center justify-center`} onClick={() => { setRightSideNavBar((prev: boolean) => !prev) }}>
                    <img src={menu} alt="" width={32} height={32} className='w-8 h-8 object-contain' />
                  </button>
                </div>
              }
            </div>
          </div>
        </div >
      </section >
      <section className="dasboard-content  lg:w-[calc(100%-15rem)] w-full bg-light-gray min-h-[calc(100vh-104px)] md:px-6 px-3 md:py-7 py-4">
        <div className="flex flex-wrap mb-10">
          <div className="w-2/4 ">
            <h3 className="md:text-2xl sm:text-xl text-base text-gray-black font-medium md:mb-4 mb-2">
              Profile
            </h3>
            <p className="text-light-black md:text-base text-sm">
              Please complete your profile to get high demand job
            </p>
          </div>
          <div className="w-2/4 text-right">
            <Link
              to="/dashboard"
              className="border-0 bg-amber-300 font-medium inline-block width-auto lg:text-base md:text-sm text-xs text-gray-black rounded-xl px-4 py-2 sm:px-6 sm:py-4"
            >
              View Profile
            </Link>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="md:w-2/6 w-full md:pr-3">
            <div className="bg-white md:p-9 sm:p-6 p-4 md:rounded-2xl rounded-xl h-[calc(100%-1.5rem)] mb-6">
              <div>
                <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
                  <div
                    className="bg-amber-300 h-2.5 rounded-full dark:bg-amber-300"
                    style={{ width: `${userDetails?.profileCompletion}%` }}
                  />
                </div>
                <h4 className="md:text-2xl sm:text-xl text-sm font-bold text-center">
                  {userDetails?.profileCompletion}%
                </h4>
              </div>
              <div className="flex justify-between">
                <span className="sm:text-base text-sm">0%</span>
                <span className="sm:text-base text-sm">Profile completion</span>
                <span className="sm:text-base text-sm">100%</span>
              </div>
              {/* <div className="rounded-full px-2 py-1 bg-[#0A1026] text-white text-center font-bold">
              Coming Soon...
            </div> */}
            </div>
          </div>
          <div className="md:w-4/6 w-full md:pl-3">
            <div className="bg-white md:p-9 sm:p-6 p-4 md:rounded-2xl rounded-xl md:mb-6 sm:mb-4 mb-3">
              <div className="lg:mb-11 md:mb-6 mb-4">
                <h4 className="md:text-xl text-base font-medium mb-2">
                  Profile statistic
                </h4>
                <p className="text-sm text-light-black">
                  Please complete your profile to get high demand job
                </p>
              </div>
              <div className="flex flex-wrap">
                {/* <div className="flex lg:w-1/4 sm:w-1/2 w-full lg:mt-0 mt-4 items-center pr-4">
                <span className="sm:h-20 h-16 sm:w-20 w-16 bg-blue-50 inline-flex justify-center items-center mr-3 rounded-xl">
                  <img src={profileIcon1} alt="" />
                </span>
                <div>
                  <p className="sm:text-sm text-xs text-dark-black mb-1">
                    Rank
                  </p>
                  {/* <h4 className="sm:text-base text-sm font-semibold">
                    1 / 15K
                  </h4> 
                </div>
              </div>
              <div className="flex lg:w-1/4 sm:w-1/2 w-full lg:mt-0 mt-4 items-center pr-4">
                <span className="sm:h-20 h-16 sm:w-20 w-16 bg-amber-50 inline-flex justify-center items-center mr-3 rounded-xl">
                  <img src={profileIcon2} alt="" />
                </span>
                <div>
                  <p className="sm:text-sm text-xs text-dark-black mb-1">
                    Completion Timing
                  </p>
                  {/* <h4 className="sm:text-base text-sm font-semibold">10:00</h4> 
                </div>
              </div> */}
                <div className="flex lg:w-1/4 sm:w-1/2 w-full lg:mt-0 mt-4 items-center pr-4">
                  <span className="sm:h-20 h-16 sm:w-20 w-16 bg-green-50 inline-flex justify-center items-center mr-3 rounded-xl">
                    <img src={profileIcon3} alt="" />
                  </span>
                  <div>
                    <p className="sm:text-sm text-xs text-dark-black mb-1">
                      Skills
                    </p>
                    <h4 className="sm:text-base text-sm font-semibold">
                      {userDetails?.totalSkills}
                    </h4>
                  </div>
                </div>
                <div className="flex lg:w-1/4 sm:w-1/2 w-full lg:mt-0 mt-4 items-center">
                  <span className="sm:h-20 h-16 sm:w-20 w-16 bg-purple-50 inline-flex justify-center items-center mr-3 rounded-xl">
                    <img src={profileIcon4} alt="" />
                  </span>
                  <div>
                    <p className="sm:text-sm text-xs text-dark-black mb-1">
                      Assessment Completed
                    </p>
                    <h4 className="sm:text-base text-sm font-semibold">
                      {userDetails?.totalAssessmentsCompleted}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="md:w-6/6 w-full md:pr-3">
            <h3 className="md:text-2xl sm:text-xl text-base text-gray-black font-medium mb-4">
              About
            </h3>
            <div
              className={`bg-white md:p-8 sm:p-6 p-4 md:rounded-2xl flex-col rounded-xl md:mb-6 sm:mb-4 mb-3 flex justify-start items-start overflow-y-scroll`}
            >
              {!toggleBio && (
                <button
                  type="button"
                  className="flex items-center sm:text-base text-xs text-indigo-600 font-medium"
                  onClick={() => {
                    if (userDetails?.about) {
                      setBio(userDetails?.about);
                    }
                    setToggleBio(true);
                  }}
                >
                  {!userDetails?.about && (
                    <img
                      className="sm:h-8 sm:w-8  h-6 w-6 mr-4"
                      src={addBtn}
                      alt=""
                    />
                  )}
                  {userDetails?.about ? 'Edit Bio' : 'Add Profile Summary'}
                </button>
              )}
              {!toggleBio && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: userDetails?.about,
                  }}
                ></p>
              )}
              {toggleBio && (
                <div className="flex flex-col items-start justify-start space-y-12 w-full">
                  <div className="w-full">
                    {/* <button
                    className="py-4 font-bold"
                    onClick={() => setToggleBio(false)}
                  >
                    Close
                  </button> */}
                    <ReactQuill
                      theme={'snow'}
                      value={bio}
                      onChange={setBio}
                      className="h-[150px] w-full"
                    />
                  </div>

                  <button
                    onClick={addBio}
                    className="border-0 bg-amber-300 font-medium inline-block width-auto lg:text-base md:text-sm text-xs text-gray-black rounded-xl px-4 py-2 sm:px-6 sm:py-4"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            {/* <h3 className="md:text-2xl sm:text-xl text-base text-gray-black font-medium mb-4">
            Education
          </h3> */}
            {/* <div className="bg-white md:p-4 sm:p-6 p-4 md:rounded-2xl rounded-xl md:mb-6 sm:mb-4 mb-3 flex justify-start">
            {!toggleEducation ? (
              <div>
                <button
                  type="button"
                  className="flex items-center sm:text-base text-xs text-indigo-600 font-medium"
                  onClick={() => setToggleEducation(true)}
                >
                  <img
                    className="sm:h-8 sm:w-8  h-6 w-6 mr-4"
                    src={addBtn}
                    alt=""
                  />{' '}
                  Add Education
                </button>
                {userDetails?.education?.map((education: any, idx: number) => {
                  return (
                    <div className="my-6" key={idx}>
                      <div className="flex justify-between items-center sm:mb-3 mb-2">
                        <h3 className="md:text-2xl sm:text-xl text-base font-medium">
                          {education?.institution}
                        </h3>
                        <button
                          className="sm:text-base text-sm text-red-600 font-medium"
                          onClick={() => deleteEducation(education?._id)}
                        >
                          Delete
                        </button>
                      </div>
                      <div className="flex justify-between items-center sm:mb-3 mb-2">
                        <h5 className="sm:text-base text-sm text-dark-black">
                          {education?.degree} - {education?.fieldOfStudy}
                        </h5>
                        <h4 className="sm:text-base text-sm">
                          {moment(education?.startDate).format('DD MMM, YYYY')}{' '}
                          - {moment(education?.endDate).format('DD MMM, YYYY')}
                        </h4>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="w-full">
                {/* <button
                  className="py-4 font-bold"
                  onClick={() => setToggleEducation(false)}
                >
                  Close
                </button> */}
            {/* <div className="w-full my-2">
                  <label
                    htmlFor="email"
                    className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                  >
                    Instituion
                  </label>
                  <div className="mt-2">
                    <input
                      id="institution"
                      name="institution"
                      type="text"
                      required
                      value={educationFormik.values.institution}
                      onChange={educationFormik.handleChange}
                      className="sm:text-base text-sm block w-full placeholder:text-xs p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:leading-6 rounded-lg"
                      placeholder="Enter your institution."
                    />
                  </div>
                </div>
                <div className="w-full my-2">
                  <label
                    htmlFor="email"
                    className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                  >
                    Degree
                  </label>
                  <div className="mt-2">
                    <input
                      id="degree"
                      name="degree"
                      type="text"
                      required
                      value={educationFormik.values.degree}
                      onChange={educationFormik.handleChange}
                      className="sm:text-base text-sm block w-full placeholder:text-xs p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:leading-6 rounded-lg"
                      placeholder="Enter your degree."
                    />
                  </div>
                </div>
                <div className="w-full my-2">
                  <label
                    htmlFor="email"
                    className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                  >
                    Field of Study
                  </label>
                  <div className="mt-2">
                    <input
                      id="fieldOfStudy"
                      name="fieldOfStudy"
                      type="text"
                      required
                      value={educationFormik.values.fieldOfStudy}
                      onChange={educationFormik.handleChange}
                      className="sm:text-base text-sm block w-full placeholder:text-xs p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:leading-6 rounded-lg"
                      placeholder="Enter your field of study."
                    />
                  </div>
                </div>
                <div className="w-full my-2">
                  <label
                    htmlFor="startDate"
                    className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                  >
                    Start Date
                  </label>
                  <div className="mt-2">
                    <div className="relative max-w-sm">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <svg
                          className="w-4 h-4 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                        </svg>
                      </div>
                      <input
                        type="date"
                        id="startDate"
                        value={educationFormik.values.startDate}

                        onChange={educationFormik.handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-[#F3F4F8] "
                        placeholder="Select date"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full my-2">
                  <label
                    htmlFor="endDate"
                    className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                  >
                    End Date
                  </label>
                  <div className="mt-2">
                    <div className="relative max-w-sm">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <svg
                          className="w-4 h-4 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                        </svg>
                      </div>
                      <input
                        type="date"
                        id="endDate"
                        value={educationFormik.values.endDate}
                        onChange={educationFormik.handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-[#F3F4F8] "
                        placeholder="Select date"
                      />
                    </div>
                  </div>
                </div>
                <button
                  onClick={educationFormik.handleSubmit as any}
                  className="border-0 bg-amber-300 font-medium inline-block width-auto lg:text-base md:text-sm text-xs text-gray-black rounded-xl px-4 py-2 sm:px-6 sm:py-4"
                >
                  Save
                </button>
              </div>
            )}
          </div> */}
            <h3 className="md:text-2xl sm:text-xl text-base text-gray-black font-medium mb-4">
              Education
            </h3>
            <div className="bg-white md:p-4 sm:p-6 p-4 md:rounded-2xl rounded-xl md:mb-6 sm:mb-4 mb-3 flex justify-start">
              {!toggleEducation ? (
                <div>
                  <button
                    type="button"
                    className="flex items-center sm:text-base text-xs text-indigo-600 font-medium"
                    onClick={() => setToggleEducation(true)}
                  >
                    <img
                      className="sm:h-8 sm:w-8  h-6 w-6 mr-4"
                      src={addBtn}
                      alt=""
                    />{' '}
                    Add Education
                  </button>
                  {userDetails?.education?.map((education: any, idx: number) => {
                    return (
                      <div className="my-6" key={idx}>
                        <div className="flex justify-between items-center sm:mb-3 mb-2">
                          <h4 className="md:text-2xl sm:text-xl text-base font-medium">
                            {education?.institution}
                          </h4>
                          <button
                            className="sm:text-base text-sm text-red-600 font-medium"
                            onClick={() => deleteEducation(education?._id)}
                          >
                            Delete
                          </button>
                        </div>
                        <div className="flex justify-between items-center sm:mb-3 mb-2">
                          <h5 className="sm:text-base text-sm text-dark-black">
                            Grade/CGPA: {education?.grade}
                          </h5>
                        </div>
                        <div className="flex justify-between items-center sm:mb-3 mb-2">
                          <h5 className="sm:text-base text-sm text-dark-black">
                            {education?.degree} - {education?.fieldOfStudy}
                          </h5>
                          <h4 className="sm:text-base text-sm">
                            {moment(education?.startDate).format('DD MMM, YYYY')}{' '}
                            - {moment(education?.endDate).format('DD MMM, YYYY')}
                          </h4>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="w-full">
                  <div className="w-full my-2">
                    <label
                      htmlFor="institution"
                      className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Institution
                    </label>
                    <div className="mt-2">
                      <select
                        id="institution"
                        name="institution"
                        value={educationFormik.values.institution}
                        onChange={educationFormik.handleChange}
                        className="sm:text-base text-sm block w-full p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 focus:ring-inset focus:ring-yellow-300 sm:leading-6 rounded-lg"
                      >
                        <option value="">Select an institution</option>
                        {/* Map over your list of institutions and create options */}
                        {(institutions?.colleges ?? []).map((college) => (
                          <option key={college} value={college}>
                            {college}
                          </option>
                        ))}

                      </select>
                    </div>
                  </div>
                  {/* ... Other input fields remain the same ... */}
                  <div className="w-full my-2">
                    <label
                      htmlFor="email"
                      className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Degree
                    </label>
                    <div className="mt-2">
                      <input
                        id="degree"
                        name="degree"
                        type="text"
                        required
                        value={educationFormik.values.degree}
                        onChange={educationFormik.handleChange}
                        className="sm:text-base text-sm block w-full placeholder:text-xs p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:leading-6 rounded-lg"
                        placeholder="Enter your degree."
                      />
                    </div>
                  </div>
                  <div className="w-full my-2">
                    <label
                      htmlFor="email"
                      className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Field of Study
                    </label>
                    <div className="mt-2">
                      <input
                        id="fieldOfStudy"
                        name="fieldOfStudy"
                        type="text"
                        required
                        value={educationFormik.values.fieldOfStudy}
                        onChange={educationFormik.handleChange}
                        className="sm:text-base text-sm block w-full placeholder:text-xs p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:leading-6 rounded-lg"
                        placeholder="Enter your field of study."
                      />
                    </div>
                  </div>
                  <div className="w-full my-2">
                    <label
                      htmlFor="startDate"
                      className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Start Date
                    </label>
                    <div className="mt-2">
                      <div className="relative max-w-sm">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                          <svg
                            className="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                          </svg>
                        </div>
                        <input
                          type="date"
                          id="startDate"
                          value={educationFormik.values.startDate}

                          onChange={educationFormik.handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-[#F3F4F8] "
                          placeholder="Select date"
                        />
                      </div>
                    </div>
                    <div className="w-full my-2">
                      <label
                        htmlFor="endDate"
                        className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                      >
                        End Date
                      </label>
                      <div className="mt-2">
                        <div className="relative max-w-sm">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                            <svg
                              className="w-4 h-4 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                            </svg>
                          </div>
                          <input
                            type="date"
                            id="endDate"
                            value={educationFormik.values.endDate}
                            onChange={educationFormik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-[#F3F4F8] "
                            placeholder="Select date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-full my-2">
                      <label
                        htmlFor="email"
                        className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                      >
                        Grade
                      </label>
                      <div className="mt-2">
                        <input
                          id="grade"
                          name="grade"
                          type="text"
                          required
                          value={educationFormik.values.grade}
                          onChange={educationFormik.handleChange}
                          className="sm:text-base text-sm block w-full placeholder:text-xs p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:leading-6 rounded-lg"
                          placeholder="Enter your Grade/CGPA."
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={educationFormik.handleSubmit as any}
                    className="border-0 bg-amber-300 font-medium inline-block width-auto lg:text-base md:text-sm text-xs text-gray-black rounded-xl px-4 py-2 sm:px-6 sm:py-4"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>

            <h3 className="md:text-2xl sm:text-xl text-base text-gray-black font-medium mb-4">
              Work Experience
            </h3>
            <div className="bg-white md:p-4 sm:p-6 p-4 md:rounded-2xl rounded-xl md:mb-6 sm:mb-4 mb-3 flex justify-start">
              {!toggleExperience ? (
                <div>
                  <button
                    type="button"
                    onClick={() => setToggleExperience(true)}
                    className="flex items-center justify-center sm:text-base text-xs text-indigo-600 font-medium sm:mb-6 mb-4"
                  >
                    <img
                      className="sm:h-8 sm:w-8  h-6 w-6 mr-4"
                      src={addBtn}
                      alt=""
                    />{' '}
                    Add Work Experience
                  </button>
                  {userDetails?.experience?.map(
                    (experience: any, idx: number) => {
                      return (
                        <div className="my-6" key={idx}>
                          <div className="flex justify-between items-center sm:mb-3 mb-2">
                            <h3 className="md:text-2xl sm:text-xl text-base font-medium">
                              {experience?.company}
                            </h3>
                            <button
                              className="sm:text-base text-sm text-red-600 font-medium"
                              onClick={() => deleteExperience(experience?._id)}
                            >
                              Delete
                            </button>
                          </div>
                          <div className="flex justify-between items-center sm:mb-3 mb-2">
                            <h5 className="sm:text-base text-sm text-dark-black">
                              {experience?.position}
                            </h5>
                            <h4 className="sm:text-base text-sm">
                              {moment(experience?.startDate).format(
                                'DD MMM, YYYY'
                              )}{' '}
                              -{' '}
                              {moment(experience?.endDate).format('DD MMM, YYYY')}
                            </h4>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              ) : (
                <div className="w-full">
                  {/* <button
                  className="py-4 font-bold"
                  onClick={() => setToggleExperience(false)}
                >
                  Close
                </button> */}
                  <div className="w-full my-2">
                    <label
                      htmlFor="company"
                      className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Company
                    </label>
                    <div className="mt-2">
                      <input
                        id="company"
                        name="company"
                        type="text"
                        required
                        value={experienceFormik.values.company}
                        onChange={experienceFormik.handleChange}
                        className="sm:text-base text-sm block w-full placeholder:text-xs p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:leading-6 rounded-lg"
                        placeholder="Enter your company."
                      />
                    </div>
                  </div>
                  <div className="w-full my-2">
                    <label
                      htmlFor="position"
                      className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Position
                    </label>
                    <div className="mt-2">
                      <input
                        id="position"
                        name="position"
                        type="text"
                        required
                        value={experienceFormik.values.position}
                        onChange={experienceFormik.handleChange}
                        className="sm:text-base text-sm block w-full placeholder:text-xs p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:leading-6 rounded-lg"
                        placeholder="Enter your position."
                      />
                    </div>
                  </div>

                  <div className="w-full my-2">
                    <label
                      htmlFor="startDate"
                      className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Start Date
                    </label>
                    <div className="mt-2">
                      <div className="relative max-w-sm">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                          <svg
                            className="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                          </svg>
                        </div>
                        <input
                          type="date"
                          id="startDate"
                          value={experienceFormik.values.startDate}
                          onChange={experienceFormik.handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-[#F3F4F8] "
                          placeholder="Select date"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full my-2">
                    <label
                      htmlFor="endDate"
                      className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      End Date
                    </label>
                    <div className="mt-2">
                      <div className="relative max-w-sm">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                          <svg
                            className="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                          </svg>
                        </div>
                        <input
                          type="date"
                          id="endDate"
                          value={experienceFormik.values.endDate}
                          onChange={experienceFormik.handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-[#F3F4F8] "
                          placeholder="Select date"
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={experienceFormik.handleSubmit as any}
                    className="border-0 bg-amber-300 font-medium inline-block width-auto lg:text-base md:text-sm text-xs text-gray-black rounded-xl px-4 py-2 sm:px-6 sm:py-4"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>

            <h3 className="md:text-2xl sm:text-xl text-base text-gray-black font-medium mb-4">
              Skills
            </h3>
            <div className="bg-white md:p-9 sm:p-6 p-4 md:rounded-2xl rounded-xl md:mb-6 sm:mb-4 mb-3">
              <p className="sm:text-sm text-xs mb-6">
                Add verified skills to your profile to highlight your professional
                knowledge and stand out to prospective employers!
              </p>
              {!toggleSkills ? (
                <div>
                  <button
                    type="button"
                    onClick={() => setToggleSkills(true)}
                    className="flex items-center sm:text-base text-sm text-indigo-600  font-medium mb-6"
                  >
                    <img
                      className="sm:h-8 sm:w-8  h-6 w-6 mr-4"
                      src={addBtn}
                      alt=""
                    />{' '}
                    Add skills
                  </button>
                  <div className="flex space-x-2 flex-wrap">
                    {userDetails?.skills?.map((skill: any) => (
                      <span className="sm:p-3 p-2 flex items-center space-x-2 bg-indigo-50 sm:text-base text-xs  rounded-lg sm:mr-4 sm:mb-4 mr-2 mb-2 text-indigo-600">
                        <span> {skill}</span>{' '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20px"
                          height="20px"
                          className="cursor-pointer"
                          fill="red"
                          onClick={() => deleteSkill(skill)}
                        >
                          <path d="M 10 2 L 9 3 L 3 3 L 3 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 4.3652344 7 L 6.0683594 22 L 17.931641 22 L 19.634766 7 L 4.3652344 7 z" />
                        </svg>
                      </span>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col w-full items-start">
                  {/* <button
                  className="py-4 font-bold"
                  onClick={() => setToggleSkills(false)}
                >
                  Close
                </button> */}
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={skills}
                      getOptionLabel={(option) => option}
                      value={skill}
                      onChange={(event, newValue: any) => {
                        setSkill(newValue);
                      }}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                      renderTags={(
                        value: string[],
                        getTagProps: AutocompleteRenderGetTagProps
                      ) =>
                        value.map((option: any, index: number) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                            onDelete={() => {
                              const newSkills: any = [...skill];
                              const skillToDelete = newSkills.indexOf(option);
                              newSkills.splice(skillToDelete, 1);
                              setSkill(newSkills);
                            }}
                          />
                        ))
                      }
                    />
                  </FormControl>
                  <button
                    onClick={appendSkills}
                    className="border-0 bg-amber-300 font-medium inline-block width-auto lg:text-base md:text-sm text-xs text-gray-black rounded-xl px-4 py-2 sm:px-6 sm:py-4"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <h3 className="md:text-2xl sm:text-xl text-base text-gray-black font-medium mb-4">
              Add Licenses and Certifications
            </h3>
            <div className="bg-white md:p-4 sm:p-6 p-4 md:rounded-2xl rounded-xl md:mb-6 sm:mb-4 mb-3 flex justify-start">
              {!toggleCertifications ? (
                <div>
                  <button
                    type="button"
                    className="flex items-center sm:text-base text-xs text-indigo-600 font-medium"
                    onClick={() => setTogglecertifications(true)}
                  >
                    <img
                      className="sm:h-8 sm:w-8  h-6 w-6 mr-4"
                      src={addBtn}
                      alt=""
                    />{' '}
                    Add Licenses and Certifications
                  </button>
                  {userDetails?.license?.map((license: any, idx: number) => {
                    return (
                      <div className="my-6" key={idx}>
                        <div className="flex justify-between items-center sm:mb-3 mb-2">
                          <h4 className="md:text-2xl sm:text-xl text-base font-medium">
                            {license?.name}
                          </h4>
                          <button
                            className="sm:text-base text-sm text-red-600 font-medium"
                            onClick={() => deleteLicense(license?._id)}
                          >
                            Delete
                          </button>
                        </div>
                        <div className="flex justify-between items-center sm:mb-3 mb-2">
                          <h5 className="sm:text-base text-sm text-dark-black">
                            Issuing Authority: {license?.issuingAuthority}
                          </h5>
                        </div>
                        <div className="flex justify-between items-center sm:mb-3 mb-2">
                          <h5 className="sm:text-base text-sm text-dark-black">
                            <h4 className="sm:text-base text-sm">
                              {moment(license?.issueDate).format('DD MMM, YYYY')}{' '}
                            </h4>
                          </h5>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="w-full">
                  <div className="w-full my-2">
                    <label
                      htmlFor="institution"
                      className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Certification Name
                    </label>
                    <div className="mt-2">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        required
                        value={licenseFormik.values.name}
                        onChange={licenseFormik.handleChange}
                        className="sm:text-base text-sm block w-full placeholder:text-xs p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:leading-6 rounded-lg"
                        placeholder="Enter issuing authority."
                      />
                    </div>
                  </div>
                  {/* ... Other input fields remain the same ... */}
                  <div className="w-full my-2">
                    <label
                      htmlFor="email"
                      className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Issuing Authority
                    </label>
                    <div className="mt-2">
                      <input
                        id="issuingAuthority"
                        name="issuingAuthority"
                        type="text"
                        required
                        value={licenseFormik.values.issuingAuthority}
                        onChange={licenseFormik.handleChange}
                        className="sm:text-base text-sm block w-full placeholder:text-xs p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:leading-6 rounded-lg"
                        placeholder="Enter issuing authority."
                      />
                    </div>
                  </div>
                  <div className="w-full my-2">
                    <label
                      htmlFor="startDate"
                      className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Date of Issue
                    </label>
                    <div className="mt-2">
                      <div className="relative max-w-sm">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                          <svg
                            className="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                          </svg>
                        </div>
                        <input
                          type="Date"
                          id="issueDate"
                          value={licenseFormik.values.issueDate}

                          onChange={licenseFormik.handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-[#F3F4F8] "
                          placeholder="Select date"
                        />
                      </div>
                    </div>
                    <div className="w-full my-2">
                      {/* <label
                    htmlFor="endDate"
                    className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                  >
                    End Date
                  </label> */}
                      {/* <div className="mt-2">
                    <div className="relative max-w-sm">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <svg
                          className="w-4 h-4 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                        </svg>
                      </div>
                      <input
                        type="date"
                        id="endDate"
                        value={educationFormik.values.endDate}
                        onChange={educationFormik.handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-[#F3F4F8] "
                        placeholder="Select date"
                      />
                    </div>
                  </div> */}
                    </div>
                    {/* <div className="w-full my-2">
                  <label
                    htmlFor="email"
                    className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
                  >
                    Grade
                  </label>
                  <div className="mt-2">
                    <input
                      id="grade"
                      name="grade"
                      type="text"
                      required
                      value={educationFormik.values.grade}
                      onChange={educationFormik.handleChange}
                      className="sm:text-base text-sm block w-full placeholder:text-xs p-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:leading-6 rounded-lg"
                      placeholder="Enter your Grade/CGPA."
                    />
                  </div>
                </div> */}
                  </div>
                  <button
                    onClick={licenseFormik.handleSubmit as any}
                    className="border-0 bg-amber-300 font-medium inline-block width-auto lg:text-base md:text-sm text-xs text-gray-black rounded-xl px-4 py-2 sm:px-6 sm:py-4"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            {/* <h3 className="md:text-2xl sm:text-xl text-base text-gray-black font-medium mb-4">
            Certificates
          </h3>
          <div className="bg-white md:p-9 sm:p-6 p-4 md:rounded-2xl rounded-xl md:mb-6 sm:mb-4 mb-3 flex justify-center">
            <button
              type="button"
              className="flex items-center sm:text-base text-xs text-indigo-600 font-medium"
            >
              <img
                className="sm:h-8 sm:w-8  h-6 w-6 mr-4"
                src={addBtn}
                alt=""
              />{' '}
              Add Education
            </button>
          </div> */}
          </div>
          <div className="md:w-2/6 w-full md:pl-3">
            {/* <h3 className="md:text-2xl sm:text-xl text-base text-gray-black font-medium mb-4">
            Career
          </h3> */}
            {/* <div className="bg-white md:p-9 sm:p-6 p-4 md:rounded-2xl rounded-xl md:mb-6 sm:mb-4 mb-3">
            <h4 className="sm:text-base text-sm text-zinc-900 md:mb-10 sm:mb-6 mb-4">
              Orientation Completed 23%
            </h4>
            <div className="flex">
              <span className="w-1/3 sm:h-2 h-1 rounded-xl bg-amber-200 inline-block mr-4 active:bg-amber-200" />
              <span className="w-1/3 sm:h-2 h-1 rounded-xl bg-neutral-200 inline-block mr-4" />
              <span className="w-1/3 sm:h-2 h-1 rounded-xl bg-neutral-200 inline-block" />
            </div>
          </div> */}
            {/* <h3 className="md:text-2xl sm:text-xl text-base text-gray-black font-medium mb-4">
            Current courses
          </h3>
          <div className="bg-white md:p-9 sm:p-6 p-4 md:rounded-2xl rounded-xl md:mb-6 sm:mb-4 mb-3 flex items-center">
            <div className="flex items-center w-4/5">
              <div className="sm:h-10 sm:w-10 h-6 w-6 mr-2">
                <img
                  className="sm:h-10 sm:w-10 h-6 w-6 rounded-xl object-cover "
                  src={rectangle}
                  alt=""
                />
              </div>
              <div className="text-ellipsis overflow-hidden whitespace-nowrap text-sm">
                Sales Management - Business Ethics and Sales
              </div>
            </div>
            <div className="w-1/5 text-right">
              <div className="progress-bar relative ml-auto mr-0">
                <progress
                  value={75}
                  //   min={0}
                  max={100}
                  style={{ visibility: 'hidden', height: 0, width: 0 }}
                >
                  75%
                </progress>
                <span className="absolute top-2/4 left-2/4 -translate-y-1/2 -translate-x-1/2 text-xs">
                  10%
                </span>
              </div>
            </div>
          </div> */}
            {/* <div className="bg-white md:p-9 sm:p-6 p-4 md:rounded-2xl rounded-xl md:mb-6 sm:mb-4 mb-3 flex items-center">
            <div className="flex items-center w-4/5">
              <div className="sm:h-10 sm:w-10 h-6 w-6 mr-2">
                <img
                  className="sm:h-10 sm:w-10 h-6 w-6 rounded-xl object-cover "
                  src={rectangle}
                  alt=""
                />
              </div>
              <div className="text-ellipsis overflow-hidden whitespace-nowrap text-sm">
                Sales Techniques - Using Competitive Sales Strategies
              </div>
            </div>
            <div className="w-1/5 text-right">
              <div className="progress-bar relative ml-auto mr-0">
                <progress
                  value={75}
                  //   min={0}
                  max={100}
                  style={{ visibility: 'hidden', height: 0, width: 0 }}
                >
                  75%
                </progress>
                <span className="absolute top-2/4 left-2/4 -translate-y-1/2 -translate-x-1/2 text-xs">
                  10%
                </span>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default MainContent;
