import React from "react";
import Header from "../../components/shared/Header";
import SignUpForm from "../../components/Login/LoginForm";
import Footer from "../../components/shared/Footer";

const Login = () => {
  return (
    <>
      <Header />
      <SignUpForm />
      <Footer />
    </>
  );
};

export default Login;
