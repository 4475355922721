import React from 'react';
import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer';
import BookADemoForm from '../../components/BookADemo/BookADemoForm';

const BookADemo = () => {
  return (
    <>
      <Header />
      <BookADemoForm />
      <Footer />
    </>
  );
};

export default BookADemo;
