import React from 'react';
import { Link } from 'react-router-dom';
import arrowLeft from '../../../assets/arrow-left-long.svg';

const StepOneContent = ({ assessmentInfo }: any) => {
  return (
    <section className="md:py-7 py-5 bg-light-white min-h-[calc(100vh-152px)]">
      <div className="mx-auto max-w-7xl px-4">
        <Link
          to="/assessments"
          className="sm:py-4 py-2 sm:px-4 px-3 bg-dark-gray rounded-lg inline-flex items-center font-medium sm:text-base text-sm md:mb-8 sm:mb-6 mb-4"
        >
          <img className="sm:mr-2 mr-1 sm:w-5 w-4" src={arrowLeft} alt="" />{' '}
          Back
        </Link>
        <h3 className="md:text-3xl sm:text-2xl text-xl md:mb-6 sm:mb-4 mb-2 font-medium">
          Overview
        </h3>

        <div>
          <div
            dangerouslySetInnerHTML={{ __html: assessmentInfo?.overview }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default StepOneContent;
