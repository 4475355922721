import React, { useState } from 'react';
import profile from '../../assets/user-profile.png';
import { axiosPrivate } from '../../api/axios';
import { toast } from 'react-toastify';
import logo from '../../assets/new-dashboard-img/tblogo.png';
import bell from '../../assets/new-dashboard-img/bell.svg';
import gmail from '../../assets/new-dashboard-img/gmail.svg';
import profilePik from '../../assets/new-dashboard-img/profilepik.png';
const Header = () => {
  const [userDetails, setUserDetails] = useState<any>({});

  const openSideNav = () => {
    var sideNav: any = document.getElementById('sideNav');
    sideNav.classList.toggle('active');
  };
  const user = JSON.parse(localStorage.getItem('user') as string);

  const getUserDetails = async () => {
    await axiosPrivate
      .get(`/user/${user?.id}`)
      .then((res) => setUserDetails(res.data));
  };

  const handleChangeProfilePicture = async (file: File) => {
    const formData = new FormData();
    formData.append('image', file);
    await axiosPrivate.patch('/user/profile-picture', formData).then(() => {
      toast.success('Profile updated successfully!');
      getUserDetails();
    });
  };
  // React.useEffect(() => {
  //   getUserDetails();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user?.id]);
  return (
    // <header className="fixed top-0 lg:left-60 left-0 lg:w-[calc(100%-15rem)] bg-white w-full md:p-6 p-4 z-10">
    //   <div className="flex items-center -mx-3">
    //     <div className="md:w-2/5 w-2/4 px-3">
    //       <div className="relative search-bar">
    //         <input
    //           type="search"
    //           autoComplete="current-password"
    //           required
    //           className="md:text-base sm:text-sm text-xs block w-full md:py-1.5 py-1 text-gray-900 shadow-sm md:h-14 sm:h-12 h-10 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:leading-6 rounded-lg"
    //           placeholder="Search something"
    //         />
    //         <button
    //           type="button"
    //           className="absolute top-2/4 left-4 -translate-y-1/2"
    //         >
    //           <svg
    //             className="md:w-6 md:h-6 w-5 h-5 "
    //             viewBox="0 0 24 24"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
    //               stroke="#323554"
    //               strokeWidth="1.5"
    //               strokeLinecap="round"
    //               strokeLinejoin="round"
    //             />
    //             <path
    //               d="M20.9999 20.9999L16.6499 16.6499"
    //               stroke="#323554"
    //               strokeWidth="1.5"
    //               strokeLinecap="round"
    //               strokeLinejoin="round"
    //             />
    //           </svg>
    //         </button>
    //       </div>
    //     </div>
    //     <div className="md:w-3/5 w-2/4 px-3">
    //       <div className="flex justify-end items-center">
    //         <button
    //           onClick={openSideNav}
    //           type="button"
    //           className="btn md:pr-6 sm:pr-4 md:mr-6 sm:mr-4 pr-2 mr-2 border-r-2 lg:hidden block"
    //         >
    //           <svg
    //             className="md:w-6 md:h-6 w-5 h-5 icon-bar"
    //             viewBox="0 0 448 512"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M0 96C0 78.3 14.3 64 32 64H416C433.7 64 448 78.3 448 96C448 113.7 433.7 128 416 128H32C14.3 128 0 113.7 0 96ZM0 256C0 238.3 14.3 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.3 288 0 273.7 0 256ZM448 416C448 433.7 433.7 448 416 448H32C14.3 448 0 433.7 0 416C0 398.3 14.3 384 32 384H416C433.7 384 448 398.3 448 416Z"
    //               fill="black"
    //             />
    //           </svg>
    //         </button>
    //         <button
    //           type="button"
    //           className="relative md:pr-6 sm:pr-4 md:mr-6 sm:mr-4 pr-2 mr-2 border-r-2"
    //         >
    //           <span className="absolute -top-1 left-2 py-0.5 px-1.5  rounded-full bg-rose-500 text-center leading-4 text-white text-xs">
    //             1
    //           </span>
    //           <svg
    //             className="md:w-6 md:h-6 w-5 h-5"
    //             viewBox="0 0 24 24"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M12.0189 2C8.33892 2 5.35892 4.98 5.35892 8.66V10.76C5.35892 11.44 5.07892 12.46 4.72892 13.04L3.45892 15.16C2.67892 16.47 3.21892 17.93 4.65892 18.41C9.43892 20 14.6089 20 19.3889 18.41C20.7389 17.96 21.3189 16.38 20.5889 15.16L19.3189 13.04C18.9689 12.46 18.6889 11.43 18.6889 10.76V8.66C18.6789 5 15.6789 2 12.0189 2Z"
    //               stroke="#393A3F"
    //               strokeWidth="1.5"
    //               strokeMiterlimit={10}
    //               strokeLinecap="round"
    //             />
    //             <path
    //               d="M15.3318 18.8203C15.3318 20.6503 13.8318 22.1503 12.0018 22.1503C11.0918 22.1503 10.2518 21.7703 9.65187 21.1703C9.05187 20.5703 8.67188 19.7303 8.67188 18.8203"
    //               stroke="#393A3F"
    //               strokeWidth="1.5"
    //               strokeMiterlimit={10}
    //             />
    //             <path
    //               d="M12 6.43945V9.76945"
    //               stroke="#393A3F"
    //               strokeWidth="1.5"
    //               strokeMiterlimit={10}
    //               strokeLinecap="round"
    //             />
    //           </svg>
    //         </button>
    //         <div className="flex items-center">
    //           <div className="relative">
    //             <img
    //               className="md:h-12 md:w-12 sm:h-8 sm:w-8 h-6 w-6 rounded-full object-cover sm:mr-3 mr-2"
    //               src={userDetails?.profilePicture || profile}
    //               alt=""
    //             />
    //             <label htmlFor="profile">
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 viewBox="0 0 24 24"
    //                 width="30px"
    //                 height="30px"
    //                 fill="#F3DA38"
    //                 className="absolute -bottom-3 cursor-pointer right-0"
    //               >
    //                 <path d="M 6 3 C 4.346 3 3 4.346 3 6 L 3 18 C 3 19.654 4.346 21 6 21 L 18 21 C 19.654 21 21 19.654 21 18 L 21 6 C 21 4.346 19.654 3 18 3 L 6 3 z M 10.267578 8 L 13.732422 8 C 13.899422 8 14.055437 8.0836562 14.148438 8.2226562 L 14.5 9 L 16 9 C 16.552 9 17 9.448 17 10 L 17 15 C 17 15.552 16.552 16 16 16 L 8 16 C 7.448 16 7 15.552 7 15 L 7 10 C 7 9.448 7.448 9 8 9 C 8 8.724 8.224 8.5 8.5 8.5 C 8.776 8.5 9 8.724 9 9 L 9.5 9 L 9.8515625 8.2226562 C 9.9435625 8.0836562 10.099578 8 10.267578 8 z M 12 10 C 10.621 10 9.5 11.121 9.5 12.5 C 9.5 13.879 10.621 15 12 15 C 13.379 15 14.5 13.879 14.5 12.5 C 14.5 11.121 13.379 10 12 10 z M 15.5 10 A 0.5 0.5 0 0 0 15.5 11 A 0.5 0.5 0 0 0 15.5 10 z M 12 11 C 12.827 11 13.5 11.673 13.5 12.5 C 13.5 13.327 12.827 14 12 14 C 11.173 14 10.5 13.327 10.5 12.5 C 10.5 11.673 11.173 11 12 11 z" />
    //               </svg>
    //               <input
    //                 type="file"
    //                 name=""
    //                 id="profile"
    //                 className="hidden"
    //                 onChange={(e: any) =>
    //                   handleChangeProfilePicture(e.target.files[0])
    //                 }
    //               />
    //             </label>
    //           </div>
    //           <h4 className="md:text-base sm:text-sm text-xs font-semibold">
    //             {userDetails?.name}
    //           </h4>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </header>
    <header className='sticky z-[99999] top-0 left-0 w-full h-16 bg-[#161B2A] flex items-center '>
      <div className="flex flex-row items-center justify-between flex-1 px-4 py-2">
        <div className='h-12  w-24 '>
          <img src={logo} className='w-full h-full object-contain' alt="" />
        </div>
        <div className='flex flex-row items-center  justify-end gap-[22px] py-[1px]'>
          <div className=' bg-[#F0F1F91A] rounded-full relative flex-row flex items-center justify-center w-[29px] h-[29px]'>
            <div className="w-4 h-[14px] ">
              <img src={bell} className='w-full h-full ' alt="" />
            </div>
          </div>
          <div className=' bg-[#F0F1F91A] rounded-full relative flex-row flex items-center justify-center w-[29px] h-[29px]'>
            <div className="w-4 h-[14px] ">
              <img src={gmail} className='w-full h-full ' alt="" />
            </div>
          </div>
          <div className=' bg-[#F0F1F91A] rounded  flex-col flex items-center justify-center w-[74px] h-[46px] gap-0.5'>
            <div className='w-6 h-6 rounded-full overflow-hidden'>
              <img src={profilePik} alt="profilepic" className='w-full h-full object-contain' />
            </div>
            <div className='flex-row items-center gap-1 flex'>
              <h3 className='my-0 text-white text-[10px] font-normal'>Profile</h3>
              <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.60863 3.73402C3.40925 3.96404 3.05234 3.96404 2.85297 3.73402L0.683813 1.23142C0.403173 0.907646 0.633163 0.403937 1.06164 0.403938L5.39996 0.403938C5.82844 0.403938 6.05843 0.907646 5.77779 1.23142L3.60863 3.73402Z" fill="white" />
              </svg>

            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;