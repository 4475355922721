import React from "react";
import google from "../../../assets/google-logo.svg";
import { PropsType } from "./types/PropsTypes";

const StepOne = ({ nextStep }: PropsType) => {
  return (
    <section className="md:py-10 sm:py-8 py-6 bg-white-sm-light sm:min-h-[calc(100vh-152px)] min-h-[calc(100vh-96px)]">
      <div className="mx-auto max-w-2xl px-4">
        <div className="md:p-8 sm:p-6 p-4 sm:rounded-2xl rounded-xl bg-white md:mb-6 sm:mb-4 mb-3">
          <h4 className="sm:text-base text-sm font-medium mb-2">UX UI Designer</h4>
          <h4 className="flex items-center text-dark-black lg:text-base text-sm">
            <img className="mr-2 md:w-6 w-5 md:h-6 h-5" src={google} alt="" />{" "}
            Company Name{" "}
          </h4>
        </div>
        <h4 className="md:text-2xl sm:text-xl text-base font-medium mb-2">
          Add your resume
        </h4>
        <div className="md:p-8 sm:p-6 p-4 sm:rounded-2xl rounded-xl bg-white border-2 border-yellow-300 relative">
          <h4 className="md:text-xl text-base font-medium mb-2 ">Raju Mullah</h4>
          <p className="text-dark-black text-sm mb-2">
            rbrajumullah100@gmail.com <br />
            +880 1784 453204 <br />
            Brahmanbaria 3462
          </p>
          <p className="text-dark-black text-sm">
            With over 4 years of experience as a UI/UX Designer, I have a strong
            track record of creating user centric designs for web and mobile
            applications that meet business objectives. My keen eye for detail
            and passion for creating intuitive and engaging user experiences is
            evident in all my work. I love traveling, hiking. I enjoy seeing
            things from a fresh perspective :)
          </p>
          <input
            type="checkbox"
            className="sm:h-5 h-4 sm:w-5 w-4 rounded-full border-2 answer-checkbox answer-check-sm border-gray-black absolute top-8 right-8"
          />
        </div>
        <div className="inline-flex items-center justify-center w-full">
          <hr className="w-full h-px md:my-9 sm:my-7 sm:my-5 bg-gray-200 border-0 dark:bg-gray-700" />
          <span className="absolute px-3 font-medium text-base text-gray-900 -translate-x-1/2 left-1/2 dark:text-white bg-gray-900">
            Or
          </span>
        </div>
        <h4 className="md:text-2xl sm:text-xl text-base font-medium md:mb-6 mb-4">Upload resume</h4>
        <div className="text-center md:p-8 sm:p-6 p-4 bg-white sm:rounded-2xl rounded-xl">
          <svg
            className="w-8 h-8 mb-4 mx-auto"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16 8L16 24" stroke="#BDBEC5" strokeWidth="1.5" />
            <path d="M24 16L8 16" stroke="#BDBEC5" strokeWidth="1.5" />
            <rect
              x="0.75"
              y="0.75"
              width="30.5"
              height="30.5"
              rx="4.58333"
              stroke="#BDBEC5"
              strokeWidth="1.5"
              strokeDasharray="3 3"
            />
          </svg>
          <p className="text-base text-dark-black">
            <span className="text-indigo-500">Click here</span>{" "}
            or drag &amp; drop
          </p>
        </div>
        <div className="sm:mt-10 mt-6 text-end">
          <a
            href="/jobs"
            className="inline-block md:py-4 py-3 md:px-10 px-6 rounded-lg bg-shade-gray font-medium sm:text-base text-sm"
          >
            Back
          </a>
          <div
            onClick={nextStep}
            className="inline-block md:py-4 py-3 md:px-10 px-6 rounded-lg bg-amber-300 font-medium sm:text-base text-sm ml-6"
          >
            Next
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepOne;
