import React from 'react';
import Header from '../../components/shared/Header';
import Hero from '../../components/Landing/Hero';
import Clients from '../../components/Landing/Clients';
import Assessment from '../../components/Landing/Assessment';
import Courses from '../../components/Landing/Courses';
import Jobs from '../../components/Landing/Jobs';
import Trainers from '../../components/Landing/Trainers';
import SuccessStory from '../../components/Landing/SuccessStory';
import Footer from '../../components/shared/Footer';
import Problem from '../../components/Landing/Problem';
import Blogs from '../../components/Landing/Blogs';

const Landing = () => {
  return (
    <div>
      <div className="bg-dark_blue">
        <Header />
        <Hero />
      </div>
      {/* <JobResults /> */}
      <Problem />
      <Assessment />
      <Jobs />
      <Courses />
      <Clients />

      <Trainers />
      <SuccessStory />
      <Blogs />

      <Footer />
    </div>
  );
};

export default Landing;
