import React, { useState, useEffect } from 'react';
import timer from '../../../assets/timer.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Button from '@mui/material/Button';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const StepFourHeader = ({ assessmentInfo }: any) => {
  const [time, setTime] = useState(assessmentInfo?.duration);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const timerId: any =
      time > 0 &&
      setInterval(() => {
        setTime((prevTime: any) => prevTime - 1);
      }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [time]);

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  const displayTime = `${hours}h ${minutes < 10 ? '0' : ''}${minutes}m ${
    seconds < 10 ? '0' : ''
  }${seconds}s`;

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {'Are you sure you want to cancel the assessment?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Your answers and results will be lost if you cancel the assessment.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>NO</Button>
          <Button
            onClick={() => ((window as Window).location = '/assessments')}
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>

      <div className="text-center ml-10">
        <h4 className="text-white flex items-center md:text-sm text-xs mb-1">
          <img className="md:w-6 w-5 md:h-6 h-5 mr-1" src={timer} alt="" /> Time
        </h4>
        <h3 className="font-semibold sm:text-base md:text-sm text-xs text-white">
          {displayTime}
        </h3>
      </div>
      <div className="">
        <div
          onClick={handleClickOpen}
          className="bg-white-transparent cursor-pointer text-white md:text-base text-sm md:py-3.5 sm:py-2.5 py-2 rounded-lg px-4 text-center inline-block font-medium"
        >
          Cancel Assessment
        </div>
      </div>
    </>
  );
};

export default StepFourHeader;
